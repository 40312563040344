import React from 'react'
import { Content, H2, P } from './Elements'
import { Container, Grid } from '@mui/material'
import FullLogo from '../../images/Fullogowhite.png'
import { useTranslation } from 'react-i18next';


function OurStory() {
  const { t } = useTranslation('sections');
  return (
    <Content  
    size="50vh" 
    bgcolor="#fff"
    viewport={{once:true}}
    initial={{opacity:0,y:150}}
    whileInView={{opacity:1,y:0}}
    transition={{type:"easeIn", duration:1,delay:0 }}
    >
        <Container>
            <Grid container >
                <Grid item lg={2}
                 style={{
                    backgroundImage:`url(${FullLogo})`,
                    backgroundRepeat:'no-repeat',
                    backgroundPosition:"left",
                    backgroundPositionY:"bottom",
                    marginBottom:-40
        
                }}
                >

                </Grid>
                <Grid item lg={10}>
                        <H2>{t("ourStory.title")} </H2>
                        <P>
                          {t("ourStory.content")} 
                       
                        </P>
                </Grid>
            </Grid>
        </Container>
    </Content>
  )
}

export default OurStory

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Container, Grid } from '@mui/material';


export const MainContainer = styled(motion(Container))`
`

export const Content = styled(motion.div)`
  display:flex;
  align-items:center;
  min-height:${props=>props.size};
  background:${props=>props.bgcolor}
`;

export const GradiantBackground = styled(motion.div)`
display:flex;
align-items:center;
min-height:100%;
background: linear-gradient(180deg, #136EF5 0%, #021634 83.8%);

 h1{
     color:#fff;
      font-size:38px;
      @media screen and (max-width: 1200px){
        text-align:center;
      }
        @media screen and (max-width: 760px){
        text-align:center;
        font-size:28px;
      }

  }


 p{
    color:#fff;
    text-align:left;
    font-size:18px;
    span {
     color:#1371FF;
    }
    @media screen and (max-width: 760px){
      text-align:justify;
    }
  }

  



`;

export const H1 = styled(motion.h1)`
   font-size:38px;
   @media screen and (max-width: 1200px){
     text-align:center;
   }
   @media screen and (max-width: 760px){
    text-align:center;
    font-size:28px;
  }
   
`

export const H2 = styled(motion.h2)`

  @media screen and (max-width: 1200px){
    text-align:center
  }
`

export const P = styled(motion.p)`
@media screen and (max-width: 760px){
  text-align:justify;
}
`

export const UL = styled(motion.ul)`
  
  color:#fff;
  line-height: 48px;
  font-size: 27px;

`

export const LI = styled(motion.li)`
  margin-bottom:30px;

`


export const Span = styled(motion.span)`
    font-size:18px;
    font-weight: 400;
`


export const ButtonLink = styled(motion(Link))`
  font-size: 18px;
  font-weight:400;
  color:${props=>props.color}; 
  display: flex;
  align-items: center;
  justify-content: center;
  padding:10px;
  max-width:200px;
  height:30px;
  text-decoration: none;
  border:2px solid ${props=>props.color}; 
  cursor: pointer;
  border-radius:50px;
  background-color:${props=>props.bgcolor};
  line-height: 200px;
`;



export const BtnContainLink = styled(motion(Link))`
  font-size: 18px;
  font-weight:400;
  color:${props=>props.color}; 
  display: flex;
  align-items: center;
  justify-content: center;
  padding:10px;
  max-width:450px;
  height:30px;
  text-decoration: none;
  border:2px solid ${props=>props.bgcolor}; 
  cursor: pointer;
  border-radius:50px;
  background-color:${props=>props.bgcolor};
  line-height: 200px;
`;

export const BtnLoginLink = styled(motion(Link))`
  font-size: 18px;
  font-weight:400;
  color:${props=>props.color}; 
  padding:10px;
  width:100%;
  height:30px;
  text-decoration: none;
  border:1px solid ${props=>props.bgcolor}; 
  cursor: pointer;
  border-radius:50px;
  background-color:${props=>props.bgcolor};
  text-align:center
`;

export const Img = styled(motion.img)`
    max-width:550px;
    height:auto;

    @media screen and (max-width: 760px){
      width:350px;   
      height:auto;
    }
`;

export const HeroBannerImg = styled.img`
    max-width:550px;
    height:auto;

    @media screen and (max-width: 1200px){
      width:450px;
      height:auto;
    }

    @media screen and (max-width: 950px){
      max-width:400px;
      height:auto;
    }

    @media screen and (max-width: 850px){
      width:350px;   
      height:auto;
    }

`;

export const Minimage = styled(motion.img)`
    max-width:486px;
    height:auto;
    border-radius:15px;

    @media screen and (max-width: 760px){
      width:350px;
      height:auto;
    }
`;

export const MotionGrid = styled(motion(Grid))`
`

export const MainTitle1 = styled(motion.div)`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;

  h2{
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 25px;
    color: #000000;
    text-align: center;
  }

  h3{
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #000000;

  }

  p{
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
  }
`
export const MainTitle2 = styled(motion.div)`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:left;

  h2{
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 25px;
    color:#1371FF;
    text-align: left;
  }

  h3{
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #1371FF;


  }

  p{
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
  }
`

export const LargeButton = styled(motion(Link))`
  font-size: 15px;
  font-weight:700;
  color:#fff; 
  padding:10px;  
  width:400px;
  height:30px;
  text-decoration: none; 
  cursor: pointer;
  text-align:center;
  border-radius:50px;
  background-color:${props=>props.bgcolor};
  @media screen and (max-width: 760px){
    width:200px;
    font-size: 12px;
  }
`;

export const PlanUl = styled(motion.ul)`

  line-height:45px;
  font-size: 13px;
  list-style:none;

  div{
    height:45px
  }
  li{
    

    span{
      width:100px;
      font-size:15px
    }
  }

`
export const Liste = styled.ul`
font-size: 25px;
list-style:none;
text-align:left;


h1{
  font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #000000;
}
li{
  margin-left:-40px;
  span{
     color:#1371FF
  }
}

p{

}
`
export const MainLink = styled(Link)`
 text-decoration:none

`


import React, {useEffect }  from 'react'
import { Button, Grid } from '@mui/material'
import Controls from '../../controls/Controls'
import {Form, useForm } from '../../controls/useForm'


const initialValues = {
    firstname:'',
    lastname:'',
    email:'',
    message: '',
}

   

const ContactForm = (props) => {

    const {addOrEdit, recordForEdit} = props

    const validate = (fieldValues = values) => {
        const temp = { ...errors }
        if ('firstname' in fieldValues)
            temp.firstname = fieldValues.firstname ? "" : "Champ requis."
        if ('lastname' in fieldValues)
            temp.lastname = fieldValues.lastname ? "" : "Champ requis."
        if ('email' in fieldValues)
            temp.email = (/$^|.+@.+..+/).test(fieldValues.email) ? "" : "Email non valide."  
        if ('message' in fieldValues)
            temp.message = fieldValues.message ? "" : "Champ requis."
       
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm
    } =useForm(initialValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }

    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])

    return (
        <Form  
          onSubmit={handleSubmit}
          title="Contact us"
          description="Please feel free to contact us with any questions or requests for information. Thank you for your interest"
        
        >
                <Grid container spacing={2}>
                     <Grid item xs={12} sm={6}>
                          <Controls.Input
                                name="firstname"
                                label="First Name"
                                value={values.firstname}
                                onChange={handleInputChange}
                                error={errors.firstname}
                            />
                     </Grid>
                     <Grid item xs={12} sm={6}>
                            <Controls.Input
                                name="lastname"
                                label="Last Name"
                                value={values.lastname}
                                onChange={handleInputChange}
                                error={errors.lastname}
                            />
                     </Grid>
                     <Grid item xs={12} sm={12}>
                          <Controls.Input
                              name="email"
                              label="Email address"
                              type="email"
                              value={values.email}
                              onChange={handleInputChange}
                              error={errors.email}
                              fullWidth
                             
                        />
                     </Grid>
                     <Grid item xs={12} sm={12}> 
                       
                       <Controls.Input
                              name="message"
                              label="Write your message"
                              value={values.message}
                              onChange={handleInputChange}
                              error={errors.message}
                              fullWidth
                              multiline
                              rows={5}
                          />
                      </Grid>
                    
                    <Grid item sm={12} xs={12} > 
                        <Button 
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            fullWidth
                        > 
                        {recordForEdit ? "Send Message" : "Send Message"} 
                        </Button>
                    </Grid>
                   
                </Grid>
        </Form>
    )
}

export default ContactForm
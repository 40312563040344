import React from 'react'
import { Box,Grid, IconButton ,Container} from '@mui/material'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import * as FaIcon from "react-icons/fa"


const P = styled(motion.p)`
    color:#fff
`;

const First = styled.div`
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
width:90%;

`
const Second = styled.div`
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
width:10%;
margin-right:10px;

`

const H1 = styled.h1`
font-size: 35px;
color:#FFF; 
@media screen and (max-width: 500px){
    text-align:center;
    font-size:20px;
  }
`

export const ButtonLink = styled(Link)`
  font-size: 18px;
  font-weight:400;
  color:#1371FF; 
  display: flex;
  align-items: center;
  padding:10px;
  width:250px;
  height:30px;
  text-decoration: none;
  cursor: pointer;
  border-radius:50px;
  background-color:#fff;
  line-height: 200px;
  justify-content:center;
`;



function Credit() {


  return (
  
      <Container>
                   <Grid  
             container 
             bgcolor="#1371FF"
             p={2}
             borderRadius={5}
            >
                <Grid item lg={10} xs={12}>
                    <Box>
                        <H1> <span></span> What are AI Credits ? </H1>
                        <P>IA credits allow you to pay spontaneously and rechargeably without the need for a subscription. JobGenius AI Writer 
                            is powered by cutting-edge AI technology ChatGPT. 
                            This means that with every job description you create, we leverage the power of AI to make it easier and faster for you.
                        </P>
                        <P>Our Pro plan includes unlimited AI Credits, while Basic and Free plans can purchase additional credits as needed.</P>
                    </Box>
                </Grid>
                <Grid item lg={2} xs={12}
                  display="flex"
                  justifyItems="center"
                  alignItems="center"
                >
                         <ButtonLink to="/login">
                            Get Started
                        </ButtonLink>
                </Grid>
            </Grid>
      </Container>
         
       
   
  )
}

export default Credit

// certificationReducer.js

import { ADD_AWARD, ADD_CERTIFICATION, DELETE_AWARD, DELETE_CERTIFICATION, UPDATE_AWARD, UPDATE_CERTIFICATION } from "../constants/resumeConstants";



const initialState = {
  certifications: [],
};

const certificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CERTIFICATION:
      // Construire un objet map avec uuid comme clé pour les items awards existants
      const existingCertificationsMap = state.certifications.reduce(
        (acc, certification) => ({ ...acc, [certification.uuid]: certification }),
        {}
      );

      // Parcourir les nouveaux items et mettre à jour ou ajouter au state
      const updatedCertifications = action.payload.map((newCertifications) => {
        const { uuid } = newCertifications;
        if (existingCertificationsMap.hasOwnProperty(uuid)) {
          // Mettre à jour l'item existant avec l'uuid correspondant
          return { ...existingCertificationsMap[uuid], ...newCertifications };
        } else {
          // Ajouter le nouvel item s'il n'existe pas déjà
          return newCertifications;
        }
      });

      return {
        ...state,
        certifications: updatedCertifications
      };
   
    case UPDATE_CERTIFICATION:
      return {
        ...state,
        certifications: state.certifications.map((certification) =>
        certification.id === action.payload.certificationId ? action.payload.updatedCertification : certification
        ),
      };
    case DELETE_CERTIFICATION:
      return {
        ...state,
        certifications: action.payload.filter((_, i) => i !== action.payload),
      };
    default:
      return state;
  }
};

export default certificationReducer;
  
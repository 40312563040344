import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography
} from '@mui/material'
import React from 'react'
import { colors } from '../../styles'

function PricingCard({ item }) {
  return (
    <Card 
      sx={{
        height: "100%",
        backgroundColor: () => (
          item?.categorie ? item.categorie === "Premium" ? `${colors.primary}` : `${colors.white}`
            :
            item?.product.name === "Premium" ? `${colors.primary}` : `${colors.white}`
        )
      }}
    >
      <CardHeader
        title={item?.title ? item.title : Math.round(item.amount / 100).toFixed(2) + ' $/month'}
        subheader={item?.subtitle ? item?.subtitle : item.product.description}
        titleTypographyProps={{
          align:
            'left',
          fontSize: 20,
          color: item?.categorie ? item.categorie === "Premium" ? `${colors.white}` : `${colors.black}`
            :
            item?.product.name === "Premium" ? `${colors.white}` : `${colors.black}`
        }}
        subheaderTypographyProps={{
          color: item?.categorie ? item.categorie === "Premium" ? `${colors.white}` : `${colors.black}`
            :
            item?.product.name === "Premium" ? `${colors.white}` : `${colors.black}`,
          fontSize: 12
        }}
        sx={{

          borderBottom: `1px solid ${colors.grayBlack}`
        }}
      />
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'baseline',
            mb: 2,
          }}
        >
          <Box>
            <Divider sx={{ backgroundColor: 'red', height: 3 }} />
          </Box>

          <Typography
            component="h2"
            textAlign="left"
            variant="h4"
            color="text.primary"
            sx={{
              fontWeight: 700,
              color: () => (
                item?.categorie ? item.categorie === "Premium" ? `${colors.white}` : `${colors.black}`
                  :
                  item?.product.name === "Premium" ? `${colors.white}` : `${colors.black}`
              )
            }}

          >
            {item?.categorie ? item.categorie : item.product.name}
          </Typography>
        </Box>
      </CardContent>
      <CardActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'baseline',
        }}
      >
        <Button
          fullWidth
          variant="outlined"
          sx={{
            "&:hover": {
              backgroundColor: `${colors.white}`
            },
            textTransform: "none",
            fontWeight: 700,
            backgroundColor: `${colors.white}`,
           // border: `1px solid ${colors.grayBlack}`,
            color: () => (
              item?.categorie ? item.categorie === "Premium" ? `${colors.black}` : `${colors.primary}`
                :
                item?.product.name === "Premium" ? `${colors.black}` : `${colors.primary}`
            ),
            border: () => (
              item?.categorie ? item.categorie === "Premium" ? `${colors.black}` : `${colors.primary}`
                :
                item?.product.name === "Premium" ? `1px solid ${colors.black}` : `1px solid  ${colors.primary}`
            )
          }}
        >
          Get Started
        </Button>
        <Box p={2} >
          <Typography
            component="h2"
            textAlign="right"
            variant="body2"
            color="text.primary"
            sx={{
              color: () => (
                item?.categorie ? item.categorie === "Premium" ? `${colors.white}` : `${colors.black}`
                  :
                  item?.product.name === "Premium" ? `${colors.white}` : `${colors.black}`
              )
            }}

          >

          </Typography>
        </Box>
      </CardActions>
    </Card>
  )
}

export default PricingCard
import React from 'react'
import styled  from 'styled-components';

const Card = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    border-right: 1px solid ${props=>props.color};
  

    
`;
const Span = styled.div`
    display:flex;
    align-items:center;
    color:#050D35;
    font-weight:700;
    font-size:32px;

    @media screen and (max-width: 760px){
      font-size:15px;
    }
`;
const B = styled.div`
    color:#51C9FF;
`;

const Subtitle = styled.span`
    
    color:#A4A5AA;
    font-size:15px;
    margin-top:25px;
    text-align:center;

    @media screen and (max-width: 760px){
      font-size:10px;
      margin-top:15px;
    }
`;


function Number({number,indice,subtitle,color,sign}) {
  return (
    <Card color={color} >
       <Span> {sign} {number} <B> {indice} </B></Span>
       <Subtitle>
         {subtitle}
       </Subtitle>
    </Card>
  )
}

export default Number

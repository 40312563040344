import { combineReducers } from 'redux';
import resumeReducer from './resumeReducer';
import storage from 'redux-persist/lib/storage';
import { 
  currentUserReducer,
  recrutorsReducer,
  updateUserReducer,
  userRegisterReducer, 
  userSigninReducer 
} from './userReducer';

import { 
  addSimulationReducer, 
  currentSimulationReducer, 
  deleteSimulationReducer, 
  listUserSimulationsReducer, 
  stepsSimulationReducer, 
  updateSimulationReducer 
} from './simulationReducer';

import { 
  listUserBusinesssReducer, 
  listBusinessReducer,
  addBusinessReducer,
  updateBusinessReducer,
  deleteBusinessReducer,
  businessDetailReducer,
} from './businessModelReducer';

import { 
  listUserResignationsReducer, 
  listResignationReducer,
  addResignationReducer,
  updateResignationReducer,
  deleteResignationReducer,
  resignationDetailReducer,
} from './resignationReducer';

import { 
  listUserCoverLettersReducer, 
  listCoverLettersReducer,
  addCoverLetterReducer,
  updateCoverLetterReducer,
  deleteCoverLetterReducer,
  coverLetterDetailReducer
} from './coverLetterReducer';
import { listSimulationMessageReducer, sendMessageReducer } from './messageReducer';




const initialState = {
  profile: {
    photo:null,
    firstname:'',
    lastname:'',
    email:'',
    phone:'',
    position: '',
    codePostal:'',
    city:'',
    address:'',
    addressComplement:''
  },
  experience: {
    experiences: [],
  },
  skills: {
    skills: [],
  },
  education: {
    educations: [],
  },
};

const rootReducer = combineReducers({
  resume:resumeReducer,
  
  userSignin:userSigninReducer,
  userRegister:userRegisterReducer,
  currentUser:currentUserReducer,
  userUpadter:updateUserReducer,
  createSimulation:addSimulationReducer,
  updateSimulation:updateSimulationReducer,
  deleteSimulation:deleteSimulationReducer,
  currentSimulation:currentSimulationReducer,
  stepsSimulation:stepsSimulationReducer,
  listUserSimulation:listUserSimulationsReducer,
   
  recrutorsList:recrutorsReducer,

  listUserBusinesses: listUserBusinesssReducer, 
  listBusinesses:listBusinessReducer,
  createBusiness: addBusinessReducer,
  updateBusiness: updateBusinessReducer,
  deleteBusiness: deleteBusinessReducer,
  detailBusiness: businessDetailReducer,

  listUserResignations: listUserResignationsReducer, 
  listResignations:  listResignationReducer,
  createResignation: addResignationReducer,
  updateResignation: updateResignationReducer,
  deleteResignation:  deleteResignationReducer,
  detailResignation:  resignationDetailReducer,
 
  listeUserCoverLetters: listUserCoverLettersReducer, 
  listCoverLetters: listCoverLettersReducer,
  createCoverLetter:  addCoverLetterReducer,
  updateCoverLetter:  updateCoverLetterReducer,
  deleteCoverLetter:  deleteCoverLetterReducer,
  detailCoverLetter:  coverLetterDetailReducer,
  createMessage: sendMessageReducer,
  listSimulationMessages:listSimulationMessageReducer
  
});

export const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
  'listeUserCoverLetters',
  'listCoverLetters',
  'createCoverLetter',
  'updateCoverLetter',
  'deleteCoverLetter',
  'detailCoverLetter',
  'createMessage',
  'listSimulationMessages',

  'detailResignation',
  'deleteResignation',
  'updateResignation',
  'listResignations',
  'createResignation',
  'listUserResignations',
  'listResignations',

  'userUpadter',
  'createSimulation',
  'updateSimulation',
  'deleteSimulation',
  'listUserSimulation',
  'currentUser',
   
  'listUserBusinesses', 
  'listBusinesses',
  'createBusiness',
  'updateBusiness',
  'deleteBusiness',
  'detailBusiness',]
};

const appReducer = (state, action) => {
  // Add logic for resetting state if needed
  return rootReducer(state, action); 
}; 

export default appReducer;
export { initialState };

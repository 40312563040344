import React from 'react'
import { Button } from '@mui/material';




export default function ActionButton(props) {

    const { color, children, onClick,variant,fullWidth } = props;

    return (
        <Button
            onClick={onClick}
            variant={variant}
            fullWidth={fullWidth}
            color={color}
            sx={{
                borderRadius:10,
                width:200,
                textTransform:"none",
                color:"#fff",
                border:'1px solid #518EF8',
                fontWeight:"400",
                backgroundColor:"#1371FF"

            }}
        >
            {children}
        </Button>
    )
}  
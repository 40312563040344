import React from 'react'
import { Content } from './Elements'
import { Container } from '@mui/material'
import Img from '../../images/circleleft.png'
import SingleTestemonial from '../../components/sections/SingleTestemonial'




function SectionDesc() {
  return (
    <Content 
      size="70vh" 
      bgcolor="#fff"
      style={{
          backgroundImage:`url(${Img})`,
          backgroundRepeat:'no-repeat',
          backgroundPosition:"left",
          backgroundPositionY:"center",
          backgroundSize:"370px",
          marginBottom:20

      }}
    viewport={{once:true}}
    initial={{opacity:0,y:150}}
    whileInView={{opacity:1,y:0}}
    transition={{type:"easeIn", duration:1,delay:0 }}
    
    >
       <Container>
          <SingleTestemonial/>
       </Container>
    </Content>
  )
}

export default SectionDesc

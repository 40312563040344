import React, { useEffect, useState } from 'react'
import { Content, H1, P } from './Elements'
import { Container, Grid} from '@mui/material'
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { searchJobData } from '../../data/searchJobData';
import MediaCard from '../../components/cards/MediaCard'; 

const Move = styled(motion(Grid))`
`
function SearchJob({translator}) {

    const [data, setData] = useState([]);

    useEffect(() => {
      setData(searchJobData)
    
      return () => {
        setData([])
      }
    }, [])
    
  return (
    <Content size="100vh" bgcolor="#fff" >
         <Container   >
                <Grid mt={10} mb={10} container spacing={2} >
                     <Move 
                     item 
                     lg={4} 
                     pr={2} 
                     viewport={{once:true}}
                      initial={{opacity:0,y:100}}
                      whileInView={{opacity:1,y:0}}
                      transition={{type:"easeIn", duration:1,delay:0 }}

                     >
                            <H1 style={{fontSize:30}}>
                               {translator("sections.searchJob.searchJob.h1")} 
                               <span style={{color:"#1371FF"}} >
                                 Jobnux
                              </span> 
                            </H1>
                            <P>
                            {translator("sections.searchJob.searchJob.p")}
                            </P>
                     </Move>
                     <Move 
                        container 
                        spacing={2} 
                        item lg={8}
                        alignItems="center"
                        viewport={{once:true}}
                        initial={{opacity:0,y:100}}
                        whileInView={{opacity:1,y:0}}
                        transition={{type:"easeIn", duration:1,delay:0 }}
                        style={{
                            boxSizing:"border-box",
                            height: "620px",
                            overflowY: "scroll",
                            borderRadius:"20px",
                            border:"2px solid #EDEDED"
                        }}
                        
                        >
                    
                            {data.map((item)=>(

                                <Grid item lg={6} sm={6} md={6} xs={12}  key={item.id} >
                                    <MediaCard item={item} />
                                </Grid>

                            ))}
                       
                     </Move>
                </Grid>
         </Container>
    </Content>
  )
}

export default SearchJob

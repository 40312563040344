import React from 'react';
import Container from '@mui/material/Container';
import { Box, Grid,} from '@mui/material';
import {Img} from './Elements';
import VideoImg from '../../images/image43.png'
import {  GradiantBackground } from '../../styles/GlobalStyles';
import ActionBtnLink from '../../components/buttons/ActionBtnLink';


function Banner({translator}) {

   
  return (
    <GradiantBackground 
        style={{height:'85vh'}} 
        viewport={{ once: true }}
        initial={{opacity:0,y:50}}
        whileInView={{opacity:1,y:0}}
        transition={{type:"easeIn", duration:1,delay:0 }}
    > 
            <Container >
                <Grid container  spacing={2} >
                        <Grid item lg={6} md={6} xs={12}>
                            <Box>
                                <h1> {translator("sections.heroBanner.title")}  </h1>
                                
                                <p>
                                {translator("sections.heroBanner.content")}
                                </p>
                            </Box>
                            <Box  
                               
                               gap={5} mt={10} 
                               sx={{
                                display:'flex',
                                justifyContent:{lg:'flex-start',md:'center',xs:'center'}
                               }} 
                            >
                               
                                <ActionBtnLink
                                   link={translator("sections.heroBanner.link")}
                                   title={translator("sections.heroBanner.btnTitle")}
                                />
                            </Box>
                           
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                            <Box 
                            sx={{
                                display:'flex',
                                justifyContent:{lg:'flex-start',md:'center',xs:'center'}
                               }} 
                            >
                                <Img
                                    src={VideoImg}
                                    alt='Jobnux.io -  start Job Interview Simulator with AI'
                                />
                            </Box>
                        </Grid>
                </Grid>
            </Container>
    </GradiantBackground>
   
  )
}

export default Banner

import * as BsIcon from 'react-icons/bs'
import * as MdIcon from 'react-icons/md'

export const frfeatureData = [{
    "id": "6404d481d54af6a7a9c8fced",
    "title": "Simulation d'entretien d'embauche",
    "description": "Préparez-vous à votre entretien en répondant à des questions interactives d'un recruteur virtuel utilisant la technologie vocale",
    "iconColor": "#fff",
    "bgColor": "#51C9FF",
    "icon": <BsIcon.BsVectorPen/>
  },{
    "id":  "6404d4c2b15091b4865e7ee9",
    "title": "Coach d'entretien d'embauche",
    "description": "Obtenez des conseils personnalisés sur la façon d'améliorer votre CV, de vous préparer à votre entretien et de vous démarquer de la foule. ",
    "iconColor": "#fff",
    "bgColor": "#51C9FF",
    "icon": <MdIcon.MdWorkOff/>
  },{
    "id": "6404d529723e4b02da0be8b7",
    "title": "Rédacteur de lettre de motivation",
    "description": "Gagnez du temps et obtenez une lettre de motivation professionnelle en quelques clics. Personnalisez votre CV pour un emploi cible",
    "iconColor": "#fff",
    "bgColor": "#51C9FF",
    "icon": <MdIcon.MdWork/>
  },{
    "id":"6404d54dddb2229f4f6f4a53",
    "title": "Rédacteur de lettre de démission",
    "description": "Vous quittez votre emploi ? Pas de soucis. Nous créerons une lettre de démission pour vous",
    "iconColor": "#fff",
    "bgColor": "#51C9FF",
    "icon": <MdIcon.MdWorkOff/>
  },{
    "id": "6404e53c0b637f4509567957",
    "title": "Créateur de business",
    "description": "Vous souhaitez démarrer votre propre entreprise mais ne savez pas par où commencer ? Notre outil alimenté par l'IA peut vous aider à générer des idées d'entreprise basées sur vos intérêts et compétences",
    "iconColor": "#fff",
    "bgColor": "#51C9FF",
    "icon": <MdIcon.MdWork/>
  },
  {
    "id": "6404e53c0b637f4501568957",
    "title": "Créateur intelligent de CV",
    "description": "Générez facilement des descriptions de poste efficaces et professionnelles qui attirent les bons candidats",
    "iconColor": "#fff",
    "bgColor": "#51C9FF",
    "icon": <MdIcon.MdWork/>
  }]

import React from 'react'
import { Container, Typography } from '@mui/material'
import { Content, H1, MotionGrid, P } from '../../styles/GlobalStyles'
import { colors } from '../../styles'

function MainHeroTitle({title,paragraph,subTitle}) {
  return (
    
    <Content
        size="50vh" 
        bgcolor="#fff" 
        viewport={{once:true}}
        initial={{opacity:0,y:100}}
        whileInView={{opacity:1,y:0}}
        transition={{type:"easeIn", duration:1,delay:0 }}
    >
        <Container>
            <MotionGrid 
                item 
                lg={12}
                xs={12} 
                pr={2} 
                
            >
                <Typography  color={`${colors.primary}`} textAlign="center"> {subTitle}  </Typography>
                <H1 style={{fontSize:30,textAlign:'center'}}>
                    {title}
                </H1>
                {
                    paragraph ?
                    <P  style={{textAlign:'center'}} > {paragraph} </P>
                    : ''
                }
                
            </MotionGrid>
        </Container>
     
  </Content>
  
  )
}

export default MainHeroTitle
import React, { useState,useEffect } from 'react'
import { Content, H1 } from './Elements'
import { Box, Container, Grid, Typography } from '@mui/material'
import { featureData } from '../../data/featureData'
import { frfeatureData } from '../../data/frfeatureData'
import FeatureCard from '../../components/cards/FeatureCard'
import Img from '../../images/Vector.png'
import { motion } from 'framer-motion';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n';



const ContenerMotion = styled(motion(Grid))`
`

function OurFeatures() {
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  
  const { t:features } = useTranslation('featureData');
  const { t } = useTranslation('homepage');

  const [data,setData] = useState([])

  useEffect(() => { 
    setData(selectedLanguage === "en" ? featureData: frfeatureData)
    return () => {
        setData([])
    }
  }, [])

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemMotion = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };
  
  return (
    <Content size="100vh" bgcolor="#FFF"
        style={{
            backgroundImage:`url(${Img})`,
            backgroundRepeat:'no-repeat',
            backgroundPosition:"right",
            backgroundPositionY:"bottom",
            marginBottom:20

        }}
    >
       
        <Container>
             <Grid container spacing={2} >
                <Grid item lg={12} xs={12}>
                    <Box>
                        <H1 style={{textAlign:'center'}}>{t("sections.ourFeatures.title")} </H1>
                        <Typography fontSize={18} textAlign='center' color='#5C5C5C' variant='subtitle1' component="p" >
                           {t("sections.ourFeatures.ourFeatures.p")}  
                        </Typography>
                    </Box>
                </Grid>
                <ContenerMotion 
                    container 
                    item 
                    lg={12} xs={12} spacing={6} 
                    variants={container}
                    initial="hidden"
                    animate="visible"
                    viewport={{once:true}}
                    
                    >
                {
                    data.map((item)=>(
                        <Grid item lg={4} md={4} sm={6} xs={6} key={item.id} >
                            <motion.div
                             variants={itemMotion}
                             viewport={{once:true}}
                            >
                            <FeatureCard
                                    item={item}
                                />
                            </motion.div>
                        </Grid>
                    ))
                }
                </ContenerMotion>
             </Grid>
             
             
        </Container>
       
       
    </Content>
  )
}

export default OurFeatures

import { combineReducers } from 'redux';
import profileReducer from './profileReducer';
import experienceReducer from './experienceReducer';
import skillsReducer from './skillsReducer';
import educationReducer from './educationReducer';
import projectReducer from './projectReducer';
import awardReducer from './awardReducer';
import certificationReducer from './certificationReducer';
import hobbieReducer from './hobbieReducer';
import languageReducer from './languageReducer';


const resumeReducer = combineReducers({
  profile: profileReducer,
  education: educationReducer,
  experience:experienceReducer,
  skill: skillsReducer,
  project:projectReducer,
  award:awardReducer,
  certification:certificationReducer,
  hobbie:hobbieReducer,
  language:languageReducer,
});

export default resumeReducer;
import * as BsIcon from 'react-icons/bs'
import * as MdIcon from 'react-icons/md'


export const featureData = [{
    "id": "6404d481d54af6a7a9c8fced",
    "title": "Job Interview Simulation",
    "description":"Prepare for your interview by answering interactive questions from a virtual recruiter using voice technology",
    "iconColor": "#fff",
    "bgColor": "#51C9FF",
    "icon": <BsIcon.BsVectorPen/>
  },{
    "id":  "6404d4c2b15091b4865e7ee9",
    "title": "Job interview Coach",
    "description":"Get personalized advice on how to improve your resume, prepare for your interview and stand out from the crowd. ",
    "iconColor": "#fff",
    "bgColor": "#51C9FF",
    "icon": <MdIcon.MdWorkOff/>
  },{
    "id": "6404d529723e4b02da0be8b7",
    "title": "Cover Letter Writer",
    "description":"Save time and get a professional cover letter in just a few clicks. Personalize your resume for a target job",
    "iconColor": "#fff",
    "bgColor": "#51C9FF",
    "icon": <MdIcon.MdWork/>
  },{
    "id":"6404d54dddb2229f4f6f4a53",
    "title": "Letter of Resignation Writer",
    "description":"Leaving your job? No worries. We will create a letter of resignation for you",
    "iconColor": "#fff",
    "bgColor": "#51C9FF",
    "icon": <MdIcon.MdWorkOff/>
  },{
    "id": "6404e53c0b637f4509567957",
    "title": "Business Creator",
    "description":"Looking to start your own business but don't know where to begin?  Our AI-powered tool can help generate business ideas based on your interests and skills",
    "iconColor": "#fff",
    "bgColor": "#51C9FF",
    "icon": <MdIcon.MdWork/>
  },
  {
    "id": "6404e53c0b637f4501568957",
    "title": "Job Description Writer",
    "description":"Effortlessly generate effective and professional job descriptions that attract the right candidates",
    "iconColor": "#fff",
    "bgColor": "#51C9FF",
    "icon": <MdIcon.MdWork/>
  }]
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import HeroBanner from '../../components/HeroSections/HeroBanner'
import BannerImage from '../../images/bannerCoach.png'
import Partners from '../../components/sections/Partners'
import FeaturesKey from '../../components/sections/FeaturesKey'
import { coachData } from '../../data/coachData'
import SectionTestemonials from '../../components/sections/SectionTestemonials'
import SectionStep from '../../components/sections/SectionStep'
import MainHeroTitle from '../../components/HeroSections/MainHeroTitle'
import Image1 from '../../images/Prez1.png'
import Image2 from '../../images/Prez1.png'
import Bande from '../../components/shared/Bande'
import SingleTestemonial from '../../components/sections/SingleTestemonial'
import ResultsNumbers from '../home/ResultsNumbers'
import SectionFaq from '../../components/sections/SectionFaq'
import { useTranslation } from 'react-i18next'


function JobInterviewCoach() {

  useEffect(() => {
   
    window.scrollTo({
        top:0
    })
 
}, [])

const {t} = useTranslation('coachPage')

  return (
    <div>
       <Helmet>
            <meta charSet="utf-8" />
            <title> {t("head.title")}</title>
            <meta name="description" content={t("head.description")} />
      </Helmet>
        <HeroBanner
          title={t("sections.heroBanner.title")} 
          paragraph= {t("sections.heroBanner.content")} 
          btnTitle= {t("sections.heroBanner.btnTitle")} 
          image={BannerImage}
          link={t("sections.heroBanner.link")} 
        />
        <Partners/>
        <FeaturesKey
          subtitle={t("sections.featuresKey.subTitle")} 
          title={t("sections.featuresKey.title")} 
          data={coachData}
        />
        <SectionTestemonials/>
        <MainHeroTitle
          subTitle={t("sections.heroTitle.subTitle")} 
          title={t("sections.heroTitle.title")} 
          paragraph={t("sections.heroTitle.paragraph")} 
        />
        {[
        {
          step:`${t("sections.steps.one.step")}` ,
          title:`${t("sections.steps.one.title")}` ,
          description:`${t("sections.steps.one.description")}` ,
          btnTitle: `${t("sections.steps.one.btnTitle")}` ,
          link: `${t("sections.steps.one.link")}`,
          image:Image1
        },
        {
          step:`${t("sections.steps.two.step")}` ,
          title:`${t("sections.steps.two.title")}` ,
          description:`${t("sections.steps.two.description")}` ,
          btnTitle: `${t("sections.steps.two.btnTitle")}` ,
          link: `${t("sections.steps.two.link")}`,
          image:Image2
        },
      ].map((stepData, index) => (
        <SectionStep key={index} {...stepData} />
      ))}
        <Bande
          title={t("sections.bande.title")}
          content={t("sections.bande.content")}
        
        />
        <SingleTestemonial/>
        <ResultsNumbers/>
        <SectionFaq/>

    </div>
  )
}

export default JobInterviewCoach
import React from 'react'
import { Container, Grid } from '@mui/material'
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Move = styled(motion(Grid))`
`

export const Content = styled.div`
  display:flex;
  align-items:center;
  min-height:${props=>props.size};
  background-color:${props=>props.bgcolor}
`;




export const Minimage = styled.img`
    max-width:486px;
    height:auto;
    border-radius:15px;

    @media screen and (max-width: 760px){
      width:350px;
      height:auto;
    }
`;


function SectionFlex({translation,image,alt,content,size,bgcolor,containerColor,link}) {
  return (
    <Content size={size} bgcolor={bgcolor} >
       <Container 
        style={{
            backgroundColor:`${containerColor}`,
            borderRadius:"20px",
        }}
       >
          <Grid 
            container 
            paddingTop="20px"
            paddingBottom="20px"
          >

            {
                translation ?
                <>
                   <Move 
                   item 
                   lg={6} md={6} sm={12} xs={12}
                   display="flex"
                   justifyContent="center"
                   viewport={{once:true}}
                   initial={{opacity:0,y:100}}
                   whileInView={{opacity:1,y:0}}
                   transition={{type:"easeIn", duration:1,delay:0 }}
                   >
                        <Minimage
                            src={image}
                            alt={alt}
                        />
                    </Move>
                    <Move 
                    item 
                    lg={6} md={6} sm={12} xs={12}
                    display="flex"
                    justifyContent="center"
                    viewport={{once:true}}
                    initial={{opacity:0,y:100}}
                    whileInView={{opacity:1,y:0}}
                    transition={{type:"easeIn", duration:1,delay:0 }}
                    >
                        {content}
                    </Move>
                  </>
                :
                <>
                   <Move 
                        item 
                        lg={6}  md={6} sm={12} xs={12}
                        display="flex"
                        justifyContent="center"
                        viewport={{once:true}}
                        initial={{opacity:0,y:100}}
                        whileInView={{opacity:1,y:0}}
                        transition={{type:"easeIn", duration:1,delay:0 }}
                    >
                        {content}
                    </Move>
                    <Move 
                        item 
                        lg={6}  md={6} sm={12} xs={12} pt={1} 
                        display="flex"
                        justifyContent="center"
                        viewport={{once:true}}
                        initial={{opacity:0,y:100}}
                        whileInView={{opacity:1,y:0}}
                        transition={{type:"easeIn", duration:1,delay:0 }}
                        >
                        <Minimage
                            src={image}
                            alt={alt}
                        />
                   </Move>
                 </>
            }
            
          </Grid>
       </Container>
    </Content>
  )
}

export default SectionFlex

import { GetCurrentUser, GetRecrutors, LoginUser, RegisterUser, UpdateUser } from "../../apiCalls/users";
const { 
    SIGNIN, 
    SIGNIN_SUCCESS,
    SIGNIN_FAIL, REGISTER, 
    REGISTER_SUCCESS, 
    REGISTER_FAIL, 
    GET_CURRENT_USER, 
    GET_CURRENT_USER_SUCCESS, 
    GET_CURRENT_USER_FAIL, 
    UPDATE_USER, 
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL, 
    GET_RECRUTORS, 
    GET_RECRUTORS_SUCCESS, 
    GET_RECRUTORS_FAIL 
  } = require("../constants/appConstants");



const signin = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SIGNIN, payload: payload });
    const response = await LoginUser(payload)
    dispatch({ type: SIGNIN_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: SIGNIN_FAIL, payload: error.message });
  }
}

const register = (payload) => async (dispatch) => {
 
  try {
    dispatch({ type: REGISTER, payload: payload });
    const response = await RegisterUser(payload)
    dispatch({ type: REGISTER_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: REGISTER_FAIL, payload:error.message });
  }
} 

const updateUser = (payload) => async (dispatch,getState)=> {
  const {userSignin : {userInfo}} = getState();
  dispatch({type:UPDATE_USER,payload:payload});
  try {
    const response = await UpdateUser(payload);
    dispatch({ type: UPDATE_USER_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: UPDATE_USER_FAIL, payload:error.message });
  }

}
 

const getRecrutors = () => async (dispatch) => {
  try {
    dispatch({ type: GET_RECRUTORS });
    const response = await GetRecrutors();
    dispatch({ type: GET_RECRUTORS_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: GET_RECRUTORS_FAIL, payload:error.message });
  }
}

const getCurrentUserInfo = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CURRENT_USER });
    const response = await GetCurrentUser();  
    dispatch({ type: GET_CURRENT_USER_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: GET_CURRENT_USER_FAIL, payload:error.message });
  }
}

export { 
  signin, 
  register,
  getCurrentUserInfo,
  updateUser,
  getRecrutors
};
import { Link } from "react-router-dom";
import styled from "styled-components";


export const FooterContainer = styled.div`
background-color:#10091B;
left: 0;
bottom: 0;
width:100%;
color:white
`;

export const LogoLink = styled(Link)`
    display: flex;
    align-items: center;
    justify-contnet:center;
    margin-top:20px;
    text-decoration:none;
`;

export const Logo = styled.img`
   width:325px;
   height:45px;
   @media screen and (max-width: 500px){
    width:255px;
    height:35px
  }
`;

export const SocialContainer = styled.div`
    margin-top:30px;
    display:flex;
    justify-content:flex-end;
    flex-direction:column;
    align-items:flex-end;
    gap:5px;

    @media screen and (max-width: 1196px){
        flex-direction:row;
        align-items:center;
        justify-content:center;
      }
`;

export const SocialLink = styled(Link)`
    text-decoration:none;
    color:#fff;
    background-color:#282232;
    font-size:15px;
    width:20px;
    height:20px;
    border-radius:50px;
    padding:10px;
    text-align:center;
    align-items:center;
    justify-content:center;
    display:flex;

   
    @media screen and (max-width: 414px){
        font-size:20px;
        border-radius:50px;
        padding:5px;
    }
`;

export const FooterLink = styled(Link)`
    text-decoration:none;
    color:#fff;
    font-size:14px;
    padding:10px;

    @media screen and (max-width: 414px){
        font-size:11px;
      }
    
`;

export const Nav = styled.div`
    display:flex;
    justify-content:flex-end;
    gap:15px;
    position:absolute;
    bottom:0px
`;


export const H3 = styled.h3`
    color:#fff;
    font-size:18px;
    padding:10px;
    font-weight:600;
    text-transform:uppercase;
    
`;

export const H1 = styled.h1`
    color:#fff;

    @media screen and (max-width: 414px){
        font-size:20px;
        padding:10px
      }
`;

export const H4 = styled.h4`
    color:#fff;
    font-size:18px;
    padding:10px;
    font-weight:600;
`;

export const Column = styled.div`
    display:flex;
    flex-direction:column;

    p{
     font-size:14px; 
     color:white
    }
`

export const Copyright = styled.p`
@media screen and (max-width: 414px){
    font-size:10px;
    
  }
`

export const ButtonLink = styled(Link)`
  font-size: 14px;
  font-weight:600;
  color:#1371FF; 
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left:20px;
  padding:10px;
  max-width:180px;
  height:30px;
  text-decoration: none;
  cursor: pointer;
  border-radius:10px;
  background-color:#fff;
  line-height: 200px;
  @media screen and (max-width: 500px){
    font-size:10px;
    max-width:120px;
    height:20px;
    margin-left:20px;
    padding:5px;
    
  }
`;
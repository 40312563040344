import React from 'react'
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

function RadioButton(props) { 
    const {name,label,value,onChange,items}= props;
  return (
  
        <FormControl component="fieldset">
          <FormLabel component="legend">  {label} </FormLabel>
          <RadioGroup aria-label=""    
            name={name} 
            value={value} 
            onChange={onChange} 
            >
                {
                    items.map(item =>(
                        <FormControlLabel key={item.id} value={item.id} control={<Radio/>} label={item.title}/>

                        
                    ))
                }
            </RadioGroup>
        </FormControl>
  
  )
}

export default RadioButton
import React, { useState,useEffect} from 'react'
import { Content, H1, MotionGrid } from '../../styles/GlobalStyles'
import { Box, Container } from '@mui/material'
import Accordeon from '../../components/accordeon/Accordeon'
import { faqData } from '../../data/faqData'

function SectionOne() {


const [questons,setQuestions] = useState([])

useEffect(() => {
 setQuestions(faqData)

  return () => {
    setQuestions([]) 
  }
}, [])


  return (
    <Content
        size="50vh" 
        bgcolor="#fff" 
        viewport={{once:true}}
        initial={{opacity:0,y:50}}
        whileInView={{opacity:1,y:0}}
        transition={{type:"easeIn", duration:1,delay:0 }}
    >
        <Container>
            <MotionGrid  container spacing={2} >
                <MotionGrid
                   item
                   lg={12}
                   xs={12}
                >
                    <H1 style={{textAlign:"center"}} >Faq</H1>
                </MotionGrid>
                <MotionGrid
                     item
                     lg={12}
                     xs={12}
                     display="flex"
                     justifyContent="center"
                     alignItems="center"  
                >
                  <Box
                   sx={{maxWidth:700}}
                  >
                    <Accordeon
                      data={questons}
                    />
                  </Box>
                   

                </MotionGrid>
            </MotionGrid>
        </Container>
    </Content>
  )
}

export default SectionOne
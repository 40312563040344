import Image1 from '../images/image41.png'



export const AiDrivenData = [{
    "id": "6404d481d54ad6a7a9c8fced",
    "title": "255 cover letter samples to start with",
    "description":"you'll find the cover letter your're looking for in our hundreds of ready-to use templates.",
    "image": Image1
  },{
    "id":  "6404d4c2d15091b4865e7ee9",
    "title": "Generated job tailored cover letters",
    "description":"gernerate quality cover letters in just a few clicks thanks to our AI.",
    "image": Image1
  },{
    "id": "6404d529d23e4b02da0be8b7",
    "title": "Create better cover letters, instantly",
    "description":"Let us showcase your skills in a concise, well-organized way.",
    "image": Image1
  }
]
import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Image30 from '../../images/Frame-3504.png'
import { Link } from 'react-router-dom';
import { Content } from '../../styles/GlobalStyles';
import { colors } from '../../styles';


const Move = styled(motion(Grid))`
`


const Image = styled.img`
  width:100%;
  height:auto;
  @media screen and (max-width: 500px){
    width:250px;
    height:auto;
  }
`

export const BtnCreateLink = styled(motion(Link))`
  font-size: 18px;
  font-weight:400;
  color:#fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:10px;
  max-width:300px;
  height:30px;
  text-decoration: none;
  border:2px solid #1371FF; 
  cursor: pointer;
  border-radius:10px;
  background-color:#1371FF;
  line-height: 200px;
  @media screen and (max-width: 760px){
    font-size: 12px;
  }
`;

function SectionStep({step,title,description,description1,image,btnTitle,height,width,link}) {
  return (
    <Content 
        size="50vh" 
        bgcolor={{color:`${colors.white}`}}
        style={{
            marginTop:"25px",
            marginBottom:"25px"
        }}
     >
        <Container>
            <Grid container spacing={2}>
                    <Move 
                        item 
                        lg={5} 
                        xs={12}
                        viewport={{once:true}}
                        initial={{opacity:0,y:100}}
                        whileInView={{opacity:1,y:0}}
                        transition={{type:"easeIn", duration:1,delay:0 }}
                        sx={{
                          marginBottom:10,
                          
                        }}

                    >
                        <Box>
                            <Typography sx={{color:`${colors.white}`}} > 
                                <span style={{color:`${colors.primary}`,fontWeight:'bold',fontSize:18}} > {step} </span>  
                            </Typography>
                            <Typography sx={{color:`${colors.black}`}} variant='h4' > 
                               {title}
                            </Typography>
                            <Typography sx={{color:`${colors.grayBlack}`}} paragraph> 
                               {description}
                            </Typography>
                            {
                                description1 ?
                                 <Typography sx={{color:`${colors.grayBlack}`}} paragraph> 
                                    {description1 ? description1 :""}
                                 </Typography>
                                :
                                ""
                            }
                           
                           
                        </Box>
                        <Box  display='flex' mt={2}  >
                            <BtnCreateLink to={link} 
                                bgcolor={colors.primary}
                                color={colors.white} 
                                style={{
                                    border:`1px solid ${colors.primary}`
                                }}
                            >
                               {btnTitle}
                            </BtnCreateLink>
                        </Box>
                    </Move>
                    <Move 
                      item 
                      lg={7} 
                      xs={12}
                      viewport={{once:true}}
                      initial={{opacity:0,y:100}}
                      whileInView={{opacity:1,y:0}}
                      transition={{type:"easeIn", duration:1,delay:0 }}
                      sx={{
                        minHeight:400,
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        background:`${colors.gradiant}`,
                        borderRadius:5,
                        width:'100%',
                        marginLeft:{lg:0,md:0,sm:0,xs:1}
                        
                        
                      }}

                        >
                            <Image
                                style={{
                                  maxHeight:height ? height :'auto',
                                  maxWidth:width ? width :'100%'
                                }}
                                src={image ? image : Image30}
                                alt="Jobgenius.ai - AI-Powered Job Description Writer - For companies"
                            />
                    </Move>
            </Grid>
        </Container>
    </Content>
  )
}

export default SectionStep

import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { colors } from '../styles';

// ----------------------------------------------------------------------

const StyledHeader = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2,2, 0),
  },
}));

// ----------------------------------------------------------------------

export default function SimpleLayout() {
  return (
    <>
      <StyledHeader>
             <Typography sx={{
                fontWeight:"bold",
                fontSize:"20px"
              }} color={`${colors.primary}`}>
                Jobnux.io
              </Typography>
      </StyledHeader>

      <Outlet />
    </>
  );
}
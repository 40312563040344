import React from 'react'
import { Content } from './Elements'
import { Box, Container, Grid } from '@mui/material'
import CustomerSpace from '../../images/image20.png'
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Image = styled(motion.img)`
  width:100%;
  height:auto
`

function UserSpace() {
  return (
    <Content size="70vh" bgcolor="#FFF">
       <Container>
          <Grid container>
          
                
                    <Box>
                        <Image 
                          src={CustomerSpace} 
                          alt="JobGenius - Espace client"
                          viewport={{once:true}}
                          initial={{opacity:0,x:-100}}
                          whileInView={{opacity:1,x:0}}
                          transition={{type:"easeIn", duration:1,delay:0 }}
                        />
                    </Box>
                
             
          </Grid>
       </Container>
    </Content>
  )
}

export default UserSpace

import React, { useEffect } from 'react'

function JobDescription() {


  useEffect(() => {
   
    window.scrollTo({
        top:0
    })

}, [])
  return (
    <div>JobDescription</div>
  )
}

export default JobDescription
// skillsReducer.js

import { ADD_SKILL, ADD_SUBSKILL, DELETE_SKILL, DELETE_SUBSKILL, UPDATE_SKILL, UPDATE_SUBSKILL } from "../constants/resumeConstants";


const initialState = {
  skills: [],
};

const skillsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SKILL:
       // Construire un objet map avec uuid comme clé pour les items skills existants
       const existingSkillsMap = state.skills.reduce(
        (acc, skill) => ({ ...acc, [skill.uuid]: skill }),
        {}
      );

      // Parcourir les nouveaux items et mettre à jour ou ajouter au state
      const updatedSkills = action.payload.map((newSkill) => {
        const { uuid } = newSkill;
        if (existingSkillsMap.hasOwnProperty(uuid)) {
          // Mettre à jour l'item existant avec l'uuid correspondant
          return { ...existingSkillsMap[uuid], ...newSkill };
        } else {
          // Ajouter le nouvel item s'il n'existe pas déjà
          return newSkill;
        }
      });

      return {
        ...state,
        skills: updatedSkills
      };
   
    case UPDATE_SKILL:
      return {
        ...state,
        skills: state.skills.map((skill) =>
          skill.id === action.payload.skillId ? action.payload.updatedSkill : skill
        ),
      };
    
    case DELETE_SKILL:
      return {
        ...state,
        skills: state.skills.filter((skill) => skill.id !== action.payload),
      }; 
   case ADD_SUBSKILL:
        return {
          ...state,
          skills: state.skills.map((skill) =>
            skill.id === action.payload.skillId
              ? { ...skill, subskills: [...skill.subskills, action.payload.subskill] }
              : skill
          ),
        };
    case UPDATE_SUBSKILL:
        return {
          ...state,
          skills: state.skills.map((skill) =>
            skill.id === action.payload.skillId
              ? {
                  ...skill,
                  subskills: skill.subskills.map((subskill) =>
                    subskill.id === action.payload.subskillId ? action.payload.updatedSubskill : subskill
                  ),
                }
              : skill
          ),
        };
    case DELETE_SUBSKILL:
      return {
        ...state,
        skills: state.skills.map((skill) =>
          skill.id === action.payload.skillId
            ? {
                ...skill,
                subskills: skill.subskills.filter((subskill) => subskill.id !== action.payload.subskillId),
              }
            : skill
        ),
      };
    default:
      return state;
  }
};

export default skillsReducer;

import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function SimpleMediaCard({item}) {
  return (
    <Card 
      elevation={0}
      sx={{ 
        maxWidth: 345,
        borderRadius:"15px",
        background: "linear-gradient(180deg, #136EF5 0%, #021634 83.8%)"

 
        }}>
      <CardMedia
        sx={{ 
            height: 240,
            
        }}
        image={item.image}
        title="green iguana"
      />
      <CardContent
       sx={{
        backgroundColor:"#fff"
       }}
      >
        <Typography gutterBottom variant="h6" component="h6">
         {item.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
         {item.description}
        </Typography>
      </CardContent>
    </Card>
  );
}
import React,{Suspense, useEffect,useState} from 'react';
import { Container, Grid, LinearProgress } from '@mui/material';
import { Box,Content, H1, P } from './Elements';
import PricingCard from '../../components/cards/PricingCard';
import { pricingData } from '../../data/princinData';

import { LargeButton, MainTitle1,MotionGrid } from '../../styles/GlobalStyles';
import { Helmet } from 'react-helmet';
import Credit from '../../components/shared/Credit';
import Accordeon from '../../components/accordeon/Accordeon';
import { faqData } from '../../data/faqData';
import SingleTestemonial from '../../components/sections/SingleTestemonial';
import Plan from './Plan';
import {GetStripePlans } from '../../apiCalls/stripe';




function Pricing() {

  const [questons,setQuestions] = useState([]);
  const [listPlans,setListPlans] = useState([]);



  const getPlans = async () => {

    try {
      const response = await GetStripePlans();
      if(response.success){
         setListPlans(response.data)
         console.log("liste des plans",response.data)
      }
    } catch (error) {
       console.log(error.message)
    }
     
  }


  useEffect(() => {

      getPlans()
    }, [])

    useEffect(() => {

    setQuestions(faqData)

      return () => {
        setQuestions([]) 
      }
    }, [])

  useEffect(() => {
   
    window.scrollTo({
        top:0
    }) 

}, [])


  return (
    <div>
         <Helmet>
          <meta charSet="utf-8" />
          <title> Pricing | Jobnux</title>
          <meta name="description" content="Explore Jobnux's available plans, each designed to give you the tools and support you need for successful job searching. Choose from a range of affordable plans with various features." />

       </Helmet>
     <Content>
     <Container>
            <MotionGrid container  spacing={2} >
                <MotionGrid  item xs={12}>
                  <Box>                
                      <H1>Pricing Plans to Fit Your Needs </H1>
                      <P>Choose from our range of affordable plans, designed to give you the tools and support you need to succeed in your job search.</P>
                        <MotionGrid container spacing={2} >
                            <Suspense fallback={<LinearProgress/>}>
                                {
                                  listPlans.length > 0 ?
                                    listPlans.sort((a,b)=> a.amount - b.amount).map((item)=>(
                                      <MotionGrid key={item.id} item lg={4} xs={12} >
                                          <PricingCard
                                            item={item}
                                          />
                                      </MotionGrid>
                                    ))
                                    :
                                    pricingData.map((item)=>(
                                      <MotionGrid key={item.id} item lg={3} xs={12} >
                                          <PricingCard
                                            item={item}
                                          />
                                      </MotionGrid>
                                    ))

                                  }
                                  
                            </Suspense>
                        </MotionGrid>
                    </Box>
                  
                </MotionGrid>
               <SingleTestemonial/>
               <MotionGrid mt={5} item lg={12} xs={12} >
                    <P>The free version will transform your writing. Advanced plans will take it to the next level.</P>
               </MotionGrid>
               <Credit/>
               <MotionGrid item lg={12} xs={12}>
                   <MainTitle1>
                         <h1>Frequently Asked Questions</h1>
                         <p> Each plan gives you access to our full range of features, and you can cancel or change your subscription at any time.</p>
                   </MainTitle1>
               </MotionGrid>
               <MotionGrid
                     item
                     lg={12}
                     xs={12}
                     display="flex"
                     justifyContent="center"
                     alignItems="center"  
                >
                    <Accordeon
                      data={questons}
                    />

                </MotionGrid>
                
               <Plan/>
                <MotionGrid
                     item
                     lg={12}
                     xs={12}
                     mt={5}
                     display="flex"
                     justifyContent="center"
                     alignItems="center"  
                >
                   <MainTitle1>
                    <h1>Discover the Future of Job Searching With Jobnux</h1>
                    <p>Our innovative tools and resources make finding your next career move easier than ever.</p>
                    <Box>
                        <LargeButton
                           bgcolor="#1371FF"
                           to="/login"
                        >
                            Create Your Free Account Now
                        </LargeButton>
                    </Box>
                   </MainTitle1>

                </MotionGrid>
            </MotionGrid>
      </Container>
      
     </Content>
    
    </div>
  )
}

export default Pricing

import React from 'react'
import { Button } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom';
import { colors } from '../../styles'



function ActionBtnLink({title,link}) {
    return (
       
            <Button
                to={link}
                component={RouterLink}
                variant="contained"
                sx={{
                    backgroundColor: `${colors.white}`,
                    color: `${colors.primary}`,
                    textTransform:'none',
                    borderRadius:5,
                    '&:hover':{
                        color: `${colors.white}`,
                    }
                }}
            >
                {title}
            </Button>
    )
}

export default ActionBtnLink
import Input from "./Input";
import Select from "./Select";
import CustomizedSwitch from "./CustomizedSwitch";
import ActionButton from "./ActionButton";
import LoginButton from "./LoginButton";
import RadioButton from "./RadioButton";


const Controls = {
    Input,
    Select,
    CustomizedSwitch,
    ActionButton,
    LoginButton,
    RadioButton  
}

export default Controls;  



export const pricingData = [
 
    {
        id:1,
        title:"0.00 $/month No card required",
        subtitle:"Get a feel for how it works. No payment required.",
        categorie:"Free",
        time:""
    },
    {
        id:2,
        title:"12.00 $/month",
        subtitle:"Access to all features plus unlimited AI Credits & free monthly review.",
        categorie:"Corporate",
        time:"30-day money-back guarantee"
    },
    {
        id:3,
        title:"24.00 $/month",
        subtitle:"Access to all features with a one-time payment.",
        categorie:"Premium",
        time:"30-day money-back guarantee"
    }
]
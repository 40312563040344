import React from 'react'
import { motion } from 'framer-motion'
import  styled from 'styled-components'
import { Typography } from '@mui/material'


const Description = styled.div`
  h1{
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
  }
`
const Card = styled(motion.div)`
width: 225px;
height: auto;
transition: transform ease 600ms;
border-radius:11px;


`
const Image = styled.img`
 border-radius:10px;
 width: 225px;
 height: 270px;  

 @media screen and (max-width: 514px){
  width: 200px;
 height: 250px;
}

@media screen and (max-width: 470px){
  width: 150px;
 height: 180px;
}
`



function TeamCard({image,title,details,alt}) {
  return (
     <Card>
        <Image   src={image} alt={alt} />
         <Description>
            <Typography
             variant="h1" 
             sx={{
              fontSize:{lg:20,md:20,xs:20},
              textAlign:'center',
              width:'100%'
             }}
             > 
             {title}
            </Typography>
             <Typography 
               sx={{
                fontSize:{lg:18,md:18,xs:12},
                textAlign:'left',
                width:165
               }}
               paragraph> 
                 {details} 
             </Typography>

        </Description>
     </Card>
  )
}

export default TeamCard
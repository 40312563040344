const { 
    CREATE_BUSINESS, 
    CREATE_BUSINESS_SUCCESS, 
    CREATE_BUSINESS_FAIL,
    LIST_USER_BUSINESSES,
    LIST_USER_BUSINESSES_SUCCESS,
    LIST_USER_BUSINESSES_FAIL,
    VIEW_BUSINESS_DETAILS,
    VIEW_BUSINESS_DETAILS_SUCCESS,
    VIEW_BUSINESS_DETAILS_FAIL,
    UPDATE_BUSINESS,
    UPDATE_BUSINESS_SUCCESS,
    UPDATE_BUSINESS_FAIL,
    DELETE_BUSINESS,
    DELETE_BUSINESS_SUCCESS,
    DELETE_BUSINESS_FAIL
} = require("../constants/appConstants");


function listBusinessReducer(state = {}, action) {
    switch (action.type) {
      case LIST_USER_BUSINESSES:
        return { loading: true };
      case LIST_USER_BUSINESSES_SUCCESS:
        return { loading: false, businesssListed: action.payload };
      case LIST_USER_BUSINESSES_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

  function listUserBusinesssReducer(state = {}, action) {
    switch (action.type) {
      case LIST_USER_BUSINESSES:
        return { loading: true };
      case LIST_USER_BUSINESSES_SUCCESS:
        return { loading: false, userBusinessListed: action.payload };
      case LIST_USER_BUSINESSES_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

  function businessDetailReducer(state = {}, action) {
    switch (action.type) {
      case VIEW_BUSINESS_DETAILS:
        return { loading: true };
      case VIEW_BUSINESS_DETAILS_SUCCESS:
        return { loading: false, businessInfo: action.payload };
      case VIEW_BUSINESS_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

function addBusinessReducer(state = {}, action) {
    switch (action.type) {
      case CREATE_BUSINESS:
        return { loading: true };
      case CREATE_BUSINESS_SUCCESS:
        return { loading: false, businessInfoCreated: action.payload };
      case CREATE_BUSINESS_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
}

function updateBusinessReducer(state = {}, action) {
    switch (action.type) {
      case UPDATE_BUSINESS:
        return { loading: true };
      case UPDATE_BUSINESS_SUCCESS:
        return { loading: false, businessUpdated: action.payload };
      case UPDATE_BUSINESS_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
}

function deleteBusinessReducer(state = {}, action) {
    switch (action.type) {
      case DELETE_BUSINESS:
        return { loading: true };
      case DELETE_BUSINESS_SUCCESS:
        return { loading: false, businessDeleted: action.payload };
      case DELETE_BUSINESS_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
}


export {
    listUserBusinesssReducer, 
    listBusinessReducer,
    addBusinessReducer,
    updateBusinessReducer,
    deleteBusinessReducer,
    businessDetailReducer
  }
import { axiosInstance, axiosInstanceUploade } from ".";

export const LoginUser = async (payload) => {
    const response = await axiosInstance("post",'/users/login',payload);
    return response;
}

export const RegisterUser = async (payload) => {
    const response = await axiosInstance("post",'/users/register',payload);
    return response;
}

export const UpdateUser = async (payload) => {
    const response = await axiosInstance("put",'/users/'+payload.userId,payload);
    return response;
}

export const GetCurrentUser = async () => {
    const response = await axiosInstance('get',"/users/get-current-user");
    return response; 
} 

export const GetRecrutors = async () => {
    const response = await axiosInstance('get',"/users/get-bots");
    return response;
}

export const UploadProfileImage = async (id,payload) => {
    const response = await axiosInstanceUploade('put',"/users/update-profile/"+id,payload);
    return response;
}
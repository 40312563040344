// hobbieReducer.js

import { ADD_HOBBIES, DELETE_HOBBIES, UPDATE_HOBBIES } from "../constants/resumeConstants";



const initialState = {
  hobbies: [],
};

const hobbieReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_HOBBIES:
      // Construire un objet map avec uuid comme clé pour les items  existants
      const existingHobbiesMap = state.hobbies.reduce(
        (acc, hobbie) => ({ ...acc, [hobbie.uuid]: hobbie }),
        {}
      );

      // Parcourir les nouveaux items et mettre à jour ou ajouter au state
      const updatedHobbies = action.payload.map((newHobbies) => {
        const { uuid } = newHobbies;
        if (existingHobbiesMap.hasOwnProperty(uuid)) {
          // Mettre à jour l'item existant avec l'uuid correspondant
          return { ...existingHobbiesMap[uuid], ...newHobbies };
        } else {
          // Ajouter le nouvel item s'il n'existe pas déjà
          return newHobbies;
        }
      });

      return {
        ...state,
        hobbies: updatedHobbies
      };
   
    case UPDATE_HOBBIES:
      return {
        ...state,
        hobbies: state.hobbies.map((hobbie) =>
          hobbie.id === action.payload.hobbieId ? action.payload.updatedHobbie : hobbie
        ),
      };
    case DELETE_HOBBIES:
      return {
        ...state,
        hobbies: action.payload.filter((_, i) => i !== action.payload),
      };
    default:
      return state;
  }
};

export default hobbieReducer;
  
import React from 'react'
import {Outlet} from 'react-router-dom'
import Footer from '../components/footer'
import Navbar from '../components/navbar'

function DefaultLayout() {
  return (
    <React.Fragment>
      <Navbar/>
      <main>
             <Outlet />
      </main>
      <Footer/>
    </React.Fragment>
  )
}

export default DefaultLayout
import React from 'react';
import Container from '@mui/material/Container';
import { Box, Grid, Typography} from '@mui/material';
import VideoImg from '../../images/image43.png'
import {  GradiantBackground } from '../../styles/GlobalStyles';
import styled from 'styled-components';
import ActionBtnLink from '../buttons/ActionBtnLink';

export const Img = styled.img`
    max-width:550px;
    height:auto;

    @media screen and (max-width: 760px){
      width:350px;   
      height:auto;
    }
`; 

function HeroBanner({title,btnTitle,paragraph,image,link}) {

 
  return (
    <GradiantBackground 
    style={{height:'95vh'}}
    viewport={{ once: true }}
    initial={{opacity:0,y:50}}
    whileInView={{opacity:1,y:0}}
    transition={{type:"easeIn", duration:1,delay:0 }}
    
    >
            <Container padding={1} >
                <Grid container  spacing={2} >
                        <Grid item lg={6} md={6} xs={12}>
                            <Box>
                                <Typography
                                  component='h1'
                                  variant='h1'
                                  sx={{
                                    fontSize:{lg:38},
                                    fontWeight:'bold',
                                    color:'#fff',
                                    textAlign:{lg:'left',xs:'left'}
                                  }}
                                > 
                                    {title}
                                 </Typography>
                                
                                <Typography  
                                    paragraph
                                    sx={{
                    
                                        textAlign:{lg:'left',md:'center',xs:'center'}
                                      }}
                                >
                                  {paragraph}
                                </Typography>
                            </Box>
                            <Box  
                                display='flex' 
                                gap={5} 
                                mt={10}  
                                sx={{
                                    justifyContent:{lg:'flex-start',xs:'center'}
                                }}
                            >
                                 <ActionBtnLink
                                   link={link}
                                   title={btnTitle}
                                />
                            </Box>
                           
                        </Grid>
                        <Grid 
                            item 
                            lg={6} 
                            md={6} 
                            xs={12}
                            sx={{
                                display:'flex',
                                justifyContent:{lg:'flex-start',xs:'center'}
                            }}

                        >
                            
                                <Img
                                    src={image ? image : VideoImg}
                                    alt='Jobnux.io - AI Resume'
                                />
                           
                        </Grid>
                </Grid>
            </Container>
    </GradiantBackground>
   
  )
}

export default HeroBanner

import React, { useState } from 'react';
import i18n from '../i18n';
import { IconButton, Menu, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language'; // Importez une icône pour le bouton de langue
import { Translate } from '@mui/icons-material';
import { colors } from '../styles';

const LanguageSelector = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleChangeLanguage = (newLanguage) => {
        i18n.changeLanguage(newLanguage);
        setSelectedLanguage(newLanguage);
        handleCloseMenu();
        //console.log(newLanguage)

    };

    return (
        <div>
            <IconButton
                aria-controls="language-menu"
                aria-haspopup="true"
                onClick={handleOpenMenu}
                size="large" // Taille de l'icône
                sx={{marginLeft:1}}
            >
                <Translate
                  sx={{color:`${colors.primary}`}}
                /> {/* Icône de langue */}
            </IconButton>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={() => handleChangeLanguage('en')}>En</MenuItem>
                <MenuItem onClick={() => handleChangeLanguage('fr')}>Fr</MenuItem>
            </Menu>
        </div>
    );
};

export default LanguageSelector;

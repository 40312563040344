import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, Grid, Typography} from '@mui/material';
import Slider from 'react-slick';
import TemplateCard from '../cards/TemplateCard';
import BtnLinkEndIcon from '../buttons/BtnLinkEndIcon';
import {  ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';





const CarouselContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
});

const CustomNextArrow = styled(Button)({
  position: 'absolute',
  top: '50%',
  right: 10,
  height:40,
  width:40,
  borderRadius:100,
  transform: 'translateY(-50%)',
  zIndex:1,
  cursor:'pointer',

});

const CustomPrevArrow = styled(Button)({
  position: 'absolute',
  top: '50%',
  left: 0,
  height:40,
  width:40,
  borderRadius:100,
  zIndex:1,
  transform: 'translateY(-50%)',
  cursor:'pointer',
 
});


const SlickArrowLeft = ({currentSlide,slideCount,...props}) => (
  <CustomPrevArrow
    {...props}
    className={
      "slick-prev slick-arrow" + 
      (currentSlide === 0 ? "slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <ArrowBackIos/>
  </CustomPrevArrow>
);
 

const SlickArrowRight = ({currentSlide,slideCount,...props}) => (
  <CustomNextArrow
    {...props}
    className={
      "slick-next slick-arrow" + 
      (currentSlide === slideCount - 1 ? "slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  >
    <ArrowForwardIos/>
  </CustomNextArrow>
)


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow:4,
  slidesToScroll:1,
  initialSlide: 0,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {  
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ],

  prevArrow:<SlickArrowLeft/>,
  nextArrow:<SlickArrowRight/>
  
};

const TemplateCarousel = ({
  data,
  filter,
  title,
  linkToViewAll,
  linkToCreateTemplate
}) => {
 

 

  return (
   
        <Box
          sx={{
              height:{lg:550,md:500},
              alignItems:'center',
              justifyContent:'center',
              display:'flex',
              flexDirection:'column',
              paddingLeft:{lg:5,md:5,xs:3}
          }}
        >
        <Grid 
          container 
          p={1}
          mb={5}
          mt={5}
        >
          <Grid  item lg={6} xs={12}>
              <Typography
               component='h4'
               typography='h4'
               sx={{
               
                textAlign:{lg:'left',xs:'center'}
               }}
           >
              {title}
            </Typography>
          </Grid>
          <Grid 
            item 
            lg={6} 
            xs={12}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap={2}

          > 
              <BtnLinkEndIcon
                 btnTitle='View All Templates'
                 to={linkToViewAll}
                 variant="outlined"
                 bgColor="white"
              />
              <BtnLinkEndIcon
                btnTitle='Create a cover letter now'
                to={linkToCreateTemplate}
                variant="contained"
                bgColor="#136EF5"
              />
          </Grid>
        </Grid>


            <CarouselContainer>
                <Slider 
                  {...settings}  
                  prevArrow={<SlickArrowLeft/>}
                  nextArrow={<SlickArrowRight/>}
                
                >
                  {data.filter(item => item.type === filter).map((temp)=> (
                    
                    <TemplateCard
                        key={temp.id}
                        job={temp.job}
                        categorie={temp.categorie}
                        content={temp.content}
                     />

                  ))}
                </Slider>
              
              </CarouselContainer>
        </Box>
  
   
  );
};

export default TemplateCarousel;

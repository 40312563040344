import {
    LIST_SIMULATION_CHAT_FAIL,
    LIST_SIMULATION_CHAT_MESSAGE,
    LIST_SIMULATION_CHAT_SUCCESS,
    RESET_SIMULATION_MESSAGES,
    SEND_CHAT_MESSAGE,
    SEND_CHAT_MESSAGE_FAIL,
    SEND_CHAT_MESSAGE_SUCCESS
} from "../constants/appConstants";




function sendMessageReducer(state = {}, action) {
    switch (action.type) {
        case SEND_CHAT_MESSAGE:
            return { loading: true };
        case SEND_CHAT_MESSAGE_SUCCESS:
            return { loading: false, messageCreated: action.payload };
        case SEND_CHAT_MESSAGE_FAIL:
            return { loading: false, error: action.payload };
        default: return state;
    }
}

function listSimulationMessageReducer(state = {}, action) {
    switch (action.type) {
        case LIST_SIMULATION_CHAT_MESSAGE:
            return { loading: true };
        case LIST_SIMULATION_CHAT_SUCCESS:
            return { loading: false, simulationMessages: action.payload };
        case LIST_SIMULATION_CHAT_FAIL:
            return { loading: false, error: action.payload };
        case RESET_SIMULATION_MESSAGES:
            return { loading: false, simulationMessages:[] };
        default: return state;
    }
}

export {
    sendMessageReducer,
    listSimulationMessageReducer,
}
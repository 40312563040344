import React from 'react';
import { Content } from '../../styles/GlobalStyles';
import { Container, Grid, Typography } from '@mui/material';
import UserCard from '../../components/cards/UserCard';
import Melchior from '../../images/melo2.png';
import Hanse from '../../images/hanse.png';
import { colors } from '../../styles';

function Mission({translator}) { 
  return (
    <Content 
      size="100vh"
       bgcolor={`${colors.grayLight}`} 
       viewport={{ once: true }}
       initial={{opacity:0,y:50}}
       whileInView={{opacity:1,y:0}}
       transition={{type:"easeIn", duration:1,delay:0 }}
       >
      <Container>
        <Grid mt={1} container spacing={3}>
          <Grid 
            item 
            lg={12}
            sx={{
              display:'flex',
              flexDirection:'column',
              justifyContent:'center'
            }}
          >
              <Typography 
                component="h1" 
                variant="h1"
                sx={{
                  fontSize:{lg:'30px',md:'25px',xs:'25px'},
                  fontWeight:'bold',
                  textAlign:"center",
                  width:'100%'
                }}
                >
                {translator("mission.title2")}
              </Typography>
              <Typography align="center" fontSize="25px" variant="h3">
                {translator("mission.title3")} 
              </Typography>
            
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12}>
            <Typography textAlign="justify" paragraph>
               {translator("mission.content1")}               
              <br />
              <br />
              {translator("mission.content2")} 
              <br />
              <br />
              {translator("mission.content3")} 
            </Typography>
          </Grid>

          <Grid item container lg={4} md={12} sm={12} xs={12} spacing={2} mb={4}>
            <Grid item lg={6} xs={6} mt={2}>
              <UserCard image={Melchior} name="Melchior" alt="MELCHIOR OBAME OBAME CEO FOUNDER OF JOBNUX, INTERVIEW SIMULATOR JOB" />
            </Grid>
            <Grid item lg={6} xs={6}>
              <UserCard image={Hanse} name="Hanse Roland Parfait" alt="Hanse Roland Parfait CTO LEAD DEV AT JOBNUX, INTERVIEW SIMULATOR JOB" />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Content>
  );
}

export default Mission;

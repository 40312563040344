import Image1 from '../images/F-3506.png'
import Image2 from '../images/F-3505.png'
import Image3 from '../images/F-3504.png'


export const coachData = [{
    "id": "6404d481d54ad6a7a9c8fced",
    "title": "Answers to all your questions",
    "description":"Practice realistic timed interviews with our interactive simulator, utilizing audio for a more immersive and authentic experience.",
    "image": Image1,
    "feature":""
  },{
    "id":  "6404d4c2d15091b4865e7ee9",
    "title": "Keep up to date",
    "description":"Receive tailored feedback and valuable insights to improve your interview skills and make a lasting impression on employers.",
    "image": Image2,
    "feature":""
  },{
    "id": "6404d529d23e4b02da0be8b7",
    "title": "Self Evaluation",
    "description":"Prepare for specific job opportunities by practicing interview questions tailored to the requirements of the position.",
    "image": Image3,
    "feature":""
  }
  
]


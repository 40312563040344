import * as AiIcons from 'react-icons/ai'
import * as FaIcons from 'react-icons/fa'
import * as IoIcons from 'react-icons/io'



export const planData = [

    {
        id:1,
        recommanded:false,
        title:"Free",
        price:"$0",
        date:"month",
        subtitle:"No credit card required",
        CoverLetter:{icon:<FaIcons.FaCheck  color="#1371FF" /> ,title:" 4 max"},
        personalizeCoverLetter:{icon:<AiIcons.AiOutlineClose color="#BEBEBE"/>,title:""},
        JobInterviews:{icon:<AiIcons.AiOutlineClose color="#BEBEBE"/>,title:""},
        SyntaxiqueCorrector:{icon:<FaIcons.FaCheck color="#1371FF" />,title:""} ,
        JobDescription:{icon:<FaIcons.FaCheck color="#1371FF" />,title:"" },
        LetterOfResignation:{icon:<FaIcons.FaCheck color="#1371FF" />} ,
        AccountManager:{icon:<AiIcons.AiOutlineClose color="#BEBEBE"/>,title:""},
        JobInterviewCoach:{icon:<AiIcons.AiOutlineClose color="#BEBEBE"/>,tilte:""},
        fonctionalities:[
            {
              id:1,
              title:"resume creation",
              status:"",
              number:1,
            },
            {
                id:2,
                title:"cover letter creation",
                status:"",
                number:4

            },
              {
                id:3,
                title:"resume downloads",
                status:"",
                number:1,
            },
            {
                id:4,
                title:"AI Resume Writer (need AI Credits)",
                status:"none"
            },
            {
                id:5,
                title:"access to Keyword Targeting",
                status:"Full"
            },
            {
                id:6,
                title:"access to Content Analysis",
                status:"Full"
            },
            {
                id:7,
                title:"resume template",
                status:"All",

            },
            {
                id:8,
                title:"resume photo",
                status:"Add",
                
              }

        ],

    },
    {
        id:2,
        recommanded:true,
        title:"Premium",
        price:"$12.99",
        date:"month",
        subtitle:"",
        CoverLetter:{icon:<FaIcons.FaCheck color="#1371FF" />,title:" 15 max"},
        personalizeCoverLetter:{icon:<AiIcons.AiOutlineClose color="#BEBEBE"/>,title:""},
        JobInterviews:{icon: <FaIcons.FaCheck color="#1371FF" />, tile: " 5 max"},
        SyntaxiqueCorrector:{icon:<AiIcons.AiOutlineClose color="#BEBEBE"/>,title:""},
        JobDescription:{icon:<AiIcons.AiOutlineClose color="#BEBEBE"/>,title:""},
        LetterOfResignation:{icon:<FaIcons.FaCheck color="#1371FF" />,title:" 5 max"},
        AccountManager:{icon:<FaIcons.FaCheck color="#1371FF" />,title:"" },
        JobInterviewCoach:{icon:<AiIcons.AiOutlineClose color="#BEBEBE"  />,title:""},
         fonctionalities:[
            {
              id:1,
              title:"resume creation",
              status:"unlimited"
            },
            {
                id:2,
                title:"cover letter creation",
                status:"unlimited"
            },
              {
                id:3,
                title:"resume downloads",
                status:"unlimited"
            },
            {
                id:4,
                title:"AI Resume Writer (need AI Credits)",
                status:"unlimited"
            },
            {
                id:5,
                title:"access to Keyword Targeting",
                status:"Full"
            },
            {
                id:6,
                title:"access to Content Analysis",
                status:"Full"
            },
            {
                id:7,
                title:"resume template",
                status:"All",

            },
            {
                id:8,
                title:"resume photo",
                status:"Add",
                
              }

        ],
    },
    {
        id:3,
        recommanded:false,
        title:"Corporate",
        price:"$30.99",
        date:"month",
        subtitle:"",
        CoverLetter:{icon:<IoIcons.IoMdInfinite  color="#1371FF" />, title:" Unlimited"},
        personalizeCoverLetter:{icon:<FaIcons.FaCheck color="#1371FF" />,title:"" },
        JobInterviews:{icon:<IoIcons.IoMdInfinite color="#1371FF"  />, title:" Unlimited"},
        SyntaxiqueCorrector:{icon:<FaIcons.FaCheck color="#1371FF" />,title:"" },
        JobDescription:{icon:<FaIcons.FaCheck color="#1371FF" />,title:"" },
        LetterOfResignation:{icon:<FaIcons.FaCheck color="#1371FF" />,title:""} ,
        AccountManager:{icon:<FaIcons.FaCheck color="#1371FF" />,title:""} ,
        JobInterviewCoach:{icon:<FaIcons.FaCheck color="#1371FF" />,title:"" },
        fonctionalities:[
            {
              id:1,
              title:"resume creation",
              status:"unlimited"
            },
            {
                id:2,
                title:"cover letter creation",
                status:"unlimited"
            },
              {
                id:3,
                title:"resume downloads",
                status:"unlimited"
            },
            {
                id:4,
                title:"AI Resume Writer (need AI Credits)",
                status:"unlimited"
            },
            {
                id:5,
                title:"access to Keyword Targeting",
                status:"Full"
            },
            {
                id:6,
                title:"access to Content Analysis",
                status:"Full"
            },
            {
                id:7,
                title:"resume template",
                status:"All"
            },
            {
                id:8,
                title:"resume photo",
                status:"Add"
              }

        ],
    }
]
import React from 'react';
import { Content} from '../../styles/GlobalStyles';
import { Container, Grid } from '@mui/material';
import Join from '../../components/shared/Join';
import CallToActionBtn from '../../components/buttons/CallToActionBtn';
import { colors } from '../../styles';

function Joinus({translator}) {
  return (
    <Content
      size="100vh"
      bgcolor="#fff"
      viewport={{ once: true }}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ type: "easeIn", duration: 1, delay: 0 }}
    >
      <Container>
        <Grid container spacing={5}>
          <Grid item lg={12} xs={12} style={{ display: "flex", justifyContent: "center" }}>
            <CallToActionBtn
               title={translator("btnJoin")}
               variant="contained"
               background={colors.green}
               color={colors.white}
               hoverColor={colors.white}
               link={translator("btnJoinLink")}
            />
          </Grid> 
          <Grid item lg={12} xs={12}>
            <Join />
          </Grid>
        </Grid>
      </Container>
    </Content>
  );
}

export default Joinus;

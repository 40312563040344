import React from 'react'
import { motion } from 'framer-motion';
import styled from 'styled-components'
import { Content, H1, Minimage, MotionGrid, Span } from '../../styles/GlobalStyles'
import { Container, Typography } from '@mui/material'
import Melo from '../../images/melo1.png'
import Customer from '../../images/image 9.png'


const CircleImg= styled(motion.img)`
    width:190px;
    height:auto;
    border-radius:50px;

    @media screen and (max-width: 760px){
      width:100px;
      height:100px;
    }
`;

const ColumnCenter = styled.div`
 display:flex;
 flex-direction:column;
 justify-content:center;
 align-items:center;

`;

const H3 = styled.h3`
`






function SectionTwo() {
  return (
    <Content
        size="100vh" 
        bgcolor="#fff" 
        viewport={{once:false}}
        initial={{opacity:0,y:50}}
        whileInView={{opacity:1,y:0}}
        transition={{type:"easeIn", duration:1,delay:0 }}
    >
    <Container>
       
        <MotionGrid
            viewport={{once:false}}
            initial={{opacity:0,y:150}}
            whileInView={{opacity:1,y:0}}
            transition={{type:"easeIn", duration:1,delay:0 }}
            container
            spacing={2}
        >
             <MotionGrid item lg={12} xs={12} >
                <H1  style={{textAlign:"center"}} >Our customers love coverlet</H1>
             </MotionGrid>
            <MotionGrid
              lg={6}
              md={6}
              xs={12}
              item
            > 
                <ColumnCenter>
                   <CircleImg src={Melo} alt="Jobnux - Melchior OBAME OBAME CEO"/>
                   <Typography  
                   sx={{
                    textAlign:{lg:'center',md:'center',xs:'justify'}
                   }}
                   paragraph>
                      coverlet.AI is an incredible tool for many people. It allows them to create 
                      professional cover letters in no time, using users' resume information to 
                      customize cover letters for each job and company they target. This allows us 
                      to provide quality service to our clients while saving valuable time and costs. 
                      With this tool, we are able to offer advanced customization options, 
                      such as templates for different types of jobs and adapted writing styles, 
                      to help our users stand out from other applicants.
                   </Typography>
                   <H3>Melchior OBAME</H3>
                   <Span>CEO & Founder at Jobnux</Span>
                </ColumnCenter>
            </MotionGrid>
            <MotionGrid
                item
                lg={6}
                md={6}
                xs={12}
                display="flex"
                justifyContent="center"
            >
                <Minimage src={Customer} alt="Jobnux.io - Melchior OBAME OBAME CEO"/>
            </MotionGrid>
        </MotionGrid>
    </Container>
</Content>
  )
}

export default SectionTwo
import React,{useEffect} from 'react'
import { Box, Grid, Typography } from '@mui/material'
import styled from 'styled-components'
import { ButtonLink, Content, H1 } from './Elements'
import {Helmet} from 'react-helmet'
import OurFeatures from './OurFeatures'
import UserSpace from './UserSpace'
import SectionDesc from './SectionDesc'
import SectionFlex from '../../components/sections/SectionFlex'
import  ImageSimulator from '../../images/Group23.png'
import Img7 from '../../images/image7.png'
import Frame3270 from '../../images/Frame3270.png'
import Imagebox1 from '../../images/imagebox1.png'
import SearchJob from './SearchJob'
import BusinessCreator from './BusinessCreator'
import Made from './Made'
import OurStory from './OurStory'
import Ready from '../../components/shared/Ready'
import ResultsNumbers from './ResultsNumbers'
import Banner from './Banner'
import SectionTestemonials from '../../components/sections/SectionTestemonials'
import Partners from '../../components/sections/Partners'
import ChromeExtension from '../../components/shared/ChromeExtensions'
import ModerneTemplate from '../../components/sections/ModerneTemplate'
import { cvTemplateData } from '../../data/cvTemplateData'
import { colors } from '../../styles'
import { useTranslation } from 'react-i18next';




const Paragraph = styled.p`
 padding-right:10px;
 font-size:18px;
 color:#fff;
 text-align:justify;

@media screen and (max-width: 760px){
  text-align:justify
}
`

function JobSimulator ({translator,link}){
  return(
    <Grid 
      container
    >
    <Box >
        <H1 style={{textAlign:"center"}} > <span style={{color:`${colors.primary}`}} > 
             AI </span> {translator("sections.jobsimulator.jobsimulator.h1")}
         </H1>             
    </Box>
    <Box>
      <Typography
       textAlign="justify"
       component="p"
       variant="body1"
       fontSize="18px"
      >
        {translator("sections.jobsimulator.jobsimulator.p-1")}  
        <span style={{color:`${colors.primary}`}} >
          {translator("sections.jobsimulator.jobsimulator.span-1")} 
        </span>
        {translator("sections.jobsimulator.jobsimulator.p-2")}
        <span style={{color:`${colors.primary}`}} >
        {translator("sections.jobsimulator.jobsimulator.span-2")}
        </span> 
        {translator("sections.jobsimulator.jobsimulator.p-3")}
      </Typography>
    </Box>
    <Box  display='flex' mt={4}  >
          <ButtonLink to={link} bgcolor={colors.primary} color={colors.white} >
          {translator("sections.jobsimulator.jobsimulator.btn")}
           </ButtonLink>
      </Box>
   </Grid>
  )
}


function JobInterview ({translator,link}){
  return(
    <Grid 
      container
    >
    <Box >
        <H1 style={{textAlign:"center",color:`${colors.white}`}} > 
        {translator("sections.jobinterviewCoach.jobinterviewCoach.h1")}
        </H1>             
    </Box>
    <Box>
    <Paragraph>
    {translator("sections.jobinterviewCoach.jobinterviewCoach.p1")}
      </Paragraph>
      <Paragraph
       
      >
       {translator("sections.jobinterviewCoach.jobinterviewCoach.p2")}
      </Paragraph>
    </Box>
    <Box  display='flex' mt={4}  >
          <ButtonLink to={link} bgcolor={colors.white}  color={colors.primary} style={{border:`1px solid ${colors.white}`}} >
          {translator("sections.jobinterviewCoach.jobinterviewCoach.btn")}
           </ButtonLink>
      </Box>
   </Grid>
  )
}

function CoverLetter ({translator,link}){
  return(
    <Grid 
      container
    >
    <Box >
        <H1 style={{textAlign:"center"}} > 
        <span style={{color:`${colors.primary}`}} >  AI </span> 
        {translator("sections.coverLetter.coverLetter.h1")}
        </H1>             
    </Box>
    <Box>
       <Paragraph style={{color:`${colors.black}`}} >
       {translator("sections.coverLetter.coverLetter.p1")}
       </Paragraph>
       <Paragraph
        style={{color:`${colors.black}`}}
       >
        {translator("sections.coverLetter.coverLetter.p2")}
       </Paragraph>
    </Box>
    <Box  display='flex' mt={4}  >
          <ButtonLink to={link} bgcolor={colors.primary} color={colors.white} >
          {translator("sections.coverLetter.coverLetter.btn")}
           </ButtonLink>
      </Box>
   </Grid>
  )
}

function AiNetworking ({translator}){
  return(
    <Grid 
      container
    >
     
    <Box>
    <H1  > <span style={{color:`${colors.primary}`}} >  AI </span> 
    {translator("sections.aiNetworking.aiNetworking.h1")}
     </H1>       
       <Paragraph style={{color:`${colors.black}`}} >
       {translator("sections.aiNetworking.aiNetworking.p1")}
       </Paragraph>
       <Paragraph
        style={{color:`${colors.black}`}}
       >
       {translator("sections.aiNetworking.aiNetworking.p2")}
       </Paragraph>
    </Box>

   </Grid>
  )
}

function ResignationLetter ({translator}){
  return(
    <Grid 
      container
    >
    <Box>
    <H1> 
      <span style={{color:`${colors.primary}`}} >  AI </span> 
      {translator("sections.resignationLetter.resignationLetter.h1")}
   </H1> 
       <Paragraph style={{color:`${colors.black}`}} >
       {translator("sections.resignationLetter.resignationLetter.p1")}
       </Paragraph>
       <Paragraph
        style={{color:`${colors.black}`}}
       >
       {translator("sections.resignationLetter.resignationLetter.p2")}
       </Paragraph>
    </Box>

   </Grid>
  )
}


function Home() {
  const { t } = useTranslation('homepage');

 

  useEffect(() => {
   
      window.scrollTo({
          top:0
      })
  }, [])


  
  return (
    <>
       <Helmet>
          <meta charSet="utf-8" />
          <title> {t("head.title")} </title>
       </Helmet>
      <Banner
        translator={t} 
      /> 
     <OurFeatures/>
     <UserSpace/> 
     <SectionDesc/>
     <ResultsNumbers/>
       <SectionFlex
        translation={true}
        alt="Jobnux - AI Job Simulator" 
        content={<JobSimulator translator={t}  link="/job-interview-simulator"  />}
        size="50vh"
        bgcolor={colors.white}
        containerColor={colors.white}
        image={ImageSimulator}
        
      />
      <SectionFlex
        translation={false}
        alt="Jobnux - AI  Job Interview Coach"
        content={<JobInterview translator={t}  link="/job-interview-coach" />}
        size="50vh"
        bgcolor={colors.white}
        containerColor="#10091B"
        image={Img7}
        
      />

       <SectionFlex
        translation={true}
        alt="Jobnux - AI Smart Cover Letter Writer"
        content={<CoverLetter translator={t}  link="/cover-letter-generator"/>}
        size="50vh"
        bgcolor={colors.white}
        containerColor={colors.white}
        image={Frame3270}
        
      />
       <SearchJob translator={t} /> 
       <SectionFlex
        translation={true}
        alt="Jobnux - AI Letter of Resignation Writer"
        content={<ResignationLetter translator={t}  link="/resignation-letter"/>}
        size="50vh"
        bgcolor={colors.white}
        containerColor={colors.white}
        image={Frame3270}
        
      />
     {/* <JobDescription
       link="/job-description"
  />*/}
      <SectionTestemonials/>
       <BusinessCreator/>
      <SectionFlex
        translation={true}
        alt="Jobnux - AI Networking"
        content={<AiNetworking translator={t} />}
        size="50vh"
        bgcolor={colors.white}
        containerColor={colors.white}
        image={Imagebox1}
      />
        <Made translator={t}/>
       <Partners/>
       <ChromeExtension/>
       <ModerneTemplate
        data={cvTemplateData} 
       />
       <OurStory/>
       <Content
        size="50vh" 
        bgcolor={colors.white} 
        viewport={{once:true}}
        initial={{opacity:0,y:50}}
        whileInView={{opacity:1,y:0}}
        transition={{type:"easeIn", duration:1,delay:0 }}
       >
          <Ready />
       </Content> 
      
    </>
     
  )
}

export default Home

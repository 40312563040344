import React,{useEffect} from 'react'
import { Content } from '../../styles/GlobalStyles'
import Ready from '../../components/shared/Ready'
import SectionTwo from './SectionTwo'
import SectionOne from './SectionOne'
import { Helmet } from 'react-helmet'




function Faq() {

  useEffect(() => {
   
    window.scrollTo({
        top:0
    })

}, [])
  return (
    <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Faq | Jobnux</title>
       </Helmet>
       
        <SectionOne/>
       <SectionTwo/> 
        <Content
            size="50vh" 
            bgcolor="#fff" 
            viewport={{once:true}}
            initial={{opacity:0,y:50}}
            whileInView={{opacity:1,y:0}}
            transition={{type:"easeIn", duration:1,delay:0 }}
        >
            <Ready/>
       </Content>
    </>
  )
}

export default Faq
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Content, MotionGrid } from '../../styles/GlobalStyles'
import { Container, Grid, Link, Typography } from '@mui/material'
import styled from 'styled-components'
import RegisterForm from '../../components/forms/authentication/RegisterForm'
import Img from '../../images/Group40.png'
import { register } from '../../redux/actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'



const Image = styled.img`
  width:100%;
  height:auto
`

function Register() {

  const navigate = useNavigate()
  const userRegister = useSelector(state => state.userRegister);
  const {userInfo} = userRegister;
  const dispatch = useDispatch();

  const handleRegister = async  (obj) => {
    
    try {
     
        toast.info("Web site in building", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      /*if(obj.password === obj.repassword){
         dispatch(register(obj));
         if(userInfo.success){
            toast.success(userInfo.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
         }else{
          toast.error(userInfo.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
         }
      }else{
        toast.error('Password doesn\'t match', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      /*const response = await RegisterUser(obj)
      if(response.success){
        //message.success(response.message)
        
        toast.success(response.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      }else{
        toast.error(response.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        throw new Error(response.message)
        
       
      }*/
    } catch (error) {
   
      console.log(error)
    }
  
  }

 /* useEffect(() => {
    if(localStorage.getItem('token')){
      navigate('/')
    }
  }, [])*/

  useEffect(() => {
   
    window.scrollTo({
        top:0
    })
}, [])

  return (
    <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Register | Jobnux</title>
        </Helmet>
        <Content>
            <Container>
                <Grid container spacing={1}  >
                    <MotionGrid p={2} container item lg={6} md={6} sm={6} xs={12}  sx={{display:{sm:'block',xs:'none'}}}  >
                        <MotionGrid mt={2} item lg={12} xs={12}>
                            <Typography  component="h6" >
                                    <b>Boost your career</b>
                            </Typography>
                            <Typography>
                                    The ultimate career tool. Our AI-powered 
                                    platform offers personalized interview simulation, job search support, 
                                    cover letter builder and more- all in one place.
                            </Typography>
                        </MotionGrid>
                        
                         <Image
                            src={Img}
                            alt="Jobnux.io - Register"
                         />
                            <Typography mt={2} >
                                  By signing up, you accept Jobnux.io's  
                                  <Link href='/term-and-privacy' sx={{textDecoration:'none',cursor:'pointer'}}> 
                                     privacy policy</Link> and 
                                  <Link href='/term-and-privacy' sx={{textDecoration:'none',cursor:'pointer'}}> terms of service.</Link> 
                            </Typography>
                    </MotionGrid>
                    <MotionGrid item lg={6} sm={6} xs={12} md={6}>
                            <RegisterForm
                              handleRegister={handleRegister}
                              
                            />
                    </MotionGrid>
                </Grid>
            </Container>
        </Content>
    </div>
  )
}

export default Register
import React from 'react'
import styled  from 'styled-components';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';

const Card = styled.div`
    width:350px;
    height:100%;
    border-radius:10px;
    padding-bottom:20px;
    flex-direction: column;
    display: flex;
    position:relatvie;
    justify-content:center;
    align-items:center;

    @media screen and (max-width:1155px){
        width:300px;
    }
    @media screen and (max-width:900px){
        width:350px;
    }
       
`;

const Header = styled.div`
   align-items:center;
   text-align:center;
   display:flex;
   flex-direction:column;
   height:50px;

`;

const Formule = styled.div`
   position:relative;
   top:30px;
   align-items:center;
   text-align:center;
   display:flex;
   flex-direction:column;
`;

const B = styled.b`
   font-size:25px;
    color:#000;
`;

const SpanHead = styled.span`
    padding:5px;
    color:#AEAEAE;
    height:30px;
    font-weight:400;
    @media screen and (max-width:1155px){
        font-size:15px;
        padding:2px;
    }
`

const Recommanded = styled.span`
     background-color:#28B446;
     color:#fff;
     padding:5px;
     border-bottom-left-radius:5px;
     border-bottom-right-radius:5px;
     text-transform:uppercase;
`

const Title = styled.span`
    padding:10px;
    font-size:25px;
    font-weight:400;
    color: #1371FF;
    text-transform:capitalize;
    @media screen and (max-width:1155px){
        font-size:20px;
        padding:5px;
    }
`;

const UL = styled.ul`
    
    height:100%; 
    @media screen and (max-width:980){
        font-size:12px;
    }
`;



const LI = styled.li`
    list-style:none;
    margin:20px;
    line-height:20px;
    margin-left:-15px;
    font-size:15px;
    
    @media screen and (max-width:980){
        font-size:10px !important;
        margin:10px !important;
        margin-left:-10px !important;
        span{
            font-size:10px ;
        }
    }

    span{

    }
`;

const Footer = styled.div`
   display:flex;
   justify-content: center;
   padding:10px;
`;

const Button = styled(Link)`
   
    text-decoration:none;
    display:flex;
    justify-content:center;
    align-items:center;
    justify-content:center;
    border-radius:60px;
    width:150px;
    height:40px;
    font-size:15px;
    color:#fff;
    background-color:#1371FF;
    border:1px solid #1371FF;
    cursor:pointer;

    :hover {
        color:#1371FF;
        background-color:#fff; 
        border:1px solid #1371FF;
    }

    @media screen and (max-width:980){
        width:100px !important;
        height:40px !important;
        font-size:12px;
    }
`;

const SpanBtn = styled.span`
    display:flex;
    justify-content:center;
    align-items:center;
    justify-content:center;
`;

const Icon = styled.span`
    font-size:20px
`;




function PricingTable({item}) {
  return (
    <Card 
      style={ item.recommanded 
        ? {boxShadow: "0px 2px 38px rgba(23, 18, 63, 0.18"}
        : {boxShadow:"#fff"}
    }
    >
        <Header>
             {item.recommanded ?  <Recommanded>recommanded</Recommanded> : "" }
        </Header>
        <Formule>
            <Title  > {item.title} </Title>
            <SpanHead  > <B >{item.price} </B> / {item.date} </SpanHead>
            <SpanHead style={{color:"#000"}} > {item.subtitle}</SpanHead>
            <Divider  />
        </Formule>
        <UL>
            <LI> <Icon>{item.CoverLetter.icon} </Icon> {item.CoverLetter.title} </LI>
            <LI>  <Icon> {item.personalizeCoverLetter.icon} </Icon> {item.personalizeCoverLetter.title} </LI>
            <LI> <Icon> {item.JobInterviews.icon } </Icon>{item.JobInterviews.title }    </LI>
            <LI> <Icon>  {item.SyntaxiqueCorrector.icon } </Icon> {item.SyntaxiqueCorrector.title } </LI>
            <LI> <Icon> {item.JobDescription.icon }  </Icon>{item.JobDescription.title } </LI>
            <LI> <Icon> {item.LetterOfResignation.icon } </Icon>{item.LetterOfResignation.title } </LI>
            <LI> <Icon> {item.AccountManager.icon } </Icon>{item.AccountManager.title } </LI>
            <LI> <Icon> {item.JobInterviewCoach.icon } </Icon>{item.JobInterviewCoach.title } </LI>
        </UL>
        <Footer>
            <Button to="#">
                <SpanBtn  >Choose this plan</SpanBtn>
            </Button>
        </Footer>
    </Card>
  )
}

export default PricingTable

import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Stack,
  Divider,
  IconButton
} from '@mui/material';
import { Visibility } from '@mui/icons-material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { BtnLoginLink, Span } from '../../../styles/GlobalStyles';
import { colors } from '../../../styles';
import * as FcIcons from 'react-icons/fc'
import * as FaIcons from 'react-icons/fa'


const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Adresse email invalide').required('Champ requis'),
  password: Yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Champ requis'),
  repassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Les mots de passe doivent correspondre')
    .required('Champ requis'),
});

const RegisterForm = ({ handleRegister}) => {

  const [showPassword, setShowPassword] = useState(false);


  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };



  const handleSubmit = (values) => {
    handleRegister(values)
  };

  return (
    <Container maxWidth="xs" sx={{padding:2}} >
      <Typography variant="h6" sx={{ textTransform: "capitalize" }} align="center" gutterBottom>
        Welcome to JobGenius
      </Typography>


      <Grid container spacing={2} padding={2} >
        <Grid container item xs={12} sm={12}>
          <Grid item xs={12} >
            <Stack direction="row" spacing={2} justifyContent="center"
              sx={{
                backgroundColor: "#1371FF7C",
                padding: 1,
                borderRadius: 20
              }}
            >
              <BtnLoginLink color={colors.white} bgcolor="transparent" to="/login">
                Login
              </BtnLoginLink>
              <BtnLoginLink color={colors.white} bgcolor={colors.primary} to="/register">
                Register
              </BtnLoginLink>
            </Stack>
          </Grid>
         
        </Grid>
        <Grid item xs={12} sm={12}>
          <Formik
            initialValues={{
              email: '',
              password: '',
              repassword: '',
            }}
            validationSchema={SignupSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <Field
                as={TextField}
                name="email"
                label="Adresse Email"
                variant="outlined"
                fullWidth
                margin="normal"
                helperText={<ErrorMessage name="email" />}
                sx={{

                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid #518EF8',
                      borderRadius: 20,
                      width: '100%'

                    },
                    '&:hover fieldset': {
                      border: '1px solid #518EF8',
                      borderRadius: 20,
                      width: '100%'

                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #518EF8',
                      borderRadius: 20,
                      width: '100%'

                    },
                  },
                }}
              />
              <Field
                as={TextField}
                type={showPassword ? 'text' : 'password'}
                name="password"
                label="Mot de passe"
                variant="outlined"
                fullWidth
                margin="normal"
                helperText={<ErrorMessage name="password" />}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleShowPasswordToggle}>
                      {showPassword === true ? <Visibility /> : <VisibilityOffIcon />}
                    </IconButton>
                  ),
                }}
                sx={{

                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid #518EF8',
                      borderRadius: 20,
                      width: '100%'

                    },
                    '&:hover fieldset': {
                      border: '1px solid #518EF8',
                      borderRadius: 20,
                      width: '100%'

                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #518EF8',
                      borderRadius: 20,
                      width: '100%'

                    },
                  },
                }}

              />
              <Field
                as={TextField}
                type={showPassword ? 'text' : 'password'}
                name="repassword"
                label="Confirm your password"
                variant="outlined"
                fullWidth
                margin="normal"
                helperText={<ErrorMessage name="repassword" />}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleShowPasswordToggle}>
                      {showPassword === true ? <Visibility /> : <VisibilityOffIcon />}
                    </IconButton>
                  ),
                }}
                sx={{

                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid #518EF8',
                      borderRadius: 20,
                      width: '100%'

                    },
                    '&:hover fieldset': {
                      border: '1px solid #518EF8',
                      borderRadius: 20,
                      width: '100%'

                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #518EF8',
                      borderRadius: 20,
                      width: '100%'

                    },
                  },
                }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth

              >
                S'inscrire
              </Button>
            </Form>
          </Formik>
        </Grid>

        <Grid item sm={12} xs={12} >
          <Stack direction="row" spacing={2} justifyContent="center">

            <Divider />
            <Span>OR</Span>
            <Divider />
          </Stack>
        </Grid>
        <Grid item sm={12} xs={12} >
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            startIcon={<FcIcons.FcGoogle />}
            sx={{
              border: `1px solid ${colors.gray}`,
              color: `${colors.black}`,
              textTransform: 'capitalize',
              "&:hover": {
                backgroundColor: `${colors.white}`,
                border: `1px solid ${colors.gray}`,
              }
            }}
          >
            Continue with Google
          </Button>

        </Grid>
        <Grid item sm={12} xs={12} >

          <Button
            variant="outlined"
            color="primary"
            fullWidth
            sx={{
              marginBottom: 2,
              border: `1px solid ${colors.gray}`,
              color: `${colors.black}`,
              textTransform: 'capitalize',
              "&:hover": {
                backgroundColor: `${colors.white}`,
                border: `1px solid ${colors.gray}`,
              }
            }}
            startIcon={<FaIcons.FaFacebookF color='#3B5999' />}
          >
            Continue with Facebook
          </Button>
        </Grid>

      </Grid>

    </Container>
  );
};

export default RegisterForm;

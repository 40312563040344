// Action types profile
export const SET_PHOTO = 'SET_PHOTO';
export const SET_FIRSTNAME = 'SET_FIRST_NAME';
export const SET_LASTNAME = 'SET_LAST_NAME';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_POSITION = 'SET_POSITION';
export const SET_CODE_POSTAL = 'SET_CODE_POSTAL';
export const SET_CITY = 'SET_CITY';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_ADDRESS_COMPLEMENT = 'SET_ADDRESS_COMPLEMENT';
export const SET_PHONE = 'SET_PHONE';
export const ADD_PROFILE = 'ADD_PROFILE';  

// Action types education
export const ADD_EDUCATION = 'ADD_EDUCATION';
export const UPDATE_EDUCATION = 'UPDATE_EDUCATION';
export const DELETE_EDUCATION = 'DELETE_EDUCATION';
export const ADD_RESPONSABILTIY = 'ADD_RESPONSABILTIY'
export const DELETE_RESPONSABILTIY = 'DELETE_RESPONSABILTIY'
export const UPDATE_RESPONSABILTIY = 'UPDATE_RESPONSABILTIY'

// Action types skill
export const ADD_SKILL = 'ADD_SKILL';
export const UPDATE_SKILL = 'UPDATE_SKILL';
export const DELETE_SKILL = 'DELETE_SKILL';

export const ADD_SUBSKILL = 'ADD_SUBSKILL';
export const UPDATE_SUBSKILL = 'UPDATE_SUBSKILL';
export const DELETE_SUBSKILL = 'DELETE_SUBSKILL';

// Action types experience
export const ADD_EXPERIENCE = 'ADD_EXPERIENCE';
export const UPDATE_EXPERIENCE = 'UPDATE_EXPERIENCE';
export const DELETE_EXPERIENCE = 'DELETE_EXPERIENCE';

// Action types award
export const ADD_AWARD = 'ADD_AWARD';
export const UPDATE_AWARD = 'UPDATE_AWARD';
export const DELETE_AWARD = 'DELETE_AWARD';

// Action types projects
export const ADD_PROJECT = 'ADD_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

export const ADD_TOOLS = 'ADD_TOOLS';
export const UPDATE_TOOLS = 'UPDATE_TOOLS';
export const DELETE_TOOLS = 'DELETE_TOOLS';

// Action types certicication
export const ADD_CERTIFICATION = 'ADD_CERTIFICATION';
export const UPDATE_CERTIFICATION = 'UPDATE_CERTIFICATION';
export const DELETE_CERTIFICATION = 'DELETE_CERTIFICATION';


// Action types Hobbies
export const ADD_HOBBIES = 'ADD_HOBBIES';
export const UPDATE_HOBBIES = 'UPDATE_HOBBIES';
export const DELETE_HOBBIES = 'DELETE_HOBBIES';


// Action types Languages
export const ADD_LANGUAGE = 'ADD_LANGUAGE';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';
import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { IoMdCard } from 'react-icons/io';
import VideoImg from '../../images/video.png';
import { Content, H1, Img, Span } from './Elements';
import { Typography } from '@mui/material';
import ActionBtnPrimary from '../../components/buttons/ActionBtnPrimary';



function Banner({translator}) {


  return (
    <Content size="80vh" bgcolor="#fff">
      <Container>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'flex-start'}}>
              <H1>
                {translator("sections.banner.banner.h1-1")}{' '}
                <span style={{ color: '#1371FF' }}>{translator("sections.banner.banner.h1-2")}</span> {translator("sections.banner.banner.h1-3")}
              </H1>
              <Typography sx={{ color: '#545454', fontSize: '18px', fontWeight: 'bold', textAlign:{xs:'justify'} }} paragraph>
              {translator("sections.banner.banner.p1")}
              </Typography>
            </Box>
            <Box display="flex" sx={{justifyContent:{xs:'center',lg:'flex-start',md:'flex-start'}}} gap={5} mt={5 }>
              <ActionBtnPrimary link="/register" title={translator("sections.banner.banner.btn")} />
            </Box>
            <Box display="flex" sx={{justifyContent:{xs:'center',lg:'flex-start',md:'flex-start'}}}  gap={1} mt={5}>
              <Span>
                <IoMdCard size={20} />
              </Span>
              <Span> {translator("sections.banner.banner.span")}</Span>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" justifyContent="center" >
              <Img src={VideoImg} alt="Jobnux.io -  Presentation Video" />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Content>
  );
}

export default Banner;

import React from 'react';
import { Content, MainTitle1 } from '../../styles/GlobalStyles';
import { Container, Grid, Typography } from '@mui/material';
import TeamCard from '../../components/cards/TeamCard';
import Melo from "../../images/melo2.png";
import Zuzo from "../../images/zuzo.png";
import Hanse from "../../images/hanse.png";
import Img from '../../images/image17.png';

function Team({translator}) {
  return (
    <Content size="100vh" bgcolor="#fff" viewport={{ once: true }}>
      <Container>
        <Grid container spacing={2} mb={5}>
          <Grid mt={2} item lg={12} xs={12}>
            <MainTitle1>
              <Typography fontSize={20} component="h2" variant="h2">
                 {translator("team.title2")} 
              </Typography>
              <Typography variant="h3"> 
                 {translator("team.title3")}
              </Typography>
              <Typography mt={1} variant="body1" paragraph>
                 {translator("team.content")}
              </Typography>
            </MainTitle1>
          </Grid>
          <Grid item lg={4} sm={6} md={4} xs={6}>
            <TeamCard
              image={Melo}
              title={translator("team.memberOne.title")}
              details={translator("team.memberOne.details")}
              alt={translator("team.memberOne.alt")}
            />
          </Grid>
          <Grid item lg={4} sm={6} md={4} xs={6}>
            <TeamCard
              image={Zuzo}
              title={translator("team.memberTwo.title")}
              details={translator("team.memberTwo.details")}
              alt={translator("team.memberTwo.alt")}
            />
          </Grid>
          <Grid item lg={4} sm={6} md={4} xs={6}>
            <TeamCard
              image={Hanse}
              t title={translator("team.memberThree.title")}
              details={translator("team.memberThree.details")}
              alt={translator("team.memberThree.alt")}
            />
          </Grid>
          
        </Grid>
      </Container>
    </Content>
  );
}

export default Team;

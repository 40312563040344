import React from 'react'
import { Button, Container, Grid } from '@mui/material'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { colors } from '../../styles'
import { useTranslation } from 'react-i18next'
import ActionBtnLink from '../buttons/ActionBtnLink'

const Card = styled(motion.div)`
    width: 100%;
    height: 176px;
    background: ${colors.gradiant};
    border-radius: 20px;
    display:flex;
`;

const First = styled.div`
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
width:90%;

`


const H1 = styled.h1`
font-size: 35px;
color:#FFF; 
@media screen and (max-width: 500px){
    text-align:center;
    font-size:20px;
  }
`



export const BtnLink = styled(Link)`
  font-size: 18px;
  font-weight:400;
  text-decoration: none;
  cursor: pointer;
`;



function Ready() {


    const { t } = useTranslation('sections');

  return (
  
        <Container>
            <Grid  container>
                <Card>
                    <First>
                        <H1>{t("readySection.title")} </H1>
                        <ActionBtnLink link="/register" title={t("readySection.buttonText")} />
                    </First>
                   
                </Card>
            </Grid>
        </Container>
   
  )
}

export default Ready

import React from 'react'
import { TextField } from '@mui/material';


export default function Input(props) {

    const {fullWidth,inputProps,sx, placeholder,name,type, label, value,error=null, onChange, disabled,multiline,rows } = props;
    return (
        <TextField
            variant="outlined"
            label={label}
            type={type}
            name={name}
            value={value}
            fullWidth={fullWidth}
            onChange={onChange} 
            disabled={disabled}
            multiline={multiline}
            placeholder={placeholder}
            rows={rows}
            sx={sx} 
            InputProps={inputProps}
            {...(error && {error:true,helperText:error})}
        />
    )
}
// projectReducer.js

import { ADD_PROJECT, ADD_TOOLS, DELETE_PROJECT, DELETE_TOOLS, UPDATE_PROJECT, UPDATE_TOOLS } from "../constants/resumeConstants";



const initialState = {
  projects: [],
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PROJECT:
      // Construire un objet map avec uuid comme clé pour les items projects existants
      const existingProjectsMap = state.projects.reduce(
        (acc, project) => ({ ...acc, [project.uuid]: project }),
        {}
      );

      // Parcourir les nouveaux items et mettre à jour ou ajouter au state
      const updatedProjects = action.payload.map((newProject) => {
        const { uuid } = newProject;
        if (existingProjectsMap.hasOwnProperty(uuid)) {
          // Mettre à jour l'item existant avec l'uuid correspondant
          return { ...existingProjectsMap[uuid], ...newProject };
        } else {
          // Ajouter le nouvel item s'il n'existe pas déjà
          return newProject;
        }
      });

      return {
        ...state,
        projects: updatedProjects
      };
    case ADD_TOOLS:
      return {
        ...state,
        projects: state.projects.map((project) =>
          project.id === action.payload.projectId
            ? { ...project, tools: [...project.tools, action.payload.tools] }
            : project
        ),
      };
    case UPDATE_PROJECT:
      return {
        ...state,
        projects: state.projects.map((project) =>
          project.id === action.payload.projectId ? action.payload.updatedProject : project
        ),
      };
    case UPDATE_TOOLS:
      return {
        ...state,
        projects: state.projects.map((project) =>
          project.id === action.payload.projectId
            ? {
                ...project,
                tools: project.tools.map((tool) =>
                  tool.id === action.payload.toolsId ? action.payload.updatedTools : tool
                ),
              }
            : project
        ),
      };
    case DELETE_PROJECT:
      return {
        ...state,
        projects: action.payload.filter((_, i) => i !== action.payload),
      }; 
    case DELETE_TOOLS:
      return {
        ...state,
        projects: state.projects.map((project) =>
          project.id === action.payload.projectId
            ? {
                ...project,
                tools: project.tools.filter((tool) => tool.id !== action.payload.toolsId),
              }
            : project
        ),
      };
    default:
      return state;
  }
};

export default projectReducer;

import { Box, Container, Grid, Rating,Typography, } from '@mui/material'
import React from 'react'
import BtnLinkEndIcon from '../buttons/BtnLinkEndIcon'
import Chrome from '../../images/chromelinkedin.png'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'


const Image = styled.img`
  width:200px;
  height:auto
`;

function ChromeExtension() {
  const { t:sections } = useTranslation('sections');
  return (
    <Box
     
    >
     <Container sx={{height:"100%"}} >
        <Grid 
            container 
            spacing={1} 
            alignItems="center"
            justifyContent="center"
            sx={{height:"100%"}}
        >
               <Grid 
                item 
                lg={3} 
                xs={12} 
                sx={{
                    display:'flex',
                    justifyContent:{lg:'flex-start',xs:'center'}
                }}
                
                >
                    <Image alt="Jobnux AI - Chrome Extension" src={Chrome} />
               </Grid>
               <Grid item container spacing={2}  lg={6} xs={12} >
                    <Grid  
                      item 
                      xs={12}
                      display="flex"
                      justifyContent="center"
                    >
                        <Rating  value={5} />
                    </Grid>
                    <Grid  
                     item
                     xs={12}                 
                    >
                        <Typography 
                            variant='h1' 
                            component='h1' 
                            fontWeight='bold'
                            fontSize={20}
                            sx={{textAlign:{xs:'center',md:'left',lg:'left'}}}
                        >
                            {sections("chromeExtension.title")}
                        </Typography>
                        <Typography 
                          paragraph
                          sx={{textAlign:{xs:'center',md:'left',lg:'left'}}}
                        >
                           {sections("chromeExtension.description")}
                        </Typography> 
                    </Grid>
                    <Grid  
                     item
                     xs={12}
                    >
                        <Typography 
                          variant='h5' 
                          component='h5' 
                          fontWeight='bold'
                          sx={{textAlign:{xs:'center',md:'left',lg:'left'}}} 
                        >
                            {sections("chromeExtension.users")}
                        </Typography>
                        
                    </Grid>
               </Grid>
               <Grid 
               item 
               lg={3} 
               xs={12} 
                sx={{
                    display:'flex',
                    justifyContent:{lg:'flex-end',xs:"center"},
                    alignItems:"flex-end",
                    alignContent:'flex-end',
                    
                    
                }}
               >
                             <BtnLinkEndIcon 
                                to="#" 
                                bgColor="" 
                                textColor="#1371FF"
                                btnTitle={sections("chromeExtension.buttonText")}
                                variant="outlinde"
                                radius="50px"

                              />
               </Grid>
        </Grid>
    </Container>
    </Box>
   
  )
}

export default ChromeExtension
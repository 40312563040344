import React from 'react'
import { Button } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom';



function CallToActionBtn({
    title,
    link,
    variant,
    background,
    color,
    hoverColor
 }) {
    return (
       
            <Button
                to={link}
                component={RouterLink}
                variant={variant}
                sx={{
                    backgroundColor: `${background}`,
                    color: `${color}`,
                    textTransform:'none',
                    borderRadius:5,
                    '&:hover':{
                        color: `${hoverColor}`,
                    }
                }}
            >
                {title}
            </Button>
    )
}

export default CallToActionBtn
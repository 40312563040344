import React from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';

export default function Select(props) {

    const { name, label, value,error=null, onChange, options,disabled,fullWidth} = props;

    return (
        <FormControl variant="outlined" fullWidth
        
        {...(error && {error:true})}>
            <InputLabel>{label}</InputLabel>
            <MuiSelect
                sx={{border:'1px solid #fff'}}
                label={label}
                name={name}
                value={value}
                disabled={disabled}
                onChange={onChange}>
                <MenuItem  value=""> -- Select Item --</MenuItem>
                {
                    options.map(
                        item => (<MenuItem sx={{color:'black'}} key={item._id} value={item._id}>{item.title}</MenuItem>)
                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
import React, { useEffect, useState } from 'react'
import {  Fab, IconButton, styled } from '@mui/material'
import * as FaIcon from "react-icons/fa"


const ScrollToTopButtonDiv = styled("div")(({theme})=> ({
    position:'fixed',
    bottom:theme.spacing(1),
    right:theme.spacing(1),
    opacity:0,
    visibility:'hidden',
    transition:'opacity 0.3s ease, visibility 0.3s ease',
    '&.visible':{
      opacity:1,
      visibility:'visible'
    }

}))


function ScrollToTopButton() {


  const [showButton,setShowButton] = useState();

  useEffect(() => {
    window.addEventListener('scroll',handleScroll);
    return () => window.removeEventListener('scroll',handleScroll);
  
  }, []);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    const middleOfPage = windowHeight/2;

    setShowButton(scrollY > middleOfPage)
  };

  const scrollToTop = () => {
    window.scrollTo({
      top:0,
      behavior:'smooth'
    })
  }
  



  return (
       <ScrollToTopButtonDiv className={`${showButton ? 'visible' : ''}`}>
            <Fab
              color="primary"
              aria-label='scroll-to-top'
              onClick={scrollToTop}
            >
                <FaIcon.FaArrowUp size={25} />
            </Fab>
       </ScrollToTopButtonDiv>
  )
}

export default ScrollToTopButton
import React from 'react';
import * as MdIcons from 'react-icons/md';


export const getMenuItems =  (t) => {

  return [
    {
      title: t('navbar.nav.nav.features'),
      path: '#',
      icon: '',
      button:'',
      iconClosed: <MdIcons.MdOutlineKeyboardArrowDown />,
      iconOpened: <MdIcons.MdKeyboardArrowUp />,
      large:true, 
      subNav: [
        { 
          title: t('navbar.nav.subnav.features.subnav.aiInterview'),
          path: '/ai-job-interview-simulation',
          icon: '',
          description:t('navbar.nav.subnav.features.subnav.aiInterview.desc'),
          cName: 'sub-nav'
        },
        {
          title: t('navbar.nav.subnav.features.subnav.aiResume'),
          path: '/ai-online-cv-creation',
          icon: '',
          description:t('navbar.nav.subnav.features.subnav.aiResume.desc'),
          cName: 'sub-nav'
        },
       
        {
          title: t('navbar.nav.subnav.features.subnav.aiResignation'),
          path: '/ai-resignation-letter-generator',
          icon: '',
          description:t('navbar.nav.subnav.features.subnav.aiResignation.desc'),
          cName: 'sub-nav'
        },
        {
          title: t('navbar.nav.subnav.features.subnav.aiCoverletter'),
          path: '/ai-cover-letter-generation',
          icon: '',
          description:t('navbar.nav.subnav.features.subnav.aiCoverletter.desc'),
          cName: 'sub-nav'
        },
       /* {
          title: 'Job Description Writer',
          path: '/job-description',
          icon: '',
          description:"Create job description for your poste",
          cName: 'sub-nav'
        },*/
        {
          title: t('navbar.nav.subnav.features.subnav.aiCoach'),
          path: '/ai-career-coach',
          icon: '',
          description:t('navbar.nav.subnav.features.subnav.aiCoach.desc'),
          cName: 'sub-nav'
          
        },
        {
          title: t('navbar.nav.subnav.features.subnav.aiBusiness'),
          path: '/ai-business-generation',
          icon: '',
          description:t('navbar.nav.subnav.features.subnav.aiBusiness.desc'),
          cName: 'sub-nav'
          
        },
       
        
      ]
    },
    {
      title: t('navbar.nav.nav.pricing'),
      path: 'pricing',
      icon: '',
      button:'',
    },
    {
      title: t('navbar.nav.nav.example'),
      path: 'example',
      icon: '',
      button:'',
    },
    {
      title: t('navbar.nav.nav.ressources'),
      path: '#',
      icon: '',
      button:'',
      iconClosed: <MdIcons.MdOutlineKeyboardArrowDown />,
      iconOpened: <MdIcons.MdKeyboardArrowUp />,
      subNav: [
       /* {
          title: t('navbar.nav.subnav.ressources.subnav.blog'),
          path: '/blog',
          icon: '',
          cName: 'sub-nav'
        },*/
       
        {
          title: t('navbar.nav.subnav.ressources.subnav.faqs'),
          path: '/faq',
          icon: '',
          cName: 'sub-nav'
        },
        {
          title: t('navbar.nav.subnav.ressources.subnav.about'),
          path: '/about-jobnux',
          icon: '',
          cName: 'sub-nav'
        }
      ]
    },
    {
      title: t('navbar.nav.nav.signin'),
      path: '/signin',
      icon: '',
      button:'yes',
      backgroundColor:'#fff',
      textColor:'#1371FF',
    },
    /*{
      title: t('navbar.nav.nav.newsletter'),
      path: '/newsletter',
      icon: '',
      button:'yes',
      backgroundColor:'#fff',
      textColor:'#1371FF',
    },
    {
      title: t('navbar.nav.nav.start'),
      path: '/register',
      icon: '',
      button:'yes',
      backgroundColor:'#1371FF',
      textColor:'#fff',
    },*/
   
  ]; 

}



import React from 'react'
import { GradiantBackground } from '../../styles/GlobalStyles'
import { Container, Grid } from '@mui/material'
import styled from 'styled-components'

const Image = styled.img`
  width:100%;
  height:auto
`

function SectionGradiant({left,image,alt}) {
  return (
    <GradiantBackground
        style={{height:'100vh'}}
    >
        <Container>
            <Grid  container spacing={2} >
                    <Grid item lg={6} xs={12} >
                            {left}
                    </Grid>
                    <Grid item lg={6} xs={12} >
                           <Image  src={image} alt={alt} />
                    </Grid>
            </Grid>
        </Container>
    </GradiantBackground>
  )
}

export default SectionGradiant
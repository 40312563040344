import React from 'react'
import {Button} from '@mui/material';
import { Link } from 'react-router-dom';

  


export default function BtnLinkEndIcon(props) {

    const {btnTitle,icon,to,variant,textColor,bgColor,radius,borderColor } = props;

    return (
        
        <Link  
            to={to} 
            style={{
                textDecoration:'none' 
            }}
        >  
            <Button   
                sx={{
                    textTransform:'capitalize',
                    height:{lg:50,xs:30},
                    minWidth:100,
                    color:`${textColor}`, 
                    border:`1px solid  ${borderColor ? borderColor : bgColor }`,
                    backgroundColor:`${bgColor}`,
                    fontSize:{xs:12},
                    borderRadius:`${radius}` ? `${radius}` : 0,
                    '&:hover':{
                        color:`${textColor}`,
                        backgroundColor:`${bgColor}`,
                    }

                }}   
                variant={variant}
                endIcon={icon}
            >
               {btnTitle}
            </Button>
        </Link>
        
    )
}


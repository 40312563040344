import React,{useEffect,useState} from 'react'
import { Content, H1 } from '../../styles/GlobalStyles'
import { Box, Container, Grid, Typography } from '@mui/material'
import Accordeon from '../accordeon/Accordeon'
import { faqData } from '../../data/faqData'
import { useTranslation } from 'react-i18next'


function SectionFaq() {

  const { t } = useTranslation('sections'); 
  const [questons,setQuestions] = useState([])

  useEffect(() => {
   setQuestions(faqData)
  
    return () => {
      setQuestions([]) 
    }
  }, [])


  return (
    <Content
        size="50vh" 
        bgcolor="#fff" 
        viewport={{once:true}}
        initial={{opacity:0,y:50}}
        whileInView={{opacity:1,y:0}}
        transition={{type:"easeIn", duration:1,delay:0 }}
    >
        <Container   >
            <Grid mt={10} container>
            
                 <Grid item lg={12} xs={12} display="flex" justifyContent="center" flexDirection="column" >
                  
                    <H1 style={{textAlign:"center"}}>
                      {t("sectionFaq.title")}
                    </H1>

                    <Typography textAlign="center" paragraph> 
                       {t("sectionFaq.content")}
                    </Typography>
                 </Grid>
                <Grid item lg={12} xs={12} display="flex" justifyContent="center" >
                  <Box
                    sx={{width:700,marginBottom:10}}
                  >
                   <Accordeon
                      data={questons}
                    />
                  </Box>
                  
                </Grid>
            </Grid>
          </Container>
    </Content>
  )
}

export default SectionFaq
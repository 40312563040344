import React,{useEffect,useState} from 'react'
import {Helmet} from 'react-helmet'
import { Container } from '@mui/material'
import CategoryBar from '../../components/shared/CategoryBar'
import LetterCategories from '../../data/letterCategories.json'
import { MainTitle1, MotionGrid } from '../../styles/GlobalStyles'
import { LetterText } from '../../data/letters'
import TemplateCarousel from '../../components/sections/TemplateCarousel'
import Banner from '../../components/banner'
import Header from '../../images/header.png'

function Example() {

const [categories , setCategories] = useState([])
const [letters , setLetters] = useState([])

useEffect(() => {
   
    window.scrollTo({
        top:0
    })
}, [])


useEffect(() => {
  setCategories(LetterCategories)

  return () => {
    setCategories()
  }
}, [])

useEffect(() => {
  setLetters(LetterText)

  return () => {
    setLetters()
  }
}, [])




  return (
    <>
     <Helmet>
            <meta charSet="utf-8" />
            <title>Example | Jobnux.io</title>
        </Helmet>
      <Banner size="70vh"  image={Header} />
     
          <Container>
            <MotionGrid  container >
              <MotionGrid item lg={12} xs={12} >
                    <CategoryBar
                        categories={categories}
                    />
              </MotionGrid> 
              <MotionGrid item lg={12} xs={12} >
                    <MainTitle1>
                      <h1>Our  Templates</h1>
                    </MainTitle1>
              </MotionGrid>
             


             
            </MotionGrid>
             
          </Container>
          <TemplateCarousel
                  data={letters}
                  filter="cover letter"
                  title="Cover Letter examples Templates"
                  linkToCreateTemplate="#"
                  linkToViewAll="#"
                />
              <TemplateCarousel
                  data={letters}
                  filter="letter of resignation"
                  title="Resignation Letter Templates"
                  linkToCreateTemplate="#"
                  linkToViewAll="#"
                />
                <TemplateCarousel
                  data={letters}
                  filter="letter of resignation"
                  title="Resignation Letter Templates"
                  linkToCreateTemplate="#"
                  linkToViewAll="#"
                />
             
      
    </>
  )
}

export default Example
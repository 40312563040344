import { 
    SIGNIN, 
    SIGNIN_SUCCESS, 
    SIGNIN_FAIL, 
    REGISTER, 
    REGISTER_SUCCESS, 
    REGISTER_FAIL, 
    GET_CURRENT_USER,
    GET_CURRENT_USER_SUCCESS,
    GET_CURRENT_USER_FAIL,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    GET_RECRUTORS,
    GET_RECRUTORS_SUCCESS,
    GET_RECRUTORS_FAIL
} from "../constants/appConstants";


function userSigninReducer(state = {}, action) {
    switch (action.type) {
      case SIGNIN:
        return { loading: true };
      case SIGNIN_SUCCESS:
        return { loading: false, userInfo: action.payload };
      case SIGNIN_FAIL:
        return { loading: false, error: action.payload };
      /*case USER_LOGOUT:
        return {userInfo:null};*/
      default: return state;
    }
  }


  function userRegisterReducer(state = {}, action) {
    switch (action.type) {
      case REGISTER:
        return { loading: true };
      case REGISTER_SUCCESS:
        return { loading: false, userInfo: action.payload };
      case REGISTER_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

  function updateUserReducer(state = {}, action) {
    switch (action.type) {
      case UPDATE_USER:
        return { loading: true };
      case UPDATE_USER_SUCCESS:
        return { loading: false, userInfo: action.payload };
      case UPDATE_USER_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

  function  recrutorsReducer(state = {}, action) {
    switch (action.type) {
      case GET_RECRUTORS:
        return { loading: true };
      case GET_RECRUTORS_SUCCESS:
        return { loading: false, recrutorsInfo: action.payload };
      case GET_RECRUTORS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }


  function  currentUserReducer(state = {}, action) {
    switch (action.type) {
      case GET_CURRENT_USER:
        return { loading: true };
      case GET_CURRENT_USER_SUCCESS:
        return { loading: false, userInfo: action.payload };
      case GET_CURRENT_USER_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

  export {
    userSigninReducer, 
    userRegisterReducer,
    currentUserReducer,
    updateUserReducer,
    recrutorsReducer
  }
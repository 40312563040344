// simulation
export const CREATE_SIMULATION = 'CREATE_SIMULATION';
export const CREATE_SIMULATION_SUCCESS = 'CREATE_SIMULATION_SUCCESS';
export const CREATE_SIMULATION_FAIL = 'CREATE_SIMULATION_FAIL';
  
export const UPDATE_SIMULATION = 'UPDATE_SIMULATION';
export const UPDATE_SIMULATION_SUCCESS = 'UPDATE_SIMULATION_SUCCESS';
export const UPDATE_SIMULATION_FAIL = 'UPDATE_SIMULATION_FAIL';

export const DELETE_SIMULATION = 'DELETE_SIMULATION';
export const DELETE_SIMULATION_SUCCESS = 'DELETE_SIMULATION_SUCCESS';
export const DELETE_SIMULATION_FAIL = 'DELETE_SIMULATION_FAIL';

export const LIST_SIMULATION = 'LIST_SIMULATION';
export const LIST_SIMULATION_SUCCESS = 'LIST_SIMULATION_SUCCESS';
export const LIST_SIMULATION_FAIL = 'LIST_SIMULATION_FAIL';

export const GET_SIMULATION_DETAILS = 'GET_SIMULATION_DETAILS';
export const GET_SIMULATION_DETAILS_SUCCESS = 'GET_SIMULATION_DETAILS_SUCCESS';
export const GET_SIMULATION_DETAILS_FAIL = 'GET_SIMULATION_DETAILS_FAIL';

export const UPDATE_SIMULATION_STEP_1 = "UPDATE_SIMULATION_STEP_1"
export const UPDATE_SIMULATION_STEP_2 = "UPDATE_SIMULATION_STEP_2"

export const GET_CURRENT_SIMUALTION = 'GET_CURRENT_SIMUALTION';
export const GET_CURRENT_SIMULATION_SUCCESS = 'GET_CURRENT_SIMULATION_SUCCESS';
export const GET_CURRENT_SIMULATION_FAIL = 'GET_CURRENT_SIMULATION_FAIL';

export const GET_USER_SIMUALTIONS = 'GET_USER_SIMUALTIONS';
export const GET_USER_SIMULATIONS_SUCCESS = 'GET_USER_SIMULATIONS_SUCCESS';
export const GET_USER_SIMULATIONS_FAIL = 'GET_USER_SIMULATIONS_FAIL';


// user
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const LIST_USERS = 'LIST_USERS';
export const LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS';
export const LIST_USERS_FAIL = 'LIST_USERS_FAIL';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAIL = 'GET_USER_DETAILS_FAIL';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAIL = 'GET_CURRENT_USER_FAIL';

export const GET_RECRUTORS = 'GET_RECRUTORS';
export const GET_RECRUTORS_SUCCESS = 'GET_RECRUTORS_SUCCESS';
export const GET_RECRUTORS_FAIL = 'GET_RECRUTORS_FAIL';



export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const SIGNIN = 'SIGNIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';

export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

// coverletter
export const CREATE_COVER_LETTER = 'CREATE_COVER_LETTER';
export const CREATE_COVER_LETTER_SUCCESS = 'CREATE_COVER_LETTER_SUCCESS';
export const CREATE_COVER_LETTER_FAIL = 'CREATE_COVER_LETTER_FAIL';

export const UPDATE_COVER_LETTER = 'UPDATE_COVER_LETTER';
export const UPDATE_COVER_LETTER_SUCCESS = 'UPDATE_COVER_LETTER_SUCCESS';
export const UPDATE_COVER_LETTER_FAIL = 'UPDATE_COVER_LETTER_FAIL';

export const DELETE_COVER_LETTER = 'DELETE_COVER_LETTER';
export const DELETE_COVER_LETTER_SUCCESS = 'DELETE_COVER_LETTER_SUCCESS';
export const DELETE_COVER_LETTER_FAIL = 'DELETE_COVER_LETTER_FAIL';

export const LIST_COVER_LETTERS = 'LIST_COVER_LETTERS';
export const LIST_COVER_LETTERS_SUCCESS = 'LIST_COVER_LETTERS_SUCCESS';
export const LIST_COVER_LETTERS_FAIL = 'LIST_COVER_LETTERS_FAIL';

export const LIST_USER_COVER_LETTERS = 'LIST_USER_COVER_LETTERS';
export const LIST_USER_COVER_LETTERS_SUCCESS = 'LIST_USER_COVER_LETTERS_SUCCESS';
export const LIST_USER_COVER_LETTERS_FAIL = 'LIST_USER_COVER_LETTERS_FAIL';
 
export const GET_COVER_LETTER_DETAILS = 'GET_COVER_LETTER_DETAILS';
export const GET_COVER_LETTER_DETAILS_SUCCESS = 'GET_COVER_LETTER_DETAILS_SUCCESS';
export const GET_COVER_LETTER_DETAILS_FAIL = 'GET_COVER_LETTER_DETAILS_FAIL';
 

// resignation letters
export const CREATE_RESIGNATION_LETTER = 'CREATE_RESIGNATION_LETTER';
export const CREATE_RESIGNATION_LETTER_SUCCESS = 'CREATE_RESIGNATION_LETTER_SUCCESS';
export const CREATE_RESIGNATION_LETTER_FAIL = 'CREATE_RESIGNATION_LETTER_FAIL';


export const UPDATE_RESIGNATION_LETTER = 'UPDATE_RESIGNATION_LETTER';
export const UPDATE_RESIGNATION_LETTER_SUCCESS = 'UPDATE_RESIGNATION_LETTER_SUCCESS';
export const UPDATE_RESIGNATION_LETTER_FAIL = 'UPDATE_RESIGNATION_LETTER_FAIL';

export const DELETE_RESIGNATION_LETTER = 'DELETE_RESIGNATION_LETTER';
export const DELETE_RESIGNATION_LETTER_SUCCESS = 'DELETE_RESIGNATION_LETTER_SUCCESS';
export const DELETE_RESIGNATION_LETTER_FAIL = 'DELETE_RESIGNATION_LETTER_FAIL';

export const LIST_RESIGNATION_LETTERS = 'LIST_RESIGNATION_LETTERS';
export const LIST_RESIGNATION_LETTERS_SUCCESS = 'LIST_RESIGNATION_LETTERS_SUCCESS';
export const LIST_RESIGNATION_LETTERS_FAIL = 'LIST_RESIGNATION_LETTERS_FAIL';
 
export const LIST_USER_RESIGNATION_LETTERS = 'LIST_USER_RESIGNATION_LETTERS';
export const LIST_USER_RESIGNATION_LETTERS_SUCCESS = 'LIST_USER_RESIGNATION_LETTERS_SUCCESS';
export const LIST_USER_RESIGNATION_LETTERS_FAIL = 'LIST_USER_RESIGNATION_LETTERS_FAIL';

export const VIEW_RESIGNATION_LETTER_DETAILS = 'VIEW_RESIGNATION_LETTER_DETAILS';
export const VIEW_RESIGNATION_LETTER_DETAILS_SUCCESS = 'VIEW_RESIGNATION_LETTER_DETAILS_SUCCESS';
export const VIEW_RESIGNATION_LETTER_DETAILS_FAIL = 'VIEW_RESIGNATION_LETTER_DETAILS_FAIL';


// job description
export const CREATE_JOB_DESCRIPTION = 'CREATE_JOB_DESCRIPTION';
export const CREATE_JOB_DESCRIPTION_SUCCESS = 'CREATE_JOB_DESCRIPTION_SUCCESS';
export const CREATE_JOB_DESCRIPTION_FAIL = 'CREATE_JOB_DESCRIPTION_FAIL';

export const UPDATE_JOB_DESCRIPTION = 'UPDATE_JOB_DESCRIPTION';
export const UPDATE_JOB_DESCRIPTION_SUCCESS = 'UPDATE_JOB_DESCRIPTION_SUCCESS';
export const UPDATE_JOB_DESCRIPTION_FAIL = 'UPDATE_JOB_DESCRIPTION_FAIL';

export const DELETE_JOB_DESCRIPTION = 'DELETE_JOB_DESCRIPTION';
export const DELETE_JOB_DESCRIPTION_SUCCESS = 'DELETE_JOB_DESCRIPTION_SUCCESS';
export const DELETE_JOB_DESCRIPTION_FAIL = 'DELETE_JOB_DESCRIPTION_FAIL';

export const LIST_JOB_DESCRIPTIONS = 'LIST_JOB_DESCRIPTIONS';
export const LIST_JOB_DESCRIPTIONS_SUCCESS = 'LIST_JOB_DESCRIPTIONS_SUCCESS';
export const LIST_JOB_DESCRIPTIONS_FAIL = 'LIST_JOB_DESCRIPTIONS_FAIL';

export const LIST_USER_JOB_DESCRIPTIONS = 'LIST_USER_JOB_DESCRIPTIONS';
export const LIST_USER_JOB_DESCRIPTIONS_SUCCESS = 'LIST_USER_JOB_DESCRIPTIONS_SUCCESS';
export const LIST_USER_JOB_DESCRIPTIONS_FAIL = 'LIST_USER_JOB_DESCRIPTIONS_FAIL';

export const VIEW_JOB_DESCRIPTION_DETAILS = 'VIEW_JOB_DESCRIPTION_DETAILS';
export const VIEW_JOB_DESCRIPTION_DETAILS_SUCCESS = 'VIEW_JOB_DESCRIPTION_DETAILS_SUCCESS';
export const VIEW_JOB_DESCRIPTION_DETAILS_FAIL = 'VIEW_JOB_DESCRIPTION_DETAILS_FAIL';


// business creator
export const CREATE_BUSINESS = 'CREATE_BUSINESS';
export const CREATE_BUSINESS_SUCCESS = 'CREATE_BUSINESS_SUCCESS';
export const CREATE_BUSINESS_FAIL = 'CREATE_BUSINESS_FAIL';

export const UPDATE_BUSINESS_DETAILS = 'UPDATE_BUSINESS_DETAILS';
export const UPDATE_BUSINESS_DETAILS_SUCCESS = 'UPDATE_BUSINESS_DETAILS_SUCCESS';
export const UPDATE_BUSINESS_DETAILS_FAIL = 'UPDATE_BUSINESS_DETAILS_FAIL';

export const DELETE_BUSINESS = 'DELETE_BUSINESS';
export const DELETE_BUSINESS_SUCCESS = 'DELETE_BUSINESS_SUCCESS';
export const DELETE_BUSINESS_FAIL = 'DELETE_BUSINESS_FAIL';

export const LIST_BUSINESSES = 'LIST_BUSINESSES';
export const LIST_BUSINESSES_SUCCESS = 'LIST_BUSINESSES_SUCCESS';
export const LIST_BUSINESSES_FAIL = 'LIST_BUSINESSES_FAIL';

export const LIST_USER_BUSINESSES = 'LIST_USER_BUSINESSES';
export const LIST_USER_BUSINESSES_SUCCESS = 'LIST_USER_BUSINESSES_SUCCESS';
export const LIST_USER_BUSINESSES_FAIL = 'LIST_USER_BUSINESSES_FAIL';

export const VIEW_BUSINESS_DETAILS = 'VIEW_BUSINESS_DETAILS';
export const VIEW_BUSINESS_DETAILS_SUCCESS = 'VIEW_BUSINESS_DETAILS_SUCCESS';
export const VIEW_BUSINESS_DETAILS_FAIL = 'VIEW_BUSINESS_DETAILS_FAIL';

// Chat
export const SEND_CHAT_MESSAGE = 'SEND_CHAT_MESSAGE';
export const SEND_CHAT_MESSAGE_SUCCESS = 'SEND_CHAT_MESSAGE_SUCCESS';
export const SEND_CHAT_MESSAGE_FAIL = 'SEND_CHAT_MESSAGE_FAIL';
 
export const LIST_SIMULATION_CHAT_MESSAGE = 'LIST_SIMULATION_CHAT_MESSAGE';
export const LIST_SIMULATION_CHAT_SUCCESS = 'LIST_SIMULATION_CHAT_SUCCESS';
export const LIST_SIMULATION_CHAT_FAIL = 'LIST_SIMULATION_CHAT_FAIL';


export const RECEIVE_CHAT_MESSAGE = 'RECEIVE_CHAT_MESSAGE';
export const RECEIVE_CHAT_MESSAGE_SUCCESS = 'RECEIVE_CHAT_MESSAGE_SUCCESS';
export const RECEIVE_CHAT_MESSAGE_FAIL = 'RECEIVE_CHAT_MESSAGE_FAIL';
export const RESET_SIMULATION_MESSAGES = 'RESET_SIMULATION_MESSAGES';

export const GET_CHAT_HISTORY = 'GET_CHAT_HISTORY';
export const GET_CHAT_HISTORY_SUCCESS = 'GET_CHAT_HISTORY_SUCCESS';
export const GET_CHAT_HISTORY_FAIL = 'GET_CHAT_HISTORY_FAIL';




import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MDEditor from '@uiw/react-md-editor';
import { Box, Chip, Stack, Typography } from '@mui/material';




export default function TemplateCard({content,job,categorie}) {

 

  return (
    <Box
      sx={{
        marginLeft:2
      }}
    >
          <Card sx={{ 
                width: 275,
                marginRight:2 ,
                filter: "drop-shadow(0px 4px 18px rgba(0, 0, 0, 0.09))",
               
            
                }}>
                <CardContent>
                   <MDEditor.Markdown 
                    style={{
                        fontSize:6,
                        background:"#fff",
                        color:"#000"
                    }}  
                    source={content} 
                />
                 </CardContent> 
            </Card>
            <Stack 
                direction="column" 
                gap={2}
                mt={2}
            >
                <Typography
                    sx={{
                        
                        textTransform:'capitalize'
                    }}
                >  
                    {job} 
                </Typography>
                <Chip 
                    label={categorie} 
                    variant='outlined' 
                    sx={{
                        maxWidth:150,
                        textTransform:'capitalize',
                        color:'#136EF5'
                    }}
                    
                />
            </Stack>
       </Box>
   
  );
}
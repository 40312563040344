import styled from 'styled-components';
import { motion } from 'framer-motion';




export const Content = styled(motion.div)`
  display:flex;
  align-items:center;
  min-height:${props=>props.size};
  background:${props=>props.bgcolor}
`;

export const H1 = styled(motion.h1)`
   font-size:38px;
   @media screen and (max-width: 1200px){
     text-align:center;
   }
   @media screen and (max-width: 760px){
    text-align:center;
    font-size:28px;
  }
 
`



export const H2 = styled(motion.h2)`

  @media screen and (max-width: 1200px){
    text-align:center
  }
`

export const P = styled(motion.p)`
@media screen and (max-width: 760px){
  text-align:justify;
}
`

export const UL = styled(motion.ul)`
  
  color:#fff;
  line-height: 48px;
  font-size: 27px;

`

export const LI = styled(motion.li)`
  margin-bottom:30px;

`


export const Span = styled(motion.span)`
    font-size:18px;
    font-weight: 400;
`


export const ButtonLink = styled(motion.Link)`
  font-size: 18px;
  font-weight:400;
  color:${props=>props.color}; 
  display: flex;
  align-items: center;
  justify-content: center;
  padding:10px;
  max-width:200px;
  height:30px;
  text-decoration: none;
  border:2px solid ${props=>props.color}; 
  cursor: pointer;
  border-radius:50px;
  background-color:${props=>props.bgcolor};
  line-height: 200px;
`;

export const Img = styled(motion.img)`
    max-width:550px;
    height:auto;

    @media screen and (max-width: 760px){
      width:350px;   
      height:auto;
    }
`;

export const Minimage = styled(motion.img)`
    max-width:486px;
    height:auto;
    border-radius:15px;

    @media screen and (max-width: 760px){
      width:350px;
      height:auto;
    }
`;




import React, { useState, useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import MDEditor from '@uiw/react-md-editor';
import TermsAndPrivacy from '../../data/terms.md';
import { Content, MainTitle2 } from '../../styles/GlobalStyles';

function Terms() {
  const [value, setValue] = useState('');

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    async function fetchTermsAndPrivacy() {
      try {
        const response = await fetch(TermsAndPrivacy);
        const text = await response.text();
        setValue(text);
      } catch (error) {
        console.error('Failed to fetch terms and privacy:', error);
      }
    }

    fetchTermsAndPrivacy();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terms & Privacy | Jobnux</title>
      </Helmet>
      <Content
        size="100vh"
        bgcolor="#fff"
        viewport={{ once: false }}
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ type: 'easeIn', duration: 1, delay: 0 }}
      >
        <Container>
          <Grid mb={3} mt={5} container spacing={2}>
            {['Term of Use', 'Privacy Policy'].map((title, index) => (
              <Grid container item lg={6} md={6} sm={6} xs={12} key={index}>
                <Grid item lg={12} xs={12}>
                  <MainTitle2>
                    <h2>{title}</h2>
                  </MainTitle2>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <MDEditor.Markdown
                    style={{ fontSize: 12, backgroundColor: '#fff', color: '#000' }}
                    source={value}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Content>
    </>
  );
}

export default Terms;

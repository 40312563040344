
export const  faqData= [

    {
        id:1,
        question:"What can I create with Jobnux.io ?",
        response:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"+
         "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris"+ 
         "nisi ut aliquip ex ea commodo consequat. "
    },
    {
        id:2,
        question:"How much does it cost ?",
        response:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"+
         "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris"+ 
         "nisi ut aliquip ex ea commodo consequat. "
    },
    {
        id:3,
        question:"What languages does it support?",
        response:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"+
         "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris"+ 
         "nisi ut aliquip ex ea commodo consequat. "
    },
    {
        id:4,
        question:"Can I get a demo of the product?",
        response:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"+
         "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris"+ 
         "nisi ut aliquip ex ea commodo consequat. "
    },
    {
        id:5,
        question:"Where can I learn more ?",
        response:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"+
         "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris"+ 
         "nisi ut aliquip ex ea commodo consequat. "
    }

]
import { Container, Grid, Typography} from '@mui/material'
import { motion } from 'framer-motion';
import styled from 'styled-components';
import SimpleFeatures from '../../components/cards/SimpleFeatures';
import { H1,Content } from '../../styles/GlobalStyles';

const Move = styled(motion(Grid))`
`
function FeaturesKey({subtitle,title,data}) {

    
  return (
    <Content size="100vh" bgcolor="#fff" >
         <Container   >
                <Grid mt={10} mb={10} container spacing={2} >
                     <Move 
                      item 
                      lg={12}
                      xs={12} 
                      pr={2} 
                      viewport={{once:true}}
                      initial={{opacity:0,y:100}}
                      whileInView={{opacity:1,y:0}}
                      transition={{type:"easeIn", duration:1,delay:0 }}

                     >
                            <Typography  color="#1371FF" textAlign="center"> {subtitle} </Typography>
                            <H1 style={{fontSize:20,textAlign:'center'}}>
                               {title}
                            </H1>
                           
                     </Move>
                     <Move 
                        container 
                        spacing={2} 
                        item 
                        lg={12}
                        alignItems="center" 
                        viewport={{once:true}}
                        initial={{opacity:0,y:100}}
                        whileInView={{opacity:1,y:0}}
                        transition={{type:"easeIn", duration:1,delay:0 }}
                     >
                    
                            {data.map((item)=>(

                                <Grid 
                                item 
                                lg={4} 
                                sm={6} md={6} 
                                xs={12}  
                                key={item.id} 
                                >
                                    <SimpleFeatures item={item} />
                                </Grid>

                            ))}
                       
                     </Move> 
                </Grid>
         </Container>
    </Content>
  )
}

export default FeaturesKey

import React from 'react'
import { Box, Container, Grid } from '@mui/material'
import * as BsIcon from 'react-icons/bs'
import styled from 'styled-components'
import User from '../../images/image19.png'
import Deloitte from '../../images/Deloitte.png'
import { useTranslation } from 'react-i18next'



const Span = styled.span`
  padding:5px;
  color:#FFD233;
`;

const P = styled.p`
text-align:center;
font-weight:bold;
font-size:28px;

@media screen and (max-width: 500px){
    text-align:justify;
    font-size:20px;
  }
`

const Card = styled.div`
display:flex;
justify-content:center;
align-items:end;
height:120px;
width:100%;

@media screen and (max-width: 500px){
     flex-direction:column;
     height:320px;
     align-items:center;
  }
`

const Titles = styled.div`
align-items:end;
height:120px;
@media screen and (max-width: 500px){
    
  }
`


const H3 = styled.h3`
font-size:24px;
font-weight:600;
text-align:center;

`


const H6 = styled.h6`
font-size:21px;
font-weight:400;
text-align:center;

@media screen and (max-width: 500px){
  margin-top:-10px
  }

`

const Logo = styled.div`
display:flex;
justify-content:center;
border-left: 1px solid #000;
margin-left:30px;
padding-left:30px;
@media screen and (max-width: 500px){
    margin-left:0;
    padding-left:10px;
  }
`

const Image = styled.img`
width: 86px;
height: 86px;
margin-right:20px;
@media screen and (max-width: 700px){
    width:56px;
    height:56px;
  }
`
const ImgLogo = styled.img`
width:230px;
height:50px;
align-items:end;
@media screen and (max-width: 700px){
    width:130px;
    height:40px;
  }
`


function SingleTestemonial() {

  const { t } = useTranslation('sections');
  return (
   
       <Container 
          style={{
            marginBottom:15
          }}
       >
          <Grid container  mt={4} >
                <Box
                  display="flex"
                  justifyContent="center"
                  textAlign="center"
                  alignContent="center"
                  width="100%"
                >
                    <Span>
                        <BsIcon.BsStarFill  />
                    </Span>
                    <Span>
                        <BsIcon.BsStarFill />
                    </Span>
                    <Span>
                        <BsIcon.BsStarFill  />
                    </Span>
                    <Span>
                        <BsIcon.BsStarFill  />
                     </Span>
                     <Span>
                        <BsIcon.BsStarFill  />
                    </Span>
                </Box>
                <Grid item lg={12} xs={12}>
                    <P>
                         {t("singleTestemonial.content")}
                    </P>
                    <Card>
                        <Image  src={User} />
                        <Titles>
                            <H3  > {t("singleTestemonial.name")}</H3>
                            <H6 style={{fontSize:"21px",fontWeight:"400"}}> {t("singleTestemonial.position")}</H6>
                        </Titles>
                        <Logo>
                            <ImgLogo src={Deloitte} alt="Jobnux - testemonials" />
                        </Logo>
                    </Card>
                </Grid>
            </Grid>
      
       </Container>
           
   
  )
}

export default SingleTestemonial

// awardReducer.js

import { ADD_AWARD, DELETE_AWARD, UPDATE_AWARD } from "../constants/resumeConstants";



const initialState = {
  awards: [],
};

const awardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_AWARD:
      // Construire un objet map avec uuid comme clé pour les items awards existants
      const existingAwardsMap = state.awards.reduce(
        (acc, award) => ({ ...acc, [award.uuid]: award }),
        {}
      );

      // Parcourir les nouveaux items et mettre à jour ou ajouter au state
      const updatedAwards = action.payload.map((newAward) => {
        const { uuid } = newAward;
        if (existingAwardsMap.hasOwnProperty(uuid)) {
          // Mettre à jour l'item existant avec l'uuid correspondant
          return { ...existingAwardsMap[uuid], ...newAward };
        } else {
          // Ajouter le nouvel item s'il n'existe pas déjà
          return newAward;
        }
      });

      return {
        ...state,
        awards: updatedAwards
      };
   
    case UPDATE_AWARD:
      return {
        ...state,
        awards: state.awards.map((award) =>
          award.id === action.payload.awardId ? action.payload.updatedAward : award
        ),
      };
    case DELETE_AWARD:
      return {
        ...state,
        awards: action.payload.filter((_, i) => i !== action.payload),
      };
    default:
      return state;
  }
};

export default awardReducer;
  
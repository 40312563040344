import React from 'react';
import { Chip, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import * as FaIcons from 'react-icons/fa'
import { 
    FooterContainer,
    FooterLink,
    H4,
    SocialContainer,
    SocialLink ,
    Column,
    Nav,
    Copyright,
    LogoLink,
    Logo,
} from './Element'
import Newsletter from '../forms/newsletter';
import LogoImg from '../../images/Logo-comp-white.png'
import ScrollToTopButton from '../buttons/ScrollToTopButton';
import { colors } from '../../styles';
import ActionBtnLink from '../buttons/ActionBtnLink';



function Footer() {
    const url = window.location.href;
  return (
    <FooterContainer>
        <Container  maxWidth={false} >
           <Grid container>
               
                <Grid item lg={2} sm={6} md={4} xs={6} >
                           <LogoLink to='/' >
                            {/*<Logo  src={LogoImg}  alt="C Jobnux AI - Logo "/>*/}
                            <Typography sx={{
                              fontWeight:"bold",
                              fontSize:"40px"
                            }} color={`${colors.white}`}>
                              Jobnux.io
                            </Typography>
                     </LogoLink>

                     <Column>
                         <p 
                           style={{
                             marginLeft:20
                           }}
                         >
                           Join over 245,000 job seekers helped since September 2023
                        </p>
                        <ActionBtnLink link="/newsletter"  title="Join our news letter" />
                            
                        
                        {/*<ButtonLink to="/register">
                            Create Free Account
                          </ButtonLink>*/}
                     </Column>
                </Grid>

                <Grid item lg={2} sm={6} md={4} xs={6} >
                    <H4>Features</H4>
                     <Column>
                        <FooterLink to="/job-interview-coach">Job Interview Simulator</FooterLink>
                        <FooterLink to="/cover-letter-generator">Cover Letter Generator</FooterLink>
                        <FooterLink to="/job-interview-coach">Job interview Coach </FooterLink>
                        <FooterLink to="/resignation-letter">Letter of resignation </FooterLink>
                        <FooterLink to="/resume"> 
                         <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          sx={{
                            height:5
                          }}
                         >
                             <Typography mr={1} variant='span' component="span" > Resume writer </Typography>
                             <Chip label="New" 
                              sx={{
                                backgroundColor:`${colors.green}`,
                                color:`${colors.white}`
                              }} 
                              />
                         </Stack>
                        
                        </FooterLink>
                        {/*<FooterLink to="/job-description">Job Description</FooterLink>*/}
                     </Column>
                </Grid>
                <Grid item lg={2} sm={6} md={4} xs={6}>
                    <H4>Entrepreneurship</H4>
                    <Column>
                        <FooterLink to="#">Networking AI</FooterLink>
                        <FooterLink to="/business-creator">Business Creator</FooterLink>
                     </Column>
                </Grid>
                <Grid item lg={2} sm={6} md={4} xs={6}>
                    <H4>For Companies</H4>
                    <Column>
                      {/*  <FooterLink to="#">Job Description writer</FooterLink>*/}
                        <FooterLink to="/resume"> 
                         <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          sx={{
                            height:5
                          }}
                         >
                             <Typography mr={1} variant='span' component="span" > 
                             Resume summary 
                             </Typography>
                             <Chip label="New" 
                              sx={{
                                backgroundColor:`${colors.green}`,
                                color:`${colors.white}`
                              }} 
                              />
                         </Stack>
                        
                        </FooterLink>
                        <FooterLink to="#">Candidate sourcing platform</FooterLink>
                        <FooterLink to="#">Applicant Tracking System (ATS)</FooterLink>
                     </Column>
                </Grid>
                <Grid item lg={2} sm={6} md={4} xs={6}>
                  <H4>Let's keep in touch</H4>
                   <Column>
                        <p>
                           Receive our great newsletters (at the right dose) to follow our progress and news.
                        </p>
                        <Newsletter/>
                  </Column>
                </Grid>
                <Grid item lg={1} sm={6} md={4} xs={6}>
                    <H4>Support</H4>
                    <Column>
                        <FooterLink to="/contact-us">Contact Us</FooterLink>
                        <FooterLink to="/resume"> 
                         <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          sx={{
                            height:5
                          }}
                         >
                             <Typography mr={1} variant='span' component="span" > 
                             Linkedin Extension
                             </Typography>
                             <Chip label="New" 
                              sx={{
                                backgroundColor:`${colors.green}`,
                                color:`${colors.white}`
                              }} 
                              />
                         </Stack>
                        
                        </FooterLink>
                        <FooterLink to="#">Blog</FooterLink>
                        <FooterLink to="/faq">FAQs</FooterLink>
                        <FooterLink to="#">Help</FooterLink>
                    </Column>
                </Grid>
               
                <Grid item lg={1} sm={12} md={12} xs={12}>
                 <SocialContainer>
                        <SocialLink to="#">
                           <FaIcons.FaLinkedinIn/>
                        </SocialLink>
                        <SocialLink to="#">
                          <FaIcons.FaFacebookF/>
                        </SocialLink>
                        <SocialLink to="#">
                          <FaIcons.FaTwitter/>
                        </SocialLink>
                        <SocialLink to="#" >
                           <FaIcons.FaInstagram/>
                        </SocialLink>
                        <SocialLink to="#" >
                           <FaIcons.FaYoutube/>
                        </SocialLink>
                        <SocialLink to="#" >
                           <FaIcons.FaTiktok/>
                        </SocialLink>
                    </SocialContainer>
                 
                </Grid>
                <Grid paddingTop={4} item xs={12}>
                    <Divider sx={{backgroundColor:'#282232',height:3}} />
                </Grid>
                <Grid item lg={6} xs={6}>
                     <Copyright>
                        © 2023 JobGenius.ai. All rights reserved. 
                    </Copyright>
                </Grid>
                <Grid style={{position:'relative',display:'flex',justifyContent:'flex-end'}} item lg={6} xs={6}>
                    <Nav>
                        <FooterLink to="about-us" >About Us</FooterLink>
                        <FooterLink to="#">Pricing</FooterLink>
                        <FooterLink to="term-and-privacy">Terme of use</FooterLink>
                    </Nav>
                </Grid>
           </Grid>
       </Container>
       <ScrollToTopButton/>
    </FooterContainer>
    
  )
}

export default Footer

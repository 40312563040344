
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AddCircle } from '@mui/icons-material';
import MDEditor from '@uiw/react-md-editor';

export default function Accordeon({ data }) {

  if (!Array.isArray(data)) {
    return null; // Si data n'est pas un tableau, retournez null ou un message d'erreur approprié
  }

  return (
    <div style={{ width: '100%' }}>
      {data.map((item) => (
        <Accordion key={item.id} sx={{ border: "none", boxShadow: "none", borderBottom: "none" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${item.id}a-content`}
            id={`panel${item.id}a-content`}
          >
            {item.question && (
              <span style={{ marginRight: 5 }}>
                <AddCircle sx={{ color: "#1371FF" }} />
              </span>
            )}
            <Typography fontSize={18} color="#4D4D4D">
              {item.question ? item.question : <b>{item.title}</b>}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {item.response ? (
              <Typography>{item.response}</Typography>
            ) : (
              <MDEditor.Markdown style={{ fontSize: 15, backgroundColor: "white", color: '#000' }} source={item.content} />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
import Image1 from '../images/Frame-3506.png'
import Image2 from '../images/Frame-3505.png'
import Image3 from '../images/Frame-3507.png'
import Image4 from '../images/Frame-3498.png'


export const performanceData = [{
    "id": "6404d481d54ad6a7a9c8fced",
    "title": "Interactive Interview Experience",
    "description":"Practice realistic timed interviews with our interactive simulator, utilizing audio for a more immersive and authentic experience.",
    "image": Image1,
    "feature":[
      {
        id:1,
        description:'Gain immersive and realistic interview practice'
      },
      {
        id:2,
        description:'Boost your confidence and performance'
      },
      {
        id:3,
        description:'Enhance your time management skills'
      },
    ]
  },{
    "id":  "6404d4c2d15091b4865e7ee9",
    "title": "Personalized Feedback and Tips",
    "description":"Receive tailored feedback and valuable insights to improve your interview skills and make a lasting impression on employers.",
    "image": Image2,
    "feature":[
      {
        id:1,
        description:'Receive detailed insights into your strengths and areas for improvement.'
      },
      {
        id:2,
        description:'Identify specific areas to focus on for interview preparation'
      },
     
    ]
  },{
    "id": "6404d529d23e4b02da0be8b7",
    "title": "Targeted Interviews ",
    "description":"Prepare for specific job opportunities by practicing interview questions tailored to the requirements of the position.",
    "image": Image3,
    "feature":[
      {
        id:1,
        description:'Practice interview questions specific to your desired job openings.'
      },
      {
        id:2,
        description:'Prepare effectively and align your skills with the job requirements.'
      },
     
    ]
  },{
    "id":"6404d54dddb2229w4f6f4a53",
    "title": "Versatile Interview Training",
    "description":"Practice various interview formats, including behavioral, technical, and situational, to be fully prepared for any interview scenario.",
    "image": Image4,
    "feature":[
      {
        id:1,
        description:'Familiarize yourself with various interview formats (behavioral, technical, etc.).'
      },
      {
        id:2,
        description:'Adapt to different employer requirements and confidently navigate any interview scenario.'
      },
     
    ]
  }
]
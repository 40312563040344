import React from 'react'
import { styled } from '@mui/material/styles';
import { Box, Button, Typography} from '@mui/material';
import Slider from 'react-slick';
import CurriculumCard from '../cards/CurriculumCard';
import {  ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';



const CarouselContainer = styled(Box)({
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
  });

  const CustomPrevArrow = styled(Button)({
    position: 'absolute',
    top: '50%',
    left: 0,
    height:40,
    width:40,
    borderRadius:100,
    zIndex:1,
    transform: 'translateY(-50%)',
    cursor:'pointer',
   
  });
  
  const CustomNextArrow = styled(Button)({
    position: 'absolute',
    top: '50%',
    right: 10,
    height:40,
    width:40,
    borderRadius:100,
    transform: 'translateY(-50%)',
    zIndex:1,
    cursor:'pointer',
  
  });
  

  const SlickArrowLeft = ({currentSlide,slideCount,...props}) => (
    <CustomPrevArrow
      {...props}
      className={
        "slick-prev slick-arrow" + 
        (currentSlide === 0 ? "slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <ArrowBackIos/>
    </CustomPrevArrow>
  );
  
  
  const SlickArrowRight = ({currentSlide,slideCount,...props}) => (
    <CustomNextArrow
      {...props}
      className={
        "slick-next slick-arrow" + 
        (currentSlide === slideCount - 1 ? "slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <ArrowForwardIos/>
    </CustomNextArrow>
  )
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:3,
    slidesToScroll:1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:2,
          slidesToScroll:1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {  
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
  
    prevArrow:<SlickArrowLeft/>,
    nextArrow:<SlickArrowRight/>
    
  };
  

function ModerneTemplate({data}) {
  return (
    <Box
      sx={{
        height:{lg:550,md:500},
        alignItems:'center',
        justifyContent:'center',
        display:'flex',
        flexDirection:'column',
        backgroundColor:'#10091B',
        padding:5,
        marginTop:5,
    
    }}
  >
     <Box
       sx={{
         width:'100%',
         marginBottom:3
       }}
     >
        <Typography
            variant='h5'
            component='h5'
            color='#fff'
            textAlign='left'
            width='100%'
            fontWeight='bold'

        >
          Modern Template
        </Typography>
     </Box>
       
      <CarouselContainer>
          <Slider 
            {...settings}  
            prevArrow={<SlickArrowLeft/>}
            nextArrow={<SlickArrowRight/>}           
          >
            {data.map((temp)=> (
              
              <Box key={temp.id} >
                  <CurriculumCard
                        image={temp.image}
                  />
              </Box>
              
            ))}
          </Slider>
        
        </CarouselContainer>
  </Box>
  )
}

export default ModerneTemplate
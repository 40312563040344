import styled  from 'styled-components';


export const Content = styled.div`
  display:flex;
  align-items:center;
  heigh:100vh;
  margin-bottom:20px
`;

export const Box = styled.div`
  display:flex;
  align-items:center;
  flex-direction:column;
  gap:20px;
  padding:40px;
`;

export const H1 = styled.h1`
  font-size:32px
`;

export const P = styled.p`
    text-align:center;
`;

export const Span = styled.span`
   font-weight:600;
   color:${props=>props.color};
   font-size:${props=>props.size}px;
   padding:${props=>props.padding}px; 
`;

export const FriendlyBox = styled.div`
  display:flex;
  justify-content:space-between;

  @media screen and (max-width:575){
    display:none;
    flex-direction:column;
  }
`;

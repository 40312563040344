import Image1 from '../images/imag.png'
import Image2 from '../images/image29.png'
import Image3 from '../images/imag.png'
import Image4 from '../images/imag.png'


export const searchJobData = [{
    "id": "6404d481d54ad6a7a9c8fced",
    "title": "PDF, DOCX, Drive Exporting",
    "description":"JobGenius enables users to download their documents in three formats: Microsoft Word DOCX, Google Doc, or PDF.",
    "image": Image1
  },{
    "id":  "6404d4c2d15091b4865e7ee9",
    "title": "Document Version Management",
    "description":"Create customized versions of your documents to suit each job, and arrange them systematically as required.",
    "image": Image2
  },{
    "id": "6404d529d23e4b02da0be8b7",
    "title": "Linkedin Profile Importing",
    "description":"JobGenius enables users to download their documents in three formats: Microsoft Word DOCX, Google Doc, or PDF.",
    "image": Image3
  },{
    "id":"6404d54dddb2229w4f6f4a53",
    "title": "PDF, DOCX, Drive Exporting",
    "description":"Create customized versions of your documents to suit each job, and arrange them systematically as required.",
    "image": Image4
  }
]
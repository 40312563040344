import React, { useState } from 'react'
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';




const theme = createTheme();

export function useForm(initialValues, validateOnChange = false, validate) {


    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const [file, setFile] = useState(null);
    const [errorFile, setErrorFile] = useState('');
    const types = ['image/png', 'image/jpeg']; // image types


    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        if (validateOnChange) 
            validate({ [name]: value }) 
    }

  
      

    const handleChecked = (e) => {
        const { name, checked } = e.target
        setValues({
          ...values,
          [name]:checked,
        });
        if (validateOnChange) 
        validate({ [name]: checked }) 
      };
    
      const handleInputFileChange = (e) => {
        let selectedFile = e.target.files[0];
        if (selectedFile && types.includes(selectedFile.type)) {
            setFile(selectedFile);
            setErrorFile('')
        }
        else { 
            setFile(null);
            alert('Sélectionner une image valide de type (jpg, jpeg ou png)');
        }
    }

    const resetForm = () => {
        setValues(initialValues);
        setErrors({})
    }


    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleChecked,
        resetForm,
        handleInputFileChange,
        file,
        errorFile

    }
}




export function Form(props) {

   
    const {textAlign, children, ...other } = props;
    return (

        <ThemeProvider theme={theme}>
             <Container sx={{height:'100vh'}} component="main" maxWidth= {props.maxWidth ? props.maxWidth : 'xs'} >
              
                <Box
                    sx={{
                        marginTop:1,
                        display: 'flex',
                        flexDirection: 'column',
                       
                    }}
                    > 
                    {
                        props.title ?
                        <Typography style={{fontWeight:700,fontSize:"30px"}}  color="#1371FF" textAlign="left" component="h1" variant="h5">
                            {props.title}
                        </Typography>
                        : ""
                    }
                    {
                       props.description ?
                       <Typography   textAlign={textAlign?textAlign:'left'} component="p" variant="body1" >
                           {props.description}
                       </Typography>
                       : "" 
                    }

                       
                        <Box component="form" noValidate autoComplete="off" {...other} sx={{ mt: 3 }}>
                            {props.children}
                        </Box>
                    </Box>
             </Container>
        </ThemeProvider>
       
    )
}
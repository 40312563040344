import React from 'react'
import { Content} from './Elements'
import { Box,  Container, Grid, Typography } from '@mui/material'
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Image30 from '../../images/Frame-3504.png'
import { Link } from 'react-router-dom';


const Move = styled(motion(Grid))`
`


const Image = styled.img`
  width:100%;
  height:auto
`

export const BtnCreateLink = styled(motion(Link))`
  font-size: 18px;
  font-weight:400;
  color:#fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:10px;
  max-width:300px;
  height:30px;
  text-decoration: none;
  border:2px solid #1371FF; 
  cursor: pointer;
  border-radius:10px;
  background-color:#1371FF;
  line-height: 200px;
  @media screen and (max-width: 760px){
    font-size: 12px;
  }
`;

function Steps({step,title,description,description1,link,btnTitle}) {
  return (
    <Content 
        size="80vh" 
        bgcolor="#fff"
        style={{
            marginTop:"25px",
            marginBottom:"25px"
        }}
     >
        <Container>
            <Grid container spacing={2}>
                    <Move 
                        item 
                        lg={5} 
                        xs={12}
                        viewport={{once:true}}
                        initial={{opacity:0,y:100}}
                        whileInView={{opacity:1,y:0}}
                        transition={{type:"easeIn", duration:1,delay:0 }}

                    >
                        <Box>
                            <Typography sx={{color:"white"}} > 
                                <span style={{color:"#1371FF",fontWeight:'bold',fontSize:18}} > {step} </span>  
                            </Typography>
                            <Typography sx={{color:"#000"}} variant='h4' > 
                               {title}
                            </Typography>
                            <Typography sx={{color:"#B6b6b6"}} paragraph> 
                               {description}
                            </Typography>
                            <Typography sx={{color:"#B6b6b6"}} paragraph> 
                               {description1 ? description1 :""}
                            </Typography>
                           
                        </Box>
                        <Box  display='flex' mt={2}  >
                            
                            <BtnCreateLink to={link}
                                bgcolor="#1371FF" 
                                color="#fff" 
                                style={{
                                    border:"1px solid #1371FF"
                                }}
                            >
                                {btnTitle}
                            </BtnCreateLink>
                        </Box>
                    </Move>
                    <Move 
                    item 
                    lg={7} 
                    xs={12}
                      viewport={{once:true}}
                      initial={{opacity:0,y:100}}
                      whileInView={{opacity:1,y:0}}
                      transition={{type:"easeIn", duration:1,delay:0 }}

                        >
                            <Image
                                src={Image30}
                                alt="Jobnux.io - AI-Powered Job Interview"
                            />
                    </Move>
            </Grid>
        </Container>
    </Content>
  )
}

export default Steps

import React from 'react'
import { motion } from 'framer-motion'
import  styled from 'styled-components'


const Card = styled(motion.div)`
width: 189px;
height: 269px;
background: #FFFFFF;
border-radius: 10px;
transition: transform ease 300ms;

  :hover{
   transform: translate(0,-10px);
   box-shadow: 7px 4px 30px rgba(200, 200, 200, 0.49);

  }

  @media screen and (max-width: 470px){
   width: 160px;
   height: 250px;
   padding:3px;
 }
`
const Image = styled.img`
 width: 167px;
 height:199px;
 padding:10px;

 @media screen and (max-width: 470px){
   width: 150px;
   height: 180px;
   padding:5px;
 }

 
`

const Name = styled.p`

    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    text-align:center;
`

function UserCard({image,name,alt}) {
  return ( 
     <Card>
        <Image  src={image} alt={alt} />
        <Name>
           {name} 
        </Name>
     </Card>
  )
}

export default UserCard
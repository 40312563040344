import React from 'react'
import { Content, H1, P } from './Elements'
import { Button, Container, Grid } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'



function Made({translator}) {
  return (
    <Content 
    size="50vh" 
    bgcolor="#fff" 
    viewport={{once:true}}
    initial={{opacity:0,y:150}}
    whileInView={{opacity:1,y:0}}
    transition={{type:"easeIn", duration:1,delay:0 }}
    
    >
        <Container>
            <Grid  container spacing={2} >
                <Grid  item lg={4} xs={12} >
                    <H1 style={{fontSize:"40px",lineHeight: "130%"}} >
                       {translator("sections.made.made.h1")}
                    </H1>
                    <P>
                     {translator("sections.made.made.p")}
                    </P>
                </Grid>
                <Grid  
                    container 
                    item 
                    lg={8} 
                    xs={12}
                     marginTop={5}
                    >
                    <Grid 
                        item 
                        lg={3} 
                        xs={6} 
                        
                    >
                        <Button 
                            variant='text'   
                            startIcon={<CheckIcon  sx={{color:'#4ECB71',width:"60px",height:"60px"}} />}
                            style={{textTransform:"none",color:"#000",fontSize:"18px"}}
                        
                        >
                                 {translator("sections.made.made.btn1")}
                        </Button>
                    </Grid>
                    <Grid item lg={3} xs={6} >
                        <Button 
                            variant='text'   
                            startIcon={<CheckIcon   sx={{color:'#4ECB71',width:"60px",height:"60px"}} />}
                            style={{textTransform:"none",color:"#000",fontSize:"18px"}}
                        
                        >
                                 {translator("sections.made.made.btn2")}
                        </Button>
                    </Grid>
                    <Grid item lg={3} xs={6} >
                        <Button 
                            variant='text'   
                            startIcon={<CheckIcon   sx={{color:'#4ECB71',width:"60px",height:"60px"}} />}
                            style={{textTransform:"none",color:"#000",fontSize:"18px"}}
                        
                        >
                                {translator("sections.made.made.btn3")}
                        </Button>
                    </Grid>
                    <Grid item lg={3} xs={6} >
                       <Button 
                            variant='text'   
                            startIcon={<CheckIcon   sx={{color:'#4ECB71',width:"60px",height:"60px"}}/>}
                            style={{textTransform:"none",color:"#000",fontSize:"18px"}}
                        
                        >
                               {translator("sections.made.made.btn4")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </Content>
  )
}

export default Made

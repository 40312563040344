import React, { useState } from 'react'
import styled from 'styled-components';
import { FaArrowRight } from 'react-icons/fa';
import { IconButton, TextField, Typography,Box } from '@mui/material';
import { styled as styledMui } from '@mui/material/styles';
import axios from 'axios';


const Input = styled.input`
 width:150px;
 @media screen and (max-width: 760px){
  width:100px;
}
`


const ButtonIcon = styledMui(IconButton)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#1371FF',
  borderRadius: 0,
  borderTopRightRadius: 10,
  borderBottomRightRadius: 10,
  height:'100%',
  '&:hover': {
    backgroundColor: '#1371FF',
  }


}));

function Newsletter() {
  const [status, setStatus] = useState({
    submitted: false,
    submitting: false,
    info: { error: false, msg: null },
  });
  const [inputs, setInputs] = useState({
    email: '',
  });

  const handleServerResponse = (ok, msg) => {
    if (ok) {
      setStatus({
        submitted: true,
        submitting: false,
        info: { error: false, msg: msg },
      });
      setInputs({
        email: '',
        message: '',
      });
    } else {
      setStatus({
        info: { error: true, msg: msg },
      });
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setStatus((prevStatus) => ({ ...prevStatus, submitting: true }));

    const formData = new FormData();
    formData.append('email', inputs.email);

    axios({
      method: 'POST',
      url: 'https://formspree.io/f/maygvzwy',
      data: formData,
    })
      .then((response) => {
        handleServerResponse(
          true,
          'Thank you, your message has been submitted.',
        );
      })
      .catch((error) => {
        handleServerResponse(false, error.response.data.error);
      });
  };

  return (
    <Box 
      component="form" 
      onSubmit={handleOnSubmit} 
      mt={1}
      >
      {status.info.error && (
        <Typography color="red" paragraph>Error: {status.info.msg}</Typography>
      )}
      {!status.info.error && status.info.msg && <p>{status.info.msg}</p>}
      <Box 
        sx={{
          display:'flex',
          justifyContent:'center',
          height:50,
          
        }}
      >
        <input
          margin="normal"
          required
          id="email"
          placeholder="Email Address"
          name="email"
          type="email"
          autoComplete="email"
          value={inputs.email}
          onChange={(e) => setInputs({ email: e.target.value })}
          style={{width:"150px"}}
        />
        <ButtonIcon
         type="submit"
         disabled={status.submitting}
        >
          <FaArrowRight />
        </ButtonIcon>
      </Box>
      

    </Box>
  )
}

export default Newsletter

import * as React from 'react'
import { Content } from '../../styles/GlobalStyles'
import { Container, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { colors } from '../../styles';
import ResumePaste from '../../images/resumePaste.png'
import styled from 'styled-components';


export const Img = styled.img`
    max-width:550px;
    height:auto;

    @media screen and (max-width: 760px){
      width:200px;   
      height:300px;
    }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography color={`${colors.white}`} >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}




function SectionTabs() {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <>
        <Content
            size="50vh" 
            bgcolor="#fff"
           
        >
            <Container>
                <Grid container spacing={2}  sx={{flexGrow: 1}} >
                   <Grid item lg={4} xs={4} >
                      <Tabs
                          orientation="vertical"
                          variant="standard"
                          value={value}
                          onChange={handleChange}
                          aria-label="Vertical tabs example"
                          sx={{ 
                            borderRight:3, 
                            borderColor: 'divider',
                            borderLeft:3
                          
                        }}
                      >
                          <Tab label={
                            <Box >
                              <Typography 
                                 sx={{
                                    color: value === 0 ? `${colors.blue}` :  `${colors.grayBlack}`,
                                    textTransform:'initial',
                                    textAlign:'left',
                                    fontSize:{lg:15,xs:12}
                                  }} 
                                  variant='h6' 
                                > 
                                  Tailored Resume Creation
                              </Typography>
                              <Typography sx={{
                                    color:`${colors.grayThird}`,
                                    textTransform:'none',
                                    fontSize:13,
                                    textAlign:'left',
                                    display:{lg:'flex',xs:'none'}
                                    }} 
                                  paragraph
                                > 
                                  Craft your perfect resume with JobGenius' Ai-powered plateform.
                                  
                              </Typography>
                            </Box>
                          } 
                          {...a11yProps(0)} 
                          />
                            <Tab label={
                              <Box>
                                  <Typography sx={{
                                    color: value === 1 ? `${colors.blue}` :  `${colors.grayBlack}`,
                                    textTransform:'initial',
                                    textAlign:'left',
                                    fontSize:{lg:15,xs:12}
                                  }} 
                                  variant='h6' 
                                   > 
                                  Multiple ATS-Optimized Templates
                                </Typography>
                                <Typography sx={{
                                    color:`${colors.grayThird}`,
                                    textTransform:'none',
                                    fontSize:13,
                                    textAlign:'left',
                                    display:{lg:'flex',xs:'none'}
                                    }} 
                                    paragraph
                                  > 
                                  Craft your perfect resume with JobGenius' Ai-powered plateform.
                                  
                                </Typography>
                              </Box>
                            } 
                            {...a11yProps(1)} 
                          />
                          <Tab label={
                              <Box>
                                  <Typography sx={{
                                    color: value === 2 ? `${colors.blue}` :  `${colors.grayBlack}`,
                                    textTransform:'initial',
                                    textAlign:'left',
                                    fontSize:{lg:15,xs:12}
                                  }} 
                                  variant='h6'  > 
                                  ATS Score Assessment
                                </Typography>
                                <Typography sx={{
                                    color:`${colors.grayThird}`,
                                    textTransform:'none',
                                    fontSize:13,
                                    textAlign:'left',
                                    display:{lg:'flex',xs:'none'}
                                    }}  
                                  paragraph> 
                                Craft your perfect resume with JobGenius' Ai-powered plateform.
                                 
                                </Typography>
                              </Box>
                            }  {...a11yProps(2)} />
                          <Tab label={
                              <Box>
                                <Typography sx={{
                                    color: value === 3 ? `${colors.blue}` :  `${colors.grayBlack}`,
                                    textTransform:'initial',
                                    textAlign:'left',
                                    fontSize:{lg:15,xs:12}
                                  }} 
                                  variant='h6'  > 
                                  Simple Resume Import :
                                </Typography>
                                <Typography sx={{
                                    color:`${colors.grayThird}`,
                                    textTransform:'none',
                                    fontSize:13,
                                    textAlign:'left',
                                    display:{lg:'flex',xs:'none'}
                                    }}  paragraph> 
                                Craft your perfect resume with JobGenius' Ai-powered plateform.
                                 
                                </Typography>
                              </Box>
                            }  {...a11yProps(3)} />
                        
                      </Tabs>
                    </Grid>
                    <Grid 
                    item 
                    lg={8} 
                    xs={8} 
                    sx={{
                      height:450,
                      background:`${colors.gradiant}`,
                      borderRadius:5,
                      display:'flex',
                      justifyContent:'center',
                      alignItems:'center',
                      
                    }}
                    >
                          <TabPanel value={value} index={0}>
                                  <Img
                                    src={ResumePaste}
                                    alt="Resume make ai"
                                  />
                          </TabPanel>
                          <TabPanel value={value} index={1}>
                                    Craft your perfect resume with JobGenius' Ai-powered plateform.
                                      Choose from a variety of templates, tailor your resume for specific job
                                      description, and get an ATS score to optimize your chances of landing your dream job.
                                  
                          </TabPanel>
                          <TabPanel value={value} index={2}>
                                    Craft your perfect resume with JobGenius' Ai-powered plateform.
                                      Choose from a variety of templates, tailor your resume for specific job
                                      description, and get an ATS score to optimize your chances of landing your dream job.
                                  
                          </TabPanel>
                          <TabPanel value={value} index={3}>
                                    Craft your perfect resume with JobGenius' Ai-powered plateform.
                                      Choose from a variety of templates, tailor your resume for specific job
                                      description, and get an ATS score to optimize your chances of landing your dream job.
                                  
                          </TabPanel>
                    </Grid>
                </Grid>
              
            </Container>
        </Content>
    </>
  )
}

export default SectionTabs
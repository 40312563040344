import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import styled from 'styled-components';
import { Avatar, Rating } from '@mui/material';



const H1 = styled.h1`
font-size:12px;
font-weight:bold;

@media screen and (max-width: 500px){
    font-size:12px
  }
`

const P = styled.p`
font-size:13px;
color:#5C5C5C;

@media screen and (max-width: 500px){
    font-size:10px
  }
`

const Span = styled.span`
background-color:${props=>props.item.bgColor};
width:41px;
height:42px;
display:flex;
align-items:center;
justify-content:center;
text-align:center;
color:${props=>props.item.iconColor};
border-radius:10px;
font-size:30px;

@media screen and (max-width: 500px){
    width:21px;
    height:22px;
    font-size:10px;
    border-radius:5px;

  }
`

export default function Testemonials({item}) {

 

  return (
    <Card sx={{ 
        maxWidth: 393,
        height:230,
        borderRadius:"20px",
        border: "2px solid #9FD7FF",
        flex: "none",
        order: 2,
        flexGrow: 0,
        zIndex:1

    }}>
     
      <CardContent>
        <P>
          {item.description}
        </P>
      </CardContent>
      <CardHeader
        avatar={
          <Avatar sx={{width:50,height:50,objectFit:"cover",zIndex:1}} src={item.image} />
          
        }
        title={<H1>{item.title} </H1>}
        subheader={<Rating  name="" value={5} readOnly />}
      />
    </Card>
  );
}
import React,{useEffect,useState} from 'react'
import { Content, MainTitle1 } from '../../styles/GlobalStyles'
import { Container, Grid } from '@mui/material'
import Accordeon from '../../components/accordeon/Accordeon'
import { faqData } from '../../data/faqData'
import { Helmet } from 'react-helmet'
import Ready from '../../components/shared/Ready'
import ContactForm from '../../components/forms/contact'


function ContactUs() {

  const [questons,setQuestions] = useState([])

useEffect(() => {
 setQuestions(faqData)

  return () => {
    setQuestions([]) 
  }
}, [])

useEffect(() => {
   
  window.scrollTo({
      top:0
  })

}, [])


  return (
    <>
     <Helmet>
          <meta charSet="utf-8" />
          <title>Contact Us | Jobnux</title>
     </Helmet>
    <Content
      size="100vh" 
      bgcolor="#fff" 
      viewport={{once:true}}
      initial={{opacity:0,y:50}}
      whileInView={{opacity:1,y:0}}
      transition={{type:"easeIn", duration:1,delay:0 }}
    >
      <Container>
          <Grid container spacing={2} >
              <Grid  item lg={12} xs={12}>
                 <MainTitle1>
                      <h1>Got Questions ? </h1>
                      <p>Get in touch with the Jobnux team and we'll get back to you and help as soon as we can!</p>
                 </MainTitle1>
              </Grid>
              <Grid item lg={6} md={6} xs={12} >
                  <ContactForm/>
              </Grid>
              <Grid item lg={6} md={6} xs={12} >
                  <Accordeon
                    data={questons}
                  />
                </Grid>
                <Grid mb={5} item lg={12} md={12} xs={12} >
                    <Ready/>
                </Grid>
          </Grid>
      </Container>
    </Content>
    </>
   
  )
}

export default ContactUs
import React from 'react'
import { Content, H2, LI, UL } from './Elements'
import { Container, Grid } from '@mui/material'
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Saly from '../../images/Saly-1.png'
import { useTranslation } from 'react-i18next';

    const Image = styled(motion.img)`
        width: 100%;
        height: auto;
        z-index:1; 
    `
export const P = styled(motion.p)`
  font-size:27px;
@media screen and (max-width: 760px){
  
  font-size:20px;
}
`

function BusinessCreator() {
  const { t:home } = useTranslation('homepage');

  return (
    <Content  size="100vh"  bgcolor="linear-gradient(89.84deg, #10091B 53.18%, #10112D 61.25%, #111B42 71.1%, #11285F 80.75%, #113983 88.44%, #124DB0 99.68%, #1371FF 99.91%)">
        <Container>
                <Grid container >
                    <Grid item lg={12} xs={12} >
                        <P style={{color:"#fff",fontWeight:"bold"}}>
                          {home("sections.businessCreator.content1")}
                       </P>
                    </Grid>
                    <Grid item lg={6} xs={12} >
                        <H2 style={{color:"#fff",fontSize:"27px"}} >{home("sections.businessCreator.h2")}</H2>
                        <P style={{color:"#fff",lineHeight:"27px"}} >
                        {home("sections.businessCreator.content3")}
                        </P>
                       
                        <UL>
                            <LI>{home("sections.businessCreator.ul.li1")}</LI>
                            <LI>{home("sections.businessCreator.ul.li2")}</LI>
                            <LI>{home("sections.businessCreator.ul.li3")}</LI>
                            <LI>{home("sections.businessCreator.ul.li4")}</LI> 
                        </UL>
                    </Grid>
                    <Grid 
                        item 
                        lg={6} xs={12}
                        display="flex"
                        justifyContent="center"


                     >
                        <Image 
                            src={Saly} 
                            alt="Jobnux - AI Business Creator" 
                            whileTap={{scale:0.6}}
                            drag={true}
                            dragConstraints={{left:20,right:50,top:20,bottom:50}}
                            viewport={{once:true}}
                            initial={{opacity:0,y:0}}
                            whileInView={{opacity:1,y:-100}}
                            transition={{type:"easeIn", duration:1,delay:0 }}
                            />
                    </Grid>
                </Grid>
        </Container>
    </Content>
  )
}

export default BusinessCreator

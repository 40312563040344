const { 
    CREATE_RESIGNATION_LETTER, 
    CREATE_RESIGNATION_LETTER_SUCCESS, 
    CREATE_RESIGNATION_LETTER_FAIL,
    LIST_RESIGNATION_LETTERS,
    LIST_RESIGNATION_LETTERS_SUCCESS,
    LIST_RESIGNATION_LETTERS_FAIL,
    LIST_USER_RESIGNATION_LETTERS,
    LIST_USER_RESIGNATION_LETTERS_SUCCESS,
    LIST_USER_RESIGNATION_LETTERS_FAIL,
    VIEW_RESIGNATION_LETTER_DETAILS,
    VIEW_RESIGNATION_LETTER_DETAILS_SUCCESS,
    VIEW_RESIGNATION_LETTER_DETAILS_FAIL,
    UPDATE_RESIGNATION_LETTER,
    UPDATE_RESIGNATION_LETTER_SUCCESS,
    UPDATE_RESIGNATION_LETTER_FAIL,
    DELETE_RESIGNATION_LETTER,
    DELETE_RESIGNATION_LETTER_SUCCESS,
    DELETE_RESIGNATION_LETTER_FAIL
} = require("../constants/appConstants");


function listResignationReducer(state = {}, action) {
    switch (action.type) {
      case LIST_RESIGNATION_LETTERS:
        return { loading: true };
      case LIST_RESIGNATION_LETTERS_SUCCESS:
        return { loading: false, resignationsListed: action.payload };
      case LIST_RESIGNATION_LETTERS_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

  function listUserResignationsReducer(state = {}, action) {
    switch (action.type) {
      case LIST_USER_RESIGNATION_LETTERS:
        return { loading: true };
      case LIST_USER_RESIGNATION_LETTERS_SUCCESS:
        return { loading: false, userResignationsListed: action.payload };
      case LIST_USER_RESIGNATION_LETTERS_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

  function resignationDetailReducer(state = {}, action) {
    switch (action.type) {
      case VIEW_RESIGNATION_LETTER_DETAILS: 
        return { loading: true };
      case VIEW_RESIGNATION_LETTER_DETAILS_SUCCESS:
        return { loading: false, resignationDetailInfo: action.payload };
      case VIEW_RESIGNATION_LETTER_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

function addResignationReducer(state = {}, action) {
    switch (action.type) {
      case CREATE_RESIGNATION_LETTER:
        return { loading: true };
      case CREATE_RESIGNATION_LETTER_SUCCESS:
        return { loading: false, resignationInfoCreated: action.payload };
      case CREATE_RESIGNATION_LETTER_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
}

function updateResignationReducer(state = {}, action) {
    switch (action.type) {
      case UPDATE_RESIGNATION_LETTER:
        return { loading: true };
      case UPDATE_RESIGNATION_LETTER_SUCCESS:
        return { loading: false, resignationUpdated: action.payload };
      case UPDATE_RESIGNATION_LETTER_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
}

function deleteResignationReducer(state = {}, action) {
    switch (action.type) {
      case DELETE_RESIGNATION_LETTER:
        return { loading: true };
      case DELETE_RESIGNATION_LETTER_SUCCESS:
        return { loading: false, resignationDeleted: action.payload };
      case DELETE_RESIGNATION_LETTER_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
}


export {
    listUserResignationsReducer, 
    listResignationReducer,
    addResignationReducer,
    updateResignationReducer,
    deleteResignationReducer,
    resignationDetailReducer
  }
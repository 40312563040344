export const black = '#000'
export const blackSecond = '#0F0F0F'
export const blackSecondary = '#3F3F45'
export const lightGray = '#DFDFDF'
export const gray = '#C8C8C8'
export const grayBlack = '#616161'
export const graySecondary = '#BDBDBD'
export const grayLight = '#DFDFDF'
export const grayThird = '#7D7D7D'
export const backgroundGray = '#e6e9ed'
export const white = '#ffffff'  
export const green = '#28B446'  
export const blue = '#1371FF'
export const lightBlue ='#51C9FF'
export const darkBlue ='#002357'  
export const secondaryBlue = '#004ec4'  
export const red = '#D32F2F'
export const primary = blue  
export const secondary = '#10091B'
export const danger = red
export const gradiant = 'linear-gradient(180deg, #136EF5 0%, #021634 83.8%)'
export const backgroundabout = '#EFF1F3'
export const background = '#14171d'

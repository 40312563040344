import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import HeroBanner from '../../components/HeroSections/HeroBanner';
import Partners from '../../components/sections/Partners';
import SectionTestemonials from '../../components/sections/SectionTestemonials';
import SectionStep from '../../components/sections/SectionStep';
import MainHeroTitle from '../../components/HeroSections/MainHeroTitle';
import ModerneTemplate from '../../components/sections/ModerneTemplate';
import SectionTabs from '../../components/sections/SectionTabs';
import SingleTestemonial from '../../components/sections/SingleTestemonial';
import SectionFaq from '../../components/sections/SectionFaq';

import BannerImage from '../../images/resumeBanner.png';
import Bande from '../../components/shared/Bande';
import { cvTemplateData } from '../../data/cvTemplateData';
import ImgSetp1 from '../../images/resumeStep1.png';
import ImgSetp2 from '../../images/resumeStep2.png';
import ImgSetp3 from '../../images/resumeStep3.png';
import { useTranslation } from 'react-i18next';

 

function Resume() {

  const {t} = useTranslation('resumeCreatorPage')
  useEffect(() => {
    // Remarque : Je ne suis pas sûr pourquoi vous utilisez scrollTo(0) ici. Si cela n'est pas nécessaire, vous pouvez le supprimer.
    window.scrollTo({
      top: 0
    });
  }, []);

  return (
    <div>
       <Helmet>
            <meta charSet="utf-8" />
            <title> {t("head.title")}</title>
            <meta name="description" content={t("head.description")} />
      </Helmet>

      <HeroBanner
        title= {t("sections.heroBanner.title")} 
        paragraph={t("sections.heroBanner.content")} 
        image={BannerImage}
        btnTitle={t("sections.heroBanner.btnTitle")} 
        link={t("sections.heroBanner.link")} 

      />

      <Partners />
      <MainHeroTitle
        subTitle={t("sections.heroTitle1.subTitle")} 
        title={t("sections.heroTitle1.title")} 
        paragraph=""
      />
      <SectionTabs />
      <MainHeroTitle
        subTitle={t("sections.heroTitle2.subTitle")} 
        title={t("sections.heroTitle2.title")} 
        paragraph={t("sections.heroTitle2.paragraph")} 
      />

      {/* Utilisation de la map pour éviter la répétition du code SectionStep */}
      {[
        {
          step:`${t("sections.steps.one.step")}` ,
          title: `${t("sections.steps.one.title")}`,
          description:`${t("sections.steps.one.description")}`,
          btnTitle:`${t("sections.steps.one.btnTitle")}`,
          image: ImgSetp1,
          height: "auto",
          width: '300px'
        },
        {
          step:`${t("sections.steps.two.step")}` ,
          title: `${t("sections.steps.two.title")}`,
          description:`${t("sections.steps.two.description")}`,
          btnTitle:`${t("sections.steps.two.btnTitle")}`,
          image: ImgSetp2,
          height: "auto",
          width: '500px'
        },
        {
          step:`${t("sections.steps.three.step")}` ,
          title: `${t("sections.steps.three.title")}`,
          description:`${t("sections.steps.three.description")}`,
          btnTitle:`${t("sections.steps.three.btnTitle")}`,
          image: ImgSetp3,
          height: "auto",
          width: '250px'
        }
      ].map((stepData, index) => (
        <SectionStep key={index} {...stepData} />
      ))}

      <ModerneTemplate data={cvTemplateData} />
      <SectionTestemonials /> 
      <Bande  
        title={t("sections.Bande.title")}
        content={t("sections.Bande.content")}
      />
      <SingleTestemonial />
      <SectionFaq />
    </div>
  );
}

export default Resume;


import React,{useEffect} from 'react'
import {Helmet} from 'react-helmet'
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import Banner from './Banner' 
import ResultsNumbers from '../home/ResultsNumbers'
import AiDriven from './AiDriven'
import Steps from './Steps'
import Bande from '../../components/shared/Bande'
import CoverSpace from './CoverSpace'
import SingleTestemonial from '../../components/sections/SingleTestemonial'
import { Content } from '../../components/sections/SectionFlex'
import ReadyToLevelUp from '../../components/shared/ReadyToLevelUp'
import Vec3 from '../../images/Vector9.png'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import SectionFaq from '../../components/sections/SectionFaq'
import Partners from '../../components/sections/Partners'
import SectionTestemonials from '../../components/sections/SectionTestemonials'
import { useTranslation } from 'react-i18next'
 


export const H1 = styled(motion.h1)`
   font-size:38px;
  
   @media screen and (max-width: 1200px){
     text-align:center;
   }
   @media screen and (max-width: 760px){
    text-align:center;
    font-size:28px;
  } 
`;

export const StepNumber = styled.span`
  background-color:#E2F6FF;
  color:#000;
  border-radius:50px;
  width: 38px;
  height: 38px;
  border: 1px solid #E2F6FF;
  font-weight:400;
  text-align:center;
  font-size:30px
`

function JobInterviewSimulator() {

  const { t } = useTranslation('simulationPage'); 

useEffect(() => {
   
    window.scrollTo({
        top:0
    })

}, [])

 
  return (
    <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title> {t("head.title")} </title>
            <meta name="description" content={t("head.description")} />
        </Helmet>
        <Banner translator={t}/> 
        <Partners />
        <AiDriven translator={t}/>
        <ResultsNumbers />
        
        <Steps
          step={t("sections.steps.stepOne.step")} 
          title={t("sections.steps.stepOne.title")}
          description={t("sections.steps.stepOne.description")}
          link={t("sections.steps.stepOne.link")}
          btnTitle={t("sections.steps.stepOne.btnTitle")}
        />

        <Steps
          step={t("sections.steps.stepTwo.step")} 
          title={t("sections.steps.stepTwo.title")}
          description={t("sections.steps.stepTwo.description")}
          description1={t("sections.steps.stepTwo.description1")}
          link={t("sections.steps.stepTwo.link")}
          btnTitle={t("sections.steps.stepTwo.btnTitle")}
       />
         <Steps
           step={t("sections.steps.stepThree.step")} 
           title={t("sections.steps.stepThree.title")}
           description={t("sections.steps.stepThree.description")}
           description1={t("sections.steps.stepThree.description1")}
           link={t("sections.steps.stepThree.link")}
           btnTitle={t("sections.steps.stepThree.btnTitle")}
       />
        <Content
          size="100%" 
          bgcolor="#fff" 
          viewport={{once:true}}
          initial={{opacity:0,y:50}}
          whileInView={{opacity:1,y:0}}
          transition={{type:"easeIn", duration:1,delay:0 }}
        >
          <Container>
                   <Grid  
                      item lg={12}
                      container 
                    >
                             <Grid
                                  item
                                  lg={4}
                                  xs={6}
                                  sx={{
                                    backgroundImage:`url(${Vec3})`,
                                    backgroundRepeat:'no-repeat',
                                    backgroundPosition:"center",
                                    backgroundPositionY:"center",
                                  }}  
                                >

                              </Grid>
                              <Grid item lg={8} xs={6}>
                                    <Box>                                   
                                        <Stack  direction="row" gap={1} alignItems="center" >
                                          <StepNumber> 4 </StepNumber> 
                                          <H1  style={{fontSize:30}}>{t("sections.starter.title")} </H1> 
                                        </Stack>
                                        <Typography style={{color:"#000"}} paragraph>
                                        {t("sections.starter.content")}
                                        </Typography>                                       
                                    </Box>                                   
                              </Grid>
                    </Grid>
          </Container>
        </Content>
        
        <SectionTestemonials/>
       
        
        <Bande 
          title={t("sections.bande.title")}
          content={t("sections.bande.content")}
          btnTitle={t("sections.bande.btnTitle")}
          link={t("sections.bande.link")}
        />
        <CoverSpace translator={t}/>
        <SingleTestemonial/>
        <SectionFaq/> 
          <ReadyToLevelUp
            title={t("sections.levelUp.title")} 
            content={t("sections.levelUp.content")} 
            content2={
            <Typography 
             color="#fff" 
             paragraph
             sx={{
              textAlign:{lg:'left',md:'center',sm:'center',xs:'center'}
            }}
             >
               {t("sections.levelUp.paragraph.textOne")} 
                <b> {t("sections.levelUp.paragraph.textTwo")}</b>  
                {t("sections.levelUp.paragraph.textThree")}
            </Typography>}
            btnTitle={t("sections.levelUp.btnLink")}
            link={t("sections.levelUp.link")}
          />
       
          
    </div>
  )
}

export default JobInterviewSimulator
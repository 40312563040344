// experienceReducer.js

import { ADD_EXPERIENCE, ADD_RESPONSABILTIY, DELETE_EXPERIENCE, DELETE_RESPONSABILTIY, UPDATE_EXPERIENCE, UPDATE_RESPONSABILTIY } from "../constants/resumeConstants";

const initialState = {
  experiences: [],
};

const experienceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EXPERIENCE:
        // Construire un objet map avec uuid comme clé pour les items expériences existants
        const existingExperiencesMap = state.experiences.reduce(
          (acc, experience) => ({ ...acc, [experience.uuid]: experience }),
          {}
        );

        // Parcourir les nouveaux items et mettre à jour ou ajouter au state
        const updatedExperiences = action.payload.map((newExperience) => {
          const { uuid } = newExperience;
          if (existingExperiencesMap.hasOwnProperty(uuid)) {
            // Mettre à jour l'item existant avec l'uuid correspondant
            return { ...existingExperiencesMap[uuid], ...newExperience };
          } else {
            // Ajouter le nouvel item s'il n'existe pas déjà
            return newExperience;
          }
        });

        return {
          ...state,
          experiences: updatedExperiences
        };
    case UPDATE_EXPERIENCE:
      return {
        ...state,
        experiences: state.experiences.map((experience) =>
          experience.id === action.payload.experienceId ? action.payload.updatedExperience : experience
        ),
      };
   
    case DELETE_EXPERIENCE:
        return {
          ...state,
          experiences: action.payload.filter((_, i) => i !== action.payload),
        };

    case ADD_RESPONSABILTIY:
        return {
          ...state,
          experiences: state.experiences.map((experience) =>
            experience.id === action.payload.experienceId
              ? { ...experience, responsabilities: [...experience.responsabilities, action.payload.responsability] }
              : experience
          ),
        };
    case UPDATE_RESPONSABILTIY:
        return {
          ...state,
          experiences: state.experiences.map((experience) =>
          experience.id === action.payload.experienceId
              ? {
                  ...experience,
                    responsabilities: experience.responsabilities.map((responsability) =>
                    responsability.id === action.payload.responsabilityId ? action.payload.updatedResponsability : responsability
                  ),
                }
              : experience
          ),
        };
    case DELETE_RESPONSABILTIY:
      return {
        ...state,
        experiences: state.experiences.map((experience) =>
          experience.id === action.payload.experienceId
            ? {
                ...experience,
                responsabilities: experience.responsabilities.filter((responsability) => responsability.id !== action.payload.responsabilityId),
              }
            : experience
        ),
      };
    default:
      return state;
  }
};

export default experienceReducer;

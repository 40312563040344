const { 
    CREATE_COVER_LETTER, 
    CREATE_COVER_LETTER_SUCCESS, 
    CREATE_COVER_LETTER_FAIL,
    LIST_COVER_LETTERS,
    LIST_COVER_LETTERS_SUCCESS,
    LIST_COVER_LETTERS_FAIL,
    LIST_USER_COVER_LETTERS,
    LIST_USER_COVER_LETTERS_SUCCESS,
    LIST_USER_COVER_LETTERS_FAIL,
    GET_COVER_LETTER_DETAILS,
    GET_COVER_LETTER_DETAILS_SUCCESS,
    GET_COVER_LETTER_DETAILS_FAIL,
    UPDATE_COVER_LETTER,
    UPDATE_COVER_LETTER_SUCCESS,
    UPDATE_COVER_LETTER_FAIL,
    DELETE_COVER_LETTER,
    DELETE_COVER_LETTER_SUCCESS,
    DELETE_COVER_LETTER_FAIL
} = require("../constants/appConstants");


function listCoverLettersReducer(state = {}, action) {
    switch (action.type) {
      case LIST_COVER_LETTERS:
        return { loading: true };
      case LIST_COVER_LETTERS_SUCCESS:
        return { loading: false, coverLettersListed: action.payload };
      case LIST_COVER_LETTERS_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

  function listUserCoverLettersReducer(state = {}, action) {
    switch (action.type) {
      case LIST_USER_COVER_LETTERS:
        return { loading: true };
      case LIST_USER_COVER_LETTERS_SUCCESS:
        return { loading: false, userCoverLettersListed: action.payload };
      case LIST_USER_COVER_LETTERS_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

  function coverLetterDetailReducer(state = {}, action) {
    switch (action.type) {
      case GET_COVER_LETTER_DETAILS:
        return { loading: true };
      case GET_COVER_LETTER_DETAILS_SUCCESS:
        return { loading: false, coverLetterDetailInfo: action.payload };
      case GET_COVER_LETTER_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

function addCoverLetterReducer(state = {}, action) {
    switch (action.type) {
      case CREATE_COVER_LETTER:
        return { loading: true };
      case CREATE_COVER_LETTER_SUCCESS:
        return { loading: false, coverLetterInfoCreated: action.payload };
      case CREATE_COVER_LETTER_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
}

function updateCoverLetterReducer(state = {}, action) {
    switch (action.type) {
      case UPDATE_COVER_LETTER:
        return { loading: true };
      case UPDATE_COVER_LETTER_SUCCESS:
        return { loading: false, coverLetterUpdated: action.payload };
      case UPDATE_COVER_LETTER_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
}

function deleteCoverLetterReducer(state = {}, action) {
    switch (action.type) {
      case DELETE_COVER_LETTER:
        return { loading: true };
      case DELETE_COVER_LETTER_SUCCESS:
        return { loading: false, coverLetterDeleted: action.payload };
      case DELETE_COVER_LETTER_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
}


export {
    listUserCoverLettersReducer, 
    listCoverLettersReducer,
    addCoverLetterReducer,
    updateCoverLetterReducer,
    deleteCoverLetterReducer,
    coverLetterDetailReducer

  }
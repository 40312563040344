 import Image from '../images/cvtemplate.png'


export const cvTemplateData = [
    {
        id:1,
        name:'Template 1',
        image:Image,

    },
    {
        id:2,
        name:'Template 2',
        image:Image,
        
    },
    {
        id:3,
        name:'Template 3',
        image:Image,
        
    },
    {
        id:4,
        name:'Template 4',
        image:Image,
        
    },
    {
        id:5,
        name:'Template 5',
        image:Image,
        
    }
]
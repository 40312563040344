import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardHeader, Stack } from '@mui/material';
import { Check } from '@mui/icons-material';

export default function SimpleFeatures({item}) {
  return (
    <Card 
      elevation={0}
      sx={{ 
        maxWidth: 345,
        borderRadius:"15px",
        }}>
      <CardHeader
        title={
          <Typography  sx={{fontSize:15,fontWeight:'bold'}} variant="h4">
            {item.title}
          </Typography>
        }
        subheader={
          <Typography sx={{fontSize:12}} paragraph >
            {item.description}
          </Typography>
        }
      />
      <CardMedia
        sx={{ 
            height: 240, 
        }}
        image={item.image}
        title="green iguana"
      />
      {
        item.feature ?
            <CardContent
              sx={{
              backgroundColor:"#fff"
              }}
            >
                {
                  item.feature.map((item)=>(
                      <Stack key={item.id} mb={1} direction="row" gap={1} justifyContent="flex-start" alignItems="center" >
                
                      <span  >  <Check   sx={{color:'#2DC071'}} /> </span>
                      <span style={{fontSize:12}}> {item.description} </span>
                    </Stack>
                  ))
                }
              
            </CardContent>
        :
        ""
      }
     
    </Card>
  );
}
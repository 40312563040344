import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography } from '@mui/material';
import MainHeroTitle from '../../components/HeroSections/MainHeroTitle';
import SectionStep from '../../components/sections/SectionStep';
import ResultsNumbers from '../home/ResultsNumbers';
import FeatureSpace from '../../components/sections/FeatureSpace';
import SectionTestemonials from '../../components/sections/SectionTestemonials';
import SingleTestemonial from '../../components/sections/SingleTestemonial';
import ReadyToLevelUp from '../../components/shared/ReadyToLevelUp';
import HeroBanner from '../../components/HeroSections/HeroBanner';
import BannerImage from '../../images/bannerResigantion.png';
import ResignationSpace from '../../images/image42.png';
import SectionGradiant from '../../components/sections/SectionGradiant';
import Letter from '../../images/twoletter.png';
import TemplateCarousel from '../../components/sections/TemplateCarousel';
import Partners from '../../components/sections/Partners';

import { LetterText } from '../../data/letters';
import LetterCategories from '../../data/letterCategories.json';
import { useTranslation } from 'react-i18next';

const createLetterLink = "/login";

const Resignation = () => {

  const {t} = useTranslation('resigantionPage')
  const [categories, setCategories] = useState([]);
  const [letters, setLetters] = useState([]);

  useEffect(() => {
    setCategories(LetterCategories);
  }, []);

  useEffect(() => {
    setLetters(LetterText);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);


  return (
    <div>
       <Helmet>
            <meta charSet="utf-8" />
            <title> {t("head.title")}</title>
            <meta name="description" content={t("head.description")} />
      </Helmet>

      <HeroBanner
        title={t("sections.heroBanner.title")} 
        paragraph= {t("sections.heroBanner.content")} 
        btnTitle= {t("sections.heroBanner.btnTitle")} 
        image={BannerImage}
        link={t("sections.heroBanner.link")} 
      />
      <Partners />
      <TemplateCarousel 
        data={letters}
        filter={t("sections.templateCarousel.filter")} 
        title={t("sections.templateCarousel.title")} 
        linkToCreateTemplate={createLetterLink}
        linkToViewAll="#"
      />
      <MainHeroTitle
        subTitle={t("sections.heroTitle.subTitle")} 
        title={t("sections.heroTitle.title")} 
        paragraph={t("sections.heroTitle.paragraph")} 
      />
      {[
        {
          step:`${t("sections.steps.one.step")}` ,
          title:`${t("sections.steps.one.title")}` ,
          description:`${t("sections.steps.one.description")}` ,
          btnTitle: `${t("sections.steps.one.btnTitle")}` ,
          link: `${t("sections.steps.one.link")}`,
        },
        {
          step:`${t("sections.steps.two.step")}` ,
          title:`${t("sections.steps.two.title")}` ,
          description:`${t("sections.steps.two.description")}` ,
          btnTitle: `${t("sections.steps.two.btnTitle")}` ,
          link: `${t("sections.steps.two.link")}`,
        },
      ].map((stepData, index) => (
        <SectionStep key={index} {...stepData} />
      ))}
      <ResultsNumbers />
      <FeatureSpace
        image={ResignationSpace}
      />
      <SectionTestemonials />
      <SectionGradiant
        left={
          <Box>
            <Typography paragraph>
               {t("sections.gradiant.title")}
            </Typography>
            <Typography paragraph>
            {t("sections.gradiant.content")}
            </Typography>
          </Box>
        }
        image={Letter}
        alt={t("sections.gradiant.alt")}
      />
      <SingleTestemonial />
      <ReadyToLevelUp
        title={t("sections.readyToLevelUp.title")}
        content={t("sections.readyToLevelUp.content")}
        content2={
        <Typography color="#fff" paragraph>
          {t("sections.readyToLevelUp.content2.word1")} 
          <b> 
            {t("sections.readyToLevelUp.content2.word2")}
          </b> 
          {t("sections.readyToLevelUp.content2.word3")}
        </Typography>}
        btnTitle={t("sections.readyToLevelUp.btnTitle")}
      />
    </div>
  );
};

export default Resignation;

import React from 'react'
import { Box, Container, Grid, Stack, Typography } from '@mui/material'
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Img1 from '../../images/Frame3272.png'
import Img2 from '../../images/Frame3273.png'
import Img3 from '../../images/Frame3274.png'
import Vec1 from '../../images/Vector11.png'
import Vec2 from '../../images/Vector10.png'
import Vec3 from '../../images/Vector9.png'
import { Link } from 'react-router-dom';
import CallToActionBtn from '../../components/buttons/CallToActionBtn';
import { colors } from '../../styles';

const Move = styled(motion(Grid))`
`

export const Content = styled.div`
  display:flex;
  align-items:center;
  min-height:${props => props.size};
  background-color:${props => props.bgcolor};

`;


export const H1 = styled.h1`
   font-size:38px;
  
   @media screen and (max-width: 1200px){
     text-align:center;
   }

   @media screen and (max-width: 760px){
    text-align:center;
    font-size:28px;
  } 

  @media screen and (max-width: 400){
    text-align:center;
    font-size:12px;
  } 
`;

export const StepNumber = styled.span`
  background-color:#E2F6FF;
  color:#000;
  border-radius:50px;
  width: 38px;
  height: 38px;
  border: 1px solid #E2F6FF;
  font-weight:400;
  text-align:center;
  font-size:30px
`

export const Minimage = styled.img`
    max-width:400px;
    height:auto;
    border-radius:15px;

    @media screen and (max-width: 760px){
      max-width:350px;
      height:auto;
    }


    @media screen and (max-width: 400px){
      max-width:250px;   
      height:auto;
    }
`;

export const BtnCreateLink = styled(motion(Link))`
  font-size: 15px;
  font-weight:400;
  color:#fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:10px;
  max-width:300px;
  height:30px;
  text-decoration: none;
  border:2px solid #1371FF; 
  cursor: pointer;
  border-radius:10px;
  background-color:#1371FF;
  line-height: 200px;
  @media screen and (max-width: 760px){
    font-size: 12px;
  }
`;


function Steps({ size, bgcolor, containerColor, translator }) {
  return (
    <Content size={size} bgcolor={bgcolor} >
      <Container
        style={{
          backgroundColor: `${containerColor}`,
          borderRadius: "20px",
        }}
      >
        <Grid
          container
          paddingTop="20px"
          paddingBottom="20px"
        >
          <Grid
            container
            item
            spacing={2}
            lg={12}
            xs={12}
            sx={{
              backgroundImage: `url(${Vec1})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: "center",
              backgroundPositionY: "bottom",
              backgroundSize: { lg: '350px', xs: '250px' },
              marginBottom: { lg: 0, xs: 10 }
            }}
          >
            <Move
              item
              lg={6} md={6} sm={6} xs={12}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              gap={2}
              viewport={{ once: true }}
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "easeIn", duration: 1, delay: 0 }}
            >

              <Box
                sx={{
                  display: { lg: 'none', sm: 'none' }
                }}
              >
                <Stack direction="row" gap={1} alignItems="center" >
                  <StepNumber> 1 </StepNumber>
                  <H1> {translator("sections.steps.step1.title")} </H1>
                </Stack>
                <Typography style={{ color: "#000" }} paragraph>
                  {translator("sections.steps.step1.content")}
                </Typography>
                <BtnCreateLink to={translator("sections.steps.link")} >
                  {translator("sections.steps.step1.btnTitle")}
                </BtnCreateLink>

              </Box>
              <Minimage
                src={Img1}
                alt=""
              />
            </Move>

            <Move
              item
              lg={6} md={6} sm={6} xs={12}
              display="flex"
              justifyContent="center"
              viewport={{ once: true }}
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "easeIn", duration: 1, delay: 0 }}
            >
              <Grid container display="flex" alignItems="center"  >
                <Box
                  sx={{
                    display: { lg: 'flex', md: 'flex', sm: 'flex', xs: 'none' }
                  }}
                >
                  <Box>
                    <Stack direction="row" gap={1} alignItems="center" >
                      <StepNumber> 1 </StepNumber>
                      <H1>{translator("sections.steps.step2.title")} </H1>
                    </Stack>
                    <Typography style={{ color: "#000" }} paragraph>
                      {translator("sections.steps.step2.content")}
                    </Typography>
                    <CallToActionBtn
                      title={translator("sections.steps.step2.btnTitle")}
                      variant="contained"
                      background={colors.primary}
                      color={colors.white}
                      hoverColor={colors.white}
                      link={translator("sections.steps.link")}
                  />

                  </Box>

                </Box>

              </Grid>
            </Move>
          </Grid>

          <Grid
            container
            item
            lg={12}
            xs={12}


            sx={{
              backgroundImage: `url(${Vec2})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: "center",
              backgroundPositionY: "bottom",
              backgroundSize: { lg: '250px', xs: '200px' },
              marginBottom: { lg: 0, xs: 10 },
              display: 'flex',
              justifyContent: 'center'

            }}
          >
            <Move
              item
              container
              lg={6} md={6} sm={6} xs={12}
              display="flex"
              justifyContent="center"
              viewport={{ once: true }}
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "easeIn", duration: 1, delay: 0 }}

            >
              <Grid
                item
                display="flex"
                alignItems="center"
                justifyContent='center'
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent='center'
                  flexDirection='column'
                >

                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"

                  >
                    <StepNumber> 2 </StepNumber>
                    <H1>{translator("sections.steps.step3.title")}</H1>
                  </Stack>
                  <Typography style={{ color: "#000" }} paragraph>
                    {translator("sections.steps.step3.content")}
                  </Typography>


                </Box>

              </Grid>
            </Move>
            <Move
              item
              lg={6} md={6} sm={6} xs={12}
              display="flex"
              justifyContent="center"
              viewport={{ once: true }}
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "easeIn", duration: 1, delay: 0 }}
              sx={{
                marginBottom: 10
              }}
            >
              <Minimage
                src={Img2}
                alt=""
              />
            </Move>

          </Grid>
          <Grid
            container
            item
            lg={12}
            xs={12}

          >
            <Move
              item
              lg={6} md={6} sm={6} xs={12}
              display="flex"
              justifyContent="center"
              flexDirection='column'
              viewport={{ once: true }}
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "easeIn", duration: 1, delay: 0 }}

            >
              <Box
                sx={{
                  display: { lg: 'none', sm: 'none' }
                }}
              >

                <Stack direction="row" gap={1} alignItems="center" >
                  <StepNumber> 3 </StepNumber>
                  <H1>{translator("sections.steps.step3.title")}</H1>
                </Stack>
                <Typography style={{ color: "#000" }} paragraph>
                   {translator("sections.steps.step3.content")}
                </Typography>
                <Typography style={{ color: "#000" }} paragraph>
                   {translator("sections.steps.step3.btnTitle")}
                </Typography>
              </Box>
              <Minimage
                src={Img3}
                alt=""
              />
            </Move>
            <Move
              item
              lg={6} md={6} sm={6} xs={12}
              display="flex"
              justifyContent="center"
              viewport={{ once: true }}
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ type: "easeIn", duration: 1, delay: 0 }}
            >
              <Grid container display="flex" alignItems="center"  >
                <Box
                  sx={{
                    display: { lg: 'flex', md: 'flex', sm: 'flex', xs: 'none' },
                    flexDirection: 'column'
                  }}
                >

                  <Stack direction="row" gap={1} alignItems="center" >
                    <StepNumber> 3 </StepNumber>
                    <H1> {translator("sections.steps.step4.title")}</H1>
                  </Stack>
                  <Typography style={{ color: "#000" }} paragraph>
                  {translator("sections.steps.step4.content1")}
                  </Typography>
                  <Typography style={{ color: "#000" }} paragraph>

                  {translator("sections.steps.step4.content2")}
                  </Typography>
                </Box>

              </Grid>
            </Move>

            <Grid
              item lg={12}
              container
            >
              <Grid
                item
                lg={4}
                xs={12}
                sx={{
                  backgroundImage: `url(${Vec3})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: "center",
                  backgroundPositionY: "center",
                  backgroundSize: { lg: '100px', xs: '70px' },
                  marginBottom: { lg: 0, xs: 10 }

                }}
              >

              </Grid>
              <Grid item lg={8} xs={12}  >
                <Box>

                  <Stack direction="row" gap={1} alignItems="center" >
                    <StepNumber> 4 </StepNumber>
                    <H1>
                    {translator("sections.steps.step6.title")}
                    </H1>
                  </Stack>
                  <Typography style={{ color: "#000" }} paragraph>
                  {translator("sections.steps.step6.content")}
                  </Typography>
                  
                  <CallToActionBtn
                      title={translator("sections.steps.step6.btnTitle")}
                      variant="contained"
                      background={colors.primary}
                      color={colors.white}
                      hoverColor={colors.white}
                      link={translator("sections.steps.link")}
                  />
                </Box>

              </Grid>

              <Grid item lg={12} xs={12} >
                <Box mt={4} >


                  <H1 style={{ textAlign: 'center', fontSize: 25 }}>
                  {translator("sections.endTitle.title")}
                  </H1>

                </Box>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Container>
    </Content>
  )
}

export default Steps

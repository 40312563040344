import React from 'react'
import { Content } from './Elements'
import { Box, Container, Grid } from '@mui/material'
import CustomerSpace from '../../images/image42.png'
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Image = styled(motion.img)`
  width:100%;
  height:auto
`

function CoverSpace() {
  return (
    <Content size="70vh" bgcolor="#FFF">
       <Container 
        mb={5}
       >
          <Grid container>
          
                
                    <Box  
                      sx={{
                        background:'linear-gradient(180deg, #136EF5 0%, #021634 83.8%)',
                        borderRadius:5,
                        paddingTop:5,
                        paddingLeft:5,
                        paddingRight:5
                      }}
                    >
                        <Image 
                          src={CustomerSpace} 
                          alt="JobGenius - Cover letter space"
                          viewport={{once:true}}
                          initial={{opacity:0,x:-100}}
                          whileInView={{opacity:1,x:0}}
                          transition={{type:"easeIn", duration:1,delay:0 }}
                        />
                    </Box>
                
             
          </Grid>
       </Container>
    </Content>
  )
}

export default CoverSpace

import React from 'react'
import { Box, Container, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import PoleEmploi from '../../images/poleemploi.png'
import Deloitte from '../../images/Deloitte.png'
import Lv from '../../images/LouisVuitton.png'
import Nestle from '../../images/Nestle.png'
import { Content, P } from '../../styles/GlobalStyles';
import { useTranslation } from 'react-i18next';


const Image = styled(motion.img)`
    width: 100px;
    height:50px;
`

function Partners() { 
    const { t } = useTranslation('sections');
  return (
    <Content 
        size="50vh" 
        bgcolor="#fff"
        viewport={{once:true}} 
        initial={{opacity:0,y:150}}
        whileInView={{opacity:1,y:0}}
        transition={{type:"easeIn", duration:1,delay:0 }} 
    
    >  
        <Container>
            <Grid  container spacing={2}>
                <Grid item xs={12}  >
                    <Box>
                        <P style={{fontSize:"25px",textAlign:"center"}} > 
                          {t("partners.title")}
                         </P>
                    </Box>
                </Grid> 
                <Grid 
                    item  
                    lg={3} 
                    xs={6}  
                    sx={{
                        display:'flex',
                        justifyContent:'center'
                    }}
                >
                        <Image style={{height:90}}  src={PoleEmploi} alt="Jobnux - Logo Google" />
                </Grid>
                <Grid 
                    item 
                    lg={3} 
                    xs={6} 
                    sx={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center'
                    }}
                >
                    <Image style={{width:160,height:30}}  src={Deloitte} alt="Jobgenius.ai - Logo Deloitte" />
                </Grid>
                <Grid 
                    item 
                    lg={3} 
                    xs={6} 
                    sx={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center'
                    }}
                >
                   <Image style={{width:160,height:30}} src={Lv} alt="Jobnux - Logo Louis Vuitton" />
                </Grid>
                <Grid 
                    item 
                    lg={3} 
                    xs={6} 
                    sx={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center'
                    }}
                >
                   <Image src={Nestle} alt="Jobnux. - Logo Nestlé" />
                </Grid>
            </Grid>
               
        </Container>
    </Content>
  )
}

export default Partners

import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import ActionBtnLink from '../buttons/ActionBtnLink'

function Bande({ title, content, btnTitle, link }) {
  return (
    <Box
      sx={{
        background: "#1371FF",
        height: { lg: 180, xs: 300 },
        marginBottom: 5,
        padding: 2
      }}
    >
      <Container sx={{ height: "100%" }} >
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="center"
          sx={{ height: "100%" }}
        >
          <Grid
            item
            lg={8}
            xs={12}
            display="flex"
            flexDirection="column"
            alignSelf="center"
            justifyContent="center"


          >
            <Typography
              variant="h4"
              sx={{
                textAlign: { lg: 'left', md: 'left', sm: 'center', xs: 'center' }
              }}
              color="#fff"
            >
              {title}
            </Typography>
            <Typography
              sx={{
                textAlign: { lg: 'left', md: 'left', sm: 'center', xs: 'center' }
              }}
              paragraph
              color="#fff"
            >
              {content}
            </Typography>

          </Grid>
          <Grid
            item
            lg={4}
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: { lg: 'flex-end', md: 'flex-end', xs: 'center' }
            }}
          >

            {
              btnTitle && (
                <ActionBtnLink
                  to={link}
                  title={btnTitle}
                />
              )
            }

          </Grid>
        </Grid>
      </Container>
    </Box>

  )
}

export default Bande
import { ADD_EDUCATION, DELETE_EDUCATION, UPDATE_EDUCATION } from "../constants/resumeConstants";

const initialState = {
  educations:[]
};



const educationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EDUCATION:
       // Construire un objet map avec uuid comme clé pour les items éducation existants
       const existingEducationsMap = state.reduce(
          (acc, education) => ({ ...acc, [education.id]: education }),
        {}
      );

      // Parcourir les nouveaux items et mettre à jour ou ajouter au state
      const updatedEducations = action.payload.map((newEducation) => {
        const { id } = newEducation;
        if (existingEducationsMap.hasOwnProperty(id)) {
          // Mettre à jour l'item existant avec l'uuid correspondant
          return { ...existingEducationsMap[id], ...newEducation };
        } else {
          // Ajouter le nouvel item s'il n'existe pas déjà
          return newEducation;
        }
      });

      return {
        ...state,
        educations: updatedEducations
      };
    case UPDATE_EDUCATION:
      return {
        ...state,
        educations: state.educations.map((education) =>
          education.id === action.payload.educationId ? action.payload.updatedEducation : education
        ),
      };
     case DELETE_EDUCATION:
      return {
        ...state,
        educations: action.payload.filter((_, i) => i !== action.payload),
      };
    default:
      return state;
  } 
};

export default educationReducer;

import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import { Box, Container, Grid,Button } from '@mui/material'
import Testemonials from '../cards/Testemonials'
import Slider from 'react-slick';
import { testemonialData } from '../../data/testemonialData'
import { Content,H1,P } from '../../styles/GlobalStyles'
import {  ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';




const CarouselContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
});

const CustomPrevArrow = styled(Button)({
  position: 'absolute',
  top: '50%',
  left: 0,
  zIndex:1,
  transform: 'translateY(-50%)',
  cursor:'pointer'
});

const CustomNextArrow = styled(Button)({
  position: 'absolute',
  top: '50%',
  right:30,
  height:50,
  width:50,
  transform: 'translateY(-50%)',
  zIndex:1,
  cursor:'pointer',
  
});

const SlickArrowLeft = ({currentSlide,slideCount,...props}) => (
  <CustomPrevArrow
    {...props}
    className={
      "slick-prev slick-arrow" + 
      (currentSlide === 0 ? "slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === 0 ? true : false}
    type="button"
  >
    <ArrowBackIos/>
  </CustomPrevArrow>
);


const SlickArrowRight = ({currentSlide,slideCount,...props}) => (
  <CustomNextArrow
    {...props}
    className={
      "slick-next slick-arrow" + 
      (currentSlide === slideCount - 1 ? "slick-disabled" : "")
    }
    aria-hidden="true"
    aria-disabled={currentSlide === slideCount - 1 ? true : false}
    type="button"
  >
    <ArrowForwardIos/>
  </CustomNextArrow>
)



const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow:3,
  slidesToScroll:1,
  initialSlide: 0,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    }, 
    {
      breakpoint: 778,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {  
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ],

  prevArrow:<SlickArrowLeft/>,
  nextArrow:<SlickArrowRight/>
  
};

function SectionTestemonials() {
  const { t:sections } = useTranslation('sections');
  const { t:testemonials } = useTranslation('testTemonialData');

 const [data,setData] = useState([])

 useEffect(() => {
   setData(testemonialData)
 
   return () => {
     setData([])
   }
 }, [])
 
  return (
    <>
    <Content  
        size="50vh" 
        color="#fff" 
        viewport={{once:true}}
        initial={{opacity:0,y:150}}
        whileInView={{opacity:1,y:0}}
        transition={{type:"easeIn", duration:1,delay:0 }}
      >
        <Container 
          maxWidth={false} 
        >
            <Grid container mb={10}   >
                <Grid item lg={12} >
                    <Box>
                        <H1  
                            style={{
                            color:"rgba(0, 0, 0, 0.7)",
                            textAlign:"center",
                            width:'100%'
                            }} >
                               {sections("sectionTestemonials.title-1")}
                                <span style={{color:'#1371FF'}} >
                                {sections("sectionTestemonials.title-2")}
                                </span> 
                                {sections("sectionTestemonials.title-3")}
                        </H1>
                        <P  
                         style={{
                            color:"rgba(0, 0, 0, 0.7)",
                            textAlign:"center"
                            }}
                        >
                         {sections("sectionTestemonials.content")}
                        </P>
                    </Box>
                </Grid>
             
            </Grid>
              <Box
                  sx={{
                      alignItems:'center',
                      justifyContent:'center',
                      display:'flex',
                      marginBottom:10
                      
                  }}
                >
              
                    <CarouselContainer>
                        <Slider 
                          {...settings}  
                          prevArrow={<SlickArrowLeft/>}
                          nextArrow={<SlickArrowRight/>}
                        
                        >
                          {
                            data.map((item)=>(
                                
                                    <Testemonials
                                        key={item.id}
                                        item={item}  
                                  />
                              
                            ))
                          }
                        </Slider>
                      
                      </CarouselContainer>
                </Box>
        </Container>
    </Content>
   
    </>
  )
}

export default SectionTestemonials

import Letter from './coverLetter.md'

export const LetterText = [
  {
    id: "644f0b5a4ddc010e7656cb1e",
    name: "popular",
    company:'Google',
    categorie: "marketing",
    type: "Resume",
    job: "community manager",
    content: "(123) 456-7891\n\n"+
            "cfredrickson@email.com\n\n"+
    
            "May 1, 2018\n\n"+
    
             "Dear Hiring Manager,\n\n"+
    
             "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
             "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
             "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
             "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
             "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
             "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
             "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
             "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
             "portfolio.\n\n"+

             "Sincerely,\n\n"+


            "Cody Fredrickson"

  },
  {
    id: "644f0b5ad3a1e24046cd5e0c",
    name: "popular",
    company:'Amazon',
    categorie: "supply chain",
    type: "letter of resignation",
    job: "business developper",
    content: "(123) 456-7891\n\n"+
            "cfredrickson@email.com\n\n"+
    
            "May 1, 2018\n\n"+
    
             "Dear Hiring Manager,\n\n"+
    
             "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
             "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
             "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
             "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
             "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
             "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
             "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
             "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
             "portfolio.\n\n"+

             "Sincerely,\n\n"+


            "Cody Fredrickson"

  },
  {
    id: "649f0b5ad2a1e24046cd5e0a",
    name: "popular",
    company:'Amazon',
    categorie: "cloud computing",
    type: "letter of resignation",
    job: "Ingeneer Cloud",
    content: "(123) 456-7891\n\n"+
            "cfredrickson@email.com\n\n"+
    
            "May 1, 2018\n\n"+
    
             "Dear Hiring Manager,\n\n"+
    
             "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
             "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
             "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
             "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
             "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
             "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
             "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
             "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
             "portfolio.\n\n"+

             "Sincerely,\n\n"+


            "Cody Fredrickson"

  },
  {
    id: "644f0b5ad3a1e24046cd5e0c",
    name: "popular",
    company:'Amazon',
    categorie: "design",
    type: "letter of resignation",
    job: "UI/UX Designer",
    content: "(123) 456-7891\n\n"+
            "cfredrickson@email.com\n\n"+
    
            "May 1, 2018\n\n"+
    
             "Dear Hiring Manager,\n\n"+
    
             "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
             "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
             "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
             "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
             "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
             "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
             "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
             "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
             "portfolio.\n\n"+

             "Sincerely,\n\n"+


            "Cody Fredrickson"

  },
  {
    id: "644f0b5aa187d300f5b4ccf9",
    name: "popular",
    company:'FaceBook',
    categorie: "marketing",
    type: "letter of resignation",
    job: "community manager",
    content: "(123) 456-7891\n\n"+
    "cfredrickson@email.com\n\n"+

    "May 1, 2018\n\n"+

     "Dear Hiring Manager,\n\n"+

     "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
     "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
     "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
     "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
     "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
     "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
     "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
     "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
     "portfolio.\n\n"+

     "Sincerely,\n\n"+


    "Cody Fredrickson"

  },
  {
    id: "644f0b5a8f4ff3e3d31c5715",
    name: "popular",
    company:'Atos',
    categorie: "business",
    type: "cover letter",
    job: "Community Manager",
    content: "(123) 456-7891\n\n"+
            "cfredrickson@email.com\n\n"+
    
            "May 1, 2018\n\n"+
    
             "Dear Hiring Manager,\n\n"+
    
             "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
             "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
             "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
             "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
             "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
             "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
             "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
             "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
             "portfolio.\n\n"+

             "Sincerely,\n\n"+


            "Cody Fredrickson"

  },
  {
    id: "644f0b5afc7b6ea9625d97d8",
    name: "popular",
    company:'SunSet',
    categorie: "design",
    type: "letter of resignation",
    job: "UX/UI Design",
    content: "(123) 456-7891\n\n"+
    "cfredrickson@email.com\n\n"+

    "May 1, 2018\n\n"+

     "Dear Hiring Manager,\n\n"+

     "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
     "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
     "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
     "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
     "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
     "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
     "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
     "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
     "portfolio.\n\n"+

     "Sincerely,\n\n"+


    "Cody Fredrickson"

  },
  {
    id: "644f0b5a856e0624cd020e91",
    name: "popular",
    company:'JobGenius',
    categorie: "web dev",
    type: "cover letter",
    job: "Web Developer",
    content: "(123) 456-7891\n\n"+
    "cfredrickson@email.com\n\n"+

    "May 1, 2018\n\n"+

     "Dear Hiring Manager,\n\n"+

     "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
     "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
     "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
     "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
     "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
     "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
     "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
     "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
     "portfolio.\n\n"+

     "Sincerely,\n\n"+


    "Cody Fredrickson"

  },
  {
    id: "640f0b5a856e0623cd020e91",
    name: "popular",
    company:'JobGenius',
    categorie: "developer",
    type: "cover letter",
    job: "DevOps",
    content: "(123) 456-7891\n\n"+
    "cfredrickson@email.com\n\n"+

    "May 1, 2018\n\n"+

     "Dear Hiring Manager,\n\n"+

     "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
     "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
     "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
     "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
     "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
     "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
     "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
     "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
     "portfolio.\n\n"+

     "Sincerely,\n\n"+


    "Cody Fredrickson"

  },
  {
    id: "640f0b5a856e0613cc020e91",
    name: "popular",
    company:'JobGenius',
    categorie: "web developer",
    type: "cover letter",
    job: "React Developer",
    content: "(123) 456-7891\n\n"+
    "cfredrickson@email.com\n\n"+

    "May 1, 2018\n\n"+

     "Dear Hiring Manager,\n\n"+

     "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
     "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
     "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
     "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
     "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
     "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
     "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
     "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
     "portfolio.\n\n"+

     "Sincerely,\n\n"+


    "Cody Fredrickson"

  },
  {
    id: "644f0b5a05be21c52adff7ab",
    name: "popular",
    company:'Sony',
    categorie: "web developer",
    type: "letter of resignation",
    job: "React Developer",
    content: "(123) 456-7891\n\n"+
            "cfredrickson@email.com\n\n"+
    
            "May 1, 2018\n\n"+
    
             "Dear Hiring Manager,\n\n"+
    
             "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
             "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
             "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
             "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
             "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
             "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
             "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
             "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
             "portfolio.\n\n"+

             "Sincerely,\n\n"+


            "Cody Fredrickson"

  },
  {
    id: "644f0b5ae94702d7941266c1",
    name: "popular",
    company:'Paul & José',
    categorie: "developer",
    type: "cover letter",
    job: "data scientist",
    content: "(123) 456-7891\n\n"+
            "cfredrickson@email.com\n\n"+
    
            "May 1, 2018\n\n"+
    
             "Dear Hiring Manager,\n\n"+
    
             "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
             "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
             "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
             "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
             "I'm excited to be applying for the Marketer position at Crane & Jenkins. As a marketing"+
             "manager with more than five years' experience at leading agencies, I've cultivated a talent"+ 
             "for developing creative and successful marketing strategies. I'm excited about combining my"+ 
             "skills and my desire to serve the community with Crane & Jenkins' extensive nonprofit client"+ 
             "portfolio.\n\n"+

             "Sincerely,\n\n"+


            "Cody Fredrickson"

  }
]
import React from 'react'
import { Container, Grid } from '@mui/material'
import { Content, H2} from './Elements'
import { Box } from '@mui/system'
import Number from '../../components/tools/Number'
import { useTranslation } from 'react-i18next'
 

function ResultsNumbers() {
   const { t:home } = useTranslation('homepage');
  return (
    <Content size="50vh" bgcolor="#FFF" >
       <Container>
          <Grid container spacing={3} >
            <Grid  item lg={12} xs={12}>
                 <Box mb={10} >
                    <H2 style={{textAlign:"center"}}> {home("sections.numbers.numbers.h2")} </H2> 
                </Box>
            </Grid>
            <Grid item lg={2} xs={4}> 
                <Number
                  color="#A4A5AA"
                  sign="+" 
                  number={150}
                  indice="K+"
                  subtitle={home("sections.numbers.numbers.1")} 
                />
            </Grid>
            <Grid item lg={2}  xs={4}>
               <Number
                  color="#A4A5AA"
                  sign=""
                  number={98}
                  indice="%"
                  subtitle={home("sections.numbers.numbers.2")} 
                />
            </Grid>
            <Grid item lg={3}  xs={4}>
               <Number
                  color="#A4A5AA"
                  sign="+"
                  number={12}
                  indice="K"
                  subtitle={home("sections.numbers.numbers.3")} 
                />
            </Grid>
            <Grid item lg={2}  xs={4}>
               <Number
                  color="#A4A5AA"
                  sign=""
                  number={14}
                  indice="K+"
                  subtitle={home("sections.numbers.numbers.4")} 
                />
            </Grid>
            <Grid item lg={2}  xs={4}>
               <Number
                  color="#fff"
                  sign="+"
                  number={190}
                  indice="K+"
                  subtitle={home("sections.numbers.numbers.5")} 
                />
            </Grid>
          </Grid>
       </Container>
    </Content>
  )
}

export default ResultsNumbers

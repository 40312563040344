import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import styled from 'styled-components';



const H1 = styled.h1`
font-size:17px;
font-weight:bold;

@media screen and (max-width: 500px){
    font-size:12px
  }
`

const P = styled.p`
font-size:15px;
color:#5C5C5C;

@media screen and (max-width: 500px){
    font-size:10px
  }
`

const Span = styled.span`
background-color:${props=>props.item.bgColor};
width:41px;
height:42px;
display:flex;
align-items:center;
justify-content:center;
text-align:center;
color:${props=>props.item.iconColor};
border-radius:10px;
font-size:30px;

@media screen and (max-width: 500px){
    width:21px;
    height:22px;
    font-size:10px;
    border-radius:5px;

  }
`

export default function FeatureCard({item}) {

 

  return (
    <Card sx={{ 
        maxWidth: 330,
        height:206,
        borderRadius:5,
        boxShadow: "7px 4px 30px rgba(200, 200, 200, 0.49)"
    }}>
      <CardHeader
        avatar={
          <Span item={item}>
            {item.icon}
          </Span>
        }
        title={
        <H1> 
           {item.title} 
        </H1>}
      />
      <CardContent>
        <P>
          {item.description}
        </P>
      </CardContent>
    </Card>
  );
}
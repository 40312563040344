import React from 'react'
import { planData } from '../../data/planData';
import { MotionGrid, PlanUl } from '../../styles/GlobalStyles';
import * as AiIcons from 'react-icons/ai'
import PricingTable from '../../components/pricing'



function Plan() {
  return (
    <MotionGrid item container>
        <MotionGrid  item lg={5} >
            <PlanUl>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                    <li>
                    <span style={{fontSize:14}}>Cover Letter Generations </span>
                    <span> <AiIcons.AiFillInfoCircle color="#9C9C9C" />  </span>
                    </li>
                    <li>
                    <span style={{fontSize:14}}  >Adding poste information to personalize the cover letter</span>
                    <span> <AiIcons.AiFillInfoCircle color="#9C9C9C" /> </span>
                    </li>
                    <li>
                    <span style={{fontSize:14}}>Job interviews Simulator</span>
                    <span> <AiIcons.AiFillInfoCircle color="#9C9C9C" /> </span>
                    </li>
                    <li>
                    <span style={{fontSize:14}}>Syntaxique Corrector</span>
                    <span> <AiIcons.AiFillInfoCircle color="#9C9C9C" /> </span>
                    </li>
                    <li>
                    <span style={{fontSize:14}}>Job Description</span>
                    <span> <AiIcons.AiFillInfoCircle color="#9C9C9C" /> </span>
                    </li>
                    <li>
                    <span style={{fontSize:14}}>Letter of resignation</span>
                    <span><AiIcons.AiFillInfoCircle color="#9C9C9C" /></span>
                    </li>
                    <li>
                    <span style={{fontSize:14}}>Account manager</span>
                    <span> <AiIcons.AiFillInfoCircle color="#9C9C9C" /> </span>
                    </li>
                    <li>
                    <span style={{fontSize:14}}>Job Interview Coach</span>
                    <span> <AiIcons.AiFillInfoCircle color="#9C9C9C" /> </span>
                    </li>
                
            </PlanUl>
            </MotionGrid>
            <MotionGrid item  container spacing={2} lg={7} >
                {
                    planData.map((values)=>(
                        <MotionGrid 
                            item 
                            lg={4} md={6} xs={6} 
                            key={values.id} 
                            display="flex"
                            justifyContent="center"
                        >
                            <PricingTable  item={values} />
                        </MotionGrid> 
                    )) 
                }
            </MotionGrid>
        </MotionGrid> 
  )
}

export default Plan
import React from 'react'
import { Button } from '@mui/material';




export default function ActionButton(props) {

    const { color, children, onClick,variant,fullWidth,startIcon } = props;

    return (
        <Button
            onClick={onClick}
            variant={variant}
            fullWidth={fullWidth}
            startIcon={startIcon}
            color={color}
            size="large"
            sx={{
                borderRadius:2,
                textTransform:"none",
                color:"#000",
                border:'1px solid #D0D0D0',
                fontWeight:"400"
            }}
        >
            {children}
        </Button>
    )
}  
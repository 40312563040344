import React from 'react'
import { Content, Liste, MainContainer, MainTitle1, MotionGrid } from '../../styles/GlobalStyles'
import { Box, Container, Grid } from '@mui/material'

function Banner({image,size}) {
  return (
    <>
        <Content
            size={size}
            bgcolor="#fff"
            style={{
                backgroundImage:`url(${image}) `,
                backgroundRepeat:'no-repeat',
                backgroundPosition:"center",
                backgroundPositionY:"center",
                backgroundSize:"cover",
            }}
        >
           <Container>
                <MotionGrid container >
                    <Grid item lg={12} >
                        <Box>
                           <h1 style={{textAlign:'left'}} > All you need for getting hired </h1> 
                           <Liste>
                                <li>180+ <span>Resignation</span> Letter Templates</li>
                                <li>180+ <span>Cover Letter</span> Templates</li>
                                <li>500+ <span>Resume</span> Templates</li>
                           </Liste>
                           <p>Supercharge your job search with JobGenius templates. Need more help? </p>
                        </Box>
                    </Grid>
                </MotionGrid>
           </Container>
        </Content>
    </>
  )
}

export default Banner
import { 
    CREATE_SIMULATION, 
    CREATE_SIMULATION_FAIL, 
    CREATE_SIMULATION_SUCCESS, 
    DELETE_SIMULATION, 
    DELETE_SIMULATION_FAIL, 
    DELETE_SIMULATION_SUCCESS, 
    GET_CURRENT_SIMUALTION, 
    GET_CURRENT_SIMULATION_FAIL, 
    GET_CURRENT_SIMULATION_SUCCESS, 
    GET_USER_SIMUALTIONS, 
    GET_USER_SIMULATIONS_FAIL, 
    GET_USER_SIMULATIONS_SUCCESS, 
    UPDATE_SIMULATION,
    UPDATE_SIMULATION_FAIL,
    UPDATE_SIMULATION_STEP_1,
    UPDATE_SIMULATION_STEP_2,
    UPDATE_SIMULATION_SUCCESS
} from "../constants/appConstants";


const initialState={
  stepsData : {
    userId:"",
    botId:"",
    jobTitle:"",
    companyName:"",
    typeOfJob:"",
    audioResponse:false,
    resumeProfile:false,
    jobDescription:"",
    minutes:0,
    seconds:0
  }
}


function addSimulationReducer(state = {}, action) {
    switch (action.type) {
      case CREATE_SIMULATION:
        return { loading: true };
      case CREATE_SIMULATION_SUCCESS:
        return { loading: false, simulationInfoCreated: action.payload };
      case CREATE_SIMULATION_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

  function listUserSimulationsReducer(state = {}, action) {
    switch (action.type) {
      case GET_USER_SIMUALTIONS:
        return { loading: true };
      case GET_USER_SIMULATIONS_SUCCESS:
        return { loading: false, userSimulationsListed: action.payload };
      case GET_USER_SIMULATIONS_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }



  function updateSimulationReducer(state = {}, action) {
    switch (action.type) {
      case UPDATE_SIMULATION:
        return { loading: true };
      case UPDATE_SIMULATION_SUCCESS:
        return { loading: false, simulationInfoUpdated: action.payload };
      case UPDATE_SIMULATION_FAIL:
        return { loading: false, error: action.payload };
      default: return state;
    }
  }

  function  currentSimulationReducer(state = {}, action) {
    switch (action.type) {
      case GET_CURRENT_SIMUALTION:
        return { loading: true };
      case GET_CURRENT_SIMULATION_SUCCESS:
        return { loading: false, currentSimulationInfo: action.payload };
      case GET_CURRENT_SIMULATION_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }


  function  deleteSimulationReducer(state = {}, action) {
    switch (action.type) {
      case DELETE_SIMULATION:
        return { loading: true };
      case DELETE_SIMULATION_SUCCESS:
        return { loading: false, simulationInfoDeleted: action.payload };
      case DELETE_SIMULATION_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }

  function stepsSimulationReducer(state = initialState , action){
    switch(action.type){
      case UPDATE_SIMULATION_STEP_1:
        return {
          ...state,
          stepsData:{
            ...state.stepsData,
            ...action.payload
          },
        };
      case UPDATE_SIMULATION_STEP_2:
        return {
          ...state,
          stepsData:{
            ...state.stepsData,
            ...action.payload
          },
        };
      default:
        return state;
    }
  }

  export {
    addSimulationReducer,
    updateSimulationReducer,
    deleteSimulationReducer,
    currentSimulationReducer,
    stepsSimulationReducer,
    listUserSimulationsReducer
  }
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next'


import enNavigationBars from '../locales/en/navigationbars.json'
import frNavigationBars from '../locales/fr/navigationbars.json'

import enHomePage from '../locales/en/pages/home.json'
import enResignationLetterPage from '../locales/en/pages/resignation.json'
import enCoverLetterPage from '../locales/en/pages/coverLetter.json'
import enBusinessCreatorPage from '../locales/en/pages/businessCreator.json'
import enResumeCreatorPage from '../locales/en/pages/resume.json'
import enCoachPage from '../locales/en/pages/coach.json'
import enSimulationPage from '../locales/en/pages/jobSimulator.json'
import enFaqPage from '../locales/en/pages/faq.json'
import enAboutUsPage from '../locales/en/pages/abouts.json'
import enExamplePage from '../locales/en/pages/example.json'
import enContactPage from '../locales/en/pages/contactUs.json'
import enPage404Page from '../locales/en/pages/page404.json'

import enSections from '../locales/en/components/sections.json'
import enAiDrivenData from '../locales/en/data/aiDrivenData.json'
import enCoachData from '../locales/en/data/coachData.json'
import enFaqData from '../locales/en/data/faqData.json'
import enFeatureData from '../locales/en/data/featureData.json'
import enTestemonialData from '../locales/en/data/testemonialData.json'


import frHomePage from '../locales/fr/pages/home.json'
import frResignationLetterPage from '../locales/fr/pages/resignation.json'
import frCoverLetterPage from '../locales/fr/pages/coverLetter.json'
import frBusinessCreatorPage from '../locales/fr/pages/businessCreator.json'
import frResumeCreatorPage from '../locales/fr/pages/resume.json'
import frCoachPage from '../locales/fr/pages/coach.json'
import frSimulationPage from '../locales/fr/pages/jobSimulator.json'
import frFaqPage from '../locales/fr/pages/faq.json'
import frAboutUsPage from '../locales/fr/pages/abouts.json'
import frExamplePage from '../locales/fr/pages/example.json'
import frContactPage from '../locales/fr/pages/contactUs.json'
import frPage404Page from '../locales/fr/pages/page404.json'

import frSections from '../locales/fr/components/sections.json'
import frAiDrivenData from '../locales/fr/data/aiDrivenData.json'
import frCoachData from '../locales/fr/data/coachData.json'
import frFaqData from '../locales/fr/data/faqData.json'
import frFeatureData from '../locales/fr/data/featureData.json'
import frTestemonialData from '../locales/fr/data/testemonialData.json'




//Les traductions - the translations

const resources = {
    en:{
        navigationbars : enNavigationBars,
        homepage:enHomePage, 
        coachPage:enCoachPage,
        resigantionPage:enResignationLetterPage ,
        coverLetterPage:enCoverLetterPage ,
        businessCreatorPage:enBusinessCreatorPage, 
        resumeCreatorPage:enResumeCreatorPage,
        simulationPage:enSimulationPage ,
        faqPage:enFaqPage , 
        aboutUsPage:enAboutUsPage,  
        examplePage:enExamplePage ,
        contactPage:enContactPage,
        page404Page:enPage404Page ,
        sections:enSections ,
        enAiDrivenData:enAiDrivenData, 
        coachData:enCoachData ,
        faqData:enFaqData ,
        featureData:enFeatureData, 
        testTemonialData:enTestemonialData
    },
    fr:{
        navigationbars:frNavigationBars,
        homepage:frHomePage, 
        coachPage:frCoachPage,
        resigantionPage:frResignationLetterPage ,
        coverLetterPage:frCoverLetterPage ,
        businessCreatorPage:frBusinessCreatorPage, 
        resumeCreatorPage:frResumeCreatorPage,
        simulationPage:frSimulationPage ,
        faqPage:frFaqPage ,
        aboutUsPage:frAboutUsPage, 
        examplePage:frExamplePage ,
        contactPage:frContactPage,
        page404Page:frPage404Page ,
        sections:frSections ,
        frAiDrivenData:frAiDrivenData, 
        coachData:frCoachData ,
        faqData:frFaqData ,
        featureData:frFeatureData, 
        testTemonialData:frTestemonialData
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng:"en",
        debug:true,
        fallbackLng:'en',
        interpolation:{
            escapeValue:false
        },
        ns:"translation",
        defaultNs:"translation"
    });

    export default i18n;

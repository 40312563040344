// languageReducer.js

import {ADD_LANGUAGE, DELETE_LANGUAGE, UPDATE_LANGUAGE,  } from "../constants/resumeConstants";



const initialState = {
  languages: [],
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LANGUAGE:
      // Construire un objet map avec uuid comme clé pour les items  existants
      const existingLanguagesMap = state.languages.reduce(
        (acc, language) => ({ ...acc, [language.uuid]: language }),
        {}
      );

      // Parcourir les nouveaux items et mettre à jour ou ajouter au state
      const updatedLanguages = action.payload.map((newLanguage) => {
        const { uuid } = newLanguage;
        if (existingLanguagesMap.hasOwnProperty(uuid)) {
          // Mettre à jour l'item existant avec l'uuid correspondant
          return { ...existingLanguagesMap[uuid], ...newLanguage };
        } else {
          // Ajouter le nouvel item s'il n'existe pas déjà
          return newLanguage;
        }
      });

      return {
        ...state,
        languages: updatedLanguages
      };
   
    case UPDATE_LANGUAGE:
      return {
        ...state,
        languages: state.languages.map((language) =>
          language.id === action.payload.languageId ? action.payload.updatedLanguage : language
        ),
      };
    case DELETE_LANGUAGE:
      return {
        ...state,
        languages: action.payload.filter((_, i) => i !== action.payload),
      };
    default:
      return state;
  }
};

export default languageReducer;
  
import React from 'react'
import { Card } from '@mui/material'
import styled from 'styled-components'


const Image = styled.img`
 width:100%;
 height:auto
`

function CurriculumCard({image}) {
  return (
    <Card
        sx={{
        width:400,
        display:'flex',
        justifyContent:'center',
        textAlign:'center'
        }}
     >
        <Image  
            src={image}
            alt="JobGenius -AI - Template CV"
        />
      
   </Card>
  )
}

export default CurriculumCard
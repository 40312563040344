import Image from '../images/photo.png'



export const testemonialData = [{
    "id": "6404d481d54ad6a7a9c8fced",
    "title": "Chesay Smith - HR recruitment",
    "description":"I used the AI Cover Letter Creator to apply for a job and got hired! It was professional and customized to the company, it really helped me stand out from other applicants.",
    "image": Image
  },{
    "id":  "6404d4c2d15091b4865e7ee9",
    "title": "Vanta Jonsohn - Data scientist",
    "description":"This’s a great tool, it allowed me to create a professional cover letter in a matter of minutes. I highly recommend this service to all job seekers",
    "image": Image
  },{
    "id": "6404d529d23e4b02da0be8b7",
    "title": "Rona Aquibo",
    "description":"I used coverlet.ai to apply for multiple jobs and got interviews for all of them. It's an amazing tool that saved me a lot of time and effort in writing my cover letter",
    "image": Image
  },{
    "id":"6404d54dddb2229w4f6f4a53",
    "title": "Noel Chang",
    "description":"I was skeptical about using an AI tool for my cover letter, but I was pleasantly surprised with the results. The letter was well-written and customized to the job I applied for. I highly recommend this service",
    "image": Image
  }
]
export const sidebar = {
    width: '1rem',
    height: '100vh'
  }
  
  export const header = {
    width: `calc(100vw - ${sidebar.width})`,
    height: '4.5rem'
  }
  
  export const footer = {
    width: '100vw',
    height: '2.5rem'
  }
  
  export const formSection = {
    width: '30%',
    height: '100vh'
  }
  
  export const previewSection = {
    width: '50%',
    height: '100vh'
  }
  
  export const templatesSection = {
    width: '20%'
  }
  
  export const card = {
    width: '85%'
  }
import { axiosInstance, axiosInstanceUploade } from ".";


export const GetStripePlans = async () => {
    const response = await axiosInstance('get',"/subscription/plans/");
    return response;   
} 

export const SubscribeToStripe = async (payload) => {
    const response = await axiosInstance("post",'/subscription/create-checkout-session',payload);
    return response;
    
} 

export const GetStripeCustomerByID = async (id) => {
    const response = await axiosInstance('get',"/subscription/get-customer/"+id);
    return response;
}

export const CreateStripeCustomer = async (payload) => {
    const response = await axiosInstance('post',"/subscription/create-customer/",payload);
    return response;
}

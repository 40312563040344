import React from 'react';
import  styled from 'styled-components';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LargeButton } from '../../styles/GlobalStyles';
import Melchior from '../../images/melo1.png'
import { useTranslation } from 'react-i18next';
import { colors } from '../../styles';
import CallToActionBtn from '../buttons/CallToActionBtn';



const GridStyled = styled(motion(Grid))`

background: #1371FF;
border-radius: 20px;

    h1{
        font-size: 30px;
        text-align: center;
        color: #FFFFFF;  
        padding:5px;
    }

    p{
      color: #FFFFFF; 
      padding:5px;
      text-align:center;
    }

    h1{
      font-weight: 700;
      font-size: 30px;
      text-align: center;
      color: #FFFFFF;
    }
`;

const Image = styled.img`
  width: 149px;
  height: 137px;
`





function Join() {
  const { t } = useTranslation('sections');


  return (
    <GridStyled pb={2}  container>

            <GridStyled  
              item 
              lg={3} 
              xs={12}
              style={{
                display:"flex",
                justifyContent:'center',
                alignItems:"center",
              }}
            
            >
                <Image src={Melchior} alt='OBAME OBAME MELCHIO - JOBNUX FOUNDER AND CEO OF JOBNUX'  />
            </GridStyled>

            <GridStyled container  item lg={6} xs={12}>
                <GridStyled  item lg={12} xs={12}>
                        <h1>
                          {t("joinSection.title")}
                        </h1>
                </GridStyled>
                <GridStyled  item lg={12} xs={12}>
                      <p>
                      {t("joinSection.description")}
                      </p>
                </GridStyled>
                <GridStyled style={{display:"flex",justifyContent:"center"}}  item lg={12} xs={12}>
                    <CallToActionBtn
                      title={t("joinSection.buttonText")}
                      variant="contained"
                      background={colors.green}
                      color={colors.white}
                      hoverColor={colors.white}
                      link={t("joinSection.link")}
                    />
                </GridStyled>
            </GridStyled>

            <GridStyled  item lg={3} xs={12}>
            </GridStyled>


    </GridStyled>
  )
}

export default Join
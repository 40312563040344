import React, { useEffect, useState } from 'react'
import { Content, H1 } from './Elements'
import { Container, Grid} from '@mui/material'
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { AiDrivenData } from '../../data/aiDrivenData';
import SimpleMediaCard from '../../components/cards/SimpleMediaCard';

const Move = styled(motion(Grid))`
`
function AiDriven({translator}) {

    const [data, setData] = useState([]);

    useEffect(() => {
      setData(AiDrivenData)
    
      return () => {
        setData([])
      }
    }, [])
    
  return (
    <Content size="100vh" bgcolor="#fff" >
         <Container   >
                <Grid mt={10} mb={10} container spacing={2} >
                     <Move 
                      item 
                      lg={12}
                      xs={12} 
                      pr={2} 
                      viewport={{once:true}}
                      initial={{opacity:0,y:100}}
                      whileInView={{opacity:1,y:0}}
                      transition={{type:"easeIn", duration:1,delay:0 }}

                     >
                            <H1 style={{fontSize:20,textAlign:'center'}}>
                                {translator("sections.aiDriven.title")}
                            </H1>
                           
                     </Move>
                     <Move 
                        container 
                        spacing={2} 
                        item 
                        lg={12}
                        alignItems="center"
                        viewport={{once:true}}
                        initial={{opacity:0,y:100}}
                        whileInView={{opacity:1,y:0}}
                        transition={{type:"easeIn", duration:1,delay:0 }}
                     >
                    
                            {data.map((item)=>(

                                <Grid item lg={4} sm={6} md={4} xs={12}  key={item.id} >
                                    <SimpleMediaCard item={item} />
                                </Grid>

                            ))}
                       
                     </Move>
                </Grid>
         </Container>
    </Content>
  )
}

export default AiDriven

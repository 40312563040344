import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import HeroBanner from '../../components/HeroSections/HeroBanner'
import BannerImage from '../../images/imageBusiness.png'
import Partners from '../../components/sections/Partners'
import SectionTestemonials from '../../components/sections/SectionTestemonials'
import SectionStep from '../../components/sections/SectionStep'
import MainHeroTitle from '../../components/HeroSections/MainHeroTitle'
import Image1 from '../../images/Prez1.png'
import Business2 from '../../images/imageBusiness2.png'
import Bande from '../../components/shared/Bande'
import SingleTestemonial from '../../components/sections/SingleTestemonial'
import SectionFaq from '../../components/sections/SectionFaq'
import SectionFlex from '../../components/sections/SectionFlex'
import { colors } from '../../styles'
import { Box, Grid, Typography } from '@mui/material'
import { H1 } from '../../styles/GlobalStyles'
import { useTranslation } from 'react-i18next'



function BusinessFlexOne({ link, translator }) {
  return (
    <Grid
      container
      display='flex'
      alignItems="center"
    >

      <Box>
        <Typography
          textAlign="justify"
          component="p"
          variant="body1"
          fontSize="18px"
          paragraph
        >
          {translator("sectionFlex.content1-1")}
          <span style={{ fontWeight: 'bold' }} >
            {translator("sectionFlex.content1-2")} 
          </span>
            {translator("sectionFlex.content1-3")}
          <span style={{ color: `${colors.primary}`}} >
           {translator("sectionFlex.content1-4")}
          </span>
          {translator("sectionFlex.content1-5")}
        </Typography>

        <Typography
          textAlign="justify"
          component="p"
          variant="body1"
          fontSize="18px"
          mt={2}
        >
         {translator("sectionFlex.content2")}
        </Typography>
      </Box>

    </Grid>
  )
}

function BusinessGenerator() {

  const { t } = useTranslation('businessCreatorPage');

  useEffect(() => {

    window.scrollTo({
      top: 0
    })

  }, [])

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("title")}</title>
        <meta name="description" content={t("description")} />
      </Helmet>
      <HeroBanner
        title={t("banner.title")}
        paragraph={t("banner.content")}
        image={BannerImage}
        btnTitle={t("banner.btnTitle")}
        link={t("banner.link")}
      />

      <Partners />
      <SectionFlex
        translation={false}
        alt="Jobnux.io - AI Business creator"
        content={<BusinessFlexOne translator={t} />}
        size="50vh"
        bgcolor={colors.gray}
        containerColor={colors.gray}
        image={Business2}
      />
      <MainHeroTitle
        subTitle={t("heroTitle.subTitle")}
        title={t("heroTitle.title")}
        paragraph={t("heroTitle.content")}
      />
      <SectionStep
        step={t("steps.one.step")}
        title={t("steps.one.title")}
        description={t("steps.one.content")}
        btnTitle={t("steps.one.btnTitle")}
        link={t("steps.one.link")}
        image={Image1}
      />
      <SectionStep
         step={t("steps.two.step")}
         title={t("steps.two.title")}
         description={t("steps.two.content")}
         btnTitle={t("steps.two.btnTitle")}
         link={t("steps.two.link")}
        image={Image1}
      />
      <SectionStep
        step={t("steps.three.step")}
        title={t("steps.three.title")}
        description={t("steps.three.content")}
        btnTitle={t("steps.three.btnTitle")}
        link={t("steps.three.link")}
        image={Image1}
      />
      <SectionTestemonials />
      <Bande
        title={t("bande.title")}
        content={t("bande.content")}
        btnTitle={t("bande.btnTitle")}
        link={t("bande.link")}
      />
      <SingleTestemonial />

      <SectionFaq />

    </div>
  )
}

export default BusinessGenerator
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Content, MotionGrid } from '../../styles/GlobalStyles'
import { Container, Grid, Typography } from '@mui/material'
import LoginForm from '../../components/forms/authentication/LoginForm'
import styled from 'styled-components'
import Img from '../../images/Rectangle77.png'
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserInfo, signin } from '../../redux/actions/userActions'
import { toast } from 'react-toastify';
import { LoginUser } from '../../apiCalls/users'
import { useNavigate } from 'react-router-dom' 

 


const Image = styled.img`
  width:100%;
  height:auto
`



function Login() {

  const userSignin = useSelector(state => state.userSignin);
  const {loading,userInfo, error} = userSignin;
  const currentUser = useSelector(state => state.currentUser);
  const { userInfo:currentUserInfo} = currentUser;
  const dispatch = useDispatch();
  const navigate = useNavigate()


  const handleSignin = async  (obj) => {
    try {
      /*toast.info("Web site in building", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });*/
         // Supprimer l'ancien token
         localStorage.removeItem('token');
         await dispatch(signin(obj)); 
         if(userInfo?.success){
            localStorage.setItem('token',userInfo.data)
            await dispatch(getCurrentUserInfo()); 
            toast.success(userInfo?.message, {
                position: "top-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
             navigate("/app");

         }else{
          toast.error(userInfo.message, {
            position: "top-center",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
         }
      
    } catch (error) {
    
      console.log(error)
    }
  
  }

  const verifyTokenExpired = () => {
    if(currentUserInfo?.success === true){
       navigate('/app')
    }
  }

  useEffect(() => {
    verifyTokenExpired()
  }, [])

  useEffect(() => {
   
    window.scrollTo({
        top:0
    })
}, [])
  
  return (
    <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login | Jobnux</title>
        </Helmet>
        <Content>
            <Container>
                <Grid container spacing={1}  >
                    <MotionGrid p={2} container item lg={6} md={6} sm={6} xs={12}  sx={{display:{sm:'block',xs:'none'}}}>
                        <MotionGrid mt={2} item lg={12} xs={12}>
                            
                            <Typography>
                                    The ultimate career tool. Our AI-powered 
                                    platform offers personalized interview simulation, job search support, 
                                    cover letter builder and more- all in one place.
                            </Typography>
                        </MotionGrid>
                        
                         <Image
                            src={Img}
                            alt="Jobnux - Login - Chidren"
                         />
                    </MotionGrid>
                    <MotionGrid item lg={6} sm={6} xs={12} md={6}>
                          <LoginForm
                             handleSignin={handleSignin}
                          />
                    </MotionGrid>
                </Grid>
            </Container>
        </Content>
    </div>
  )
}

export default Login
import {BrowserRouter as Router,Route,Routes,} from 'react-router-dom'
import "@uiw/react-md-editor/markdown-editor.css";
import Home from './public-pages/home';
import Pricing from './public-pages/pricing';
import Resume from './public-pages/resume/Resume';
import Faq from './public-pages/faq';
import AboutUs from './public-pages/aboutUs';
import ContactUs from './public-pages/contactUs';
import Terms from './public-pages/terms';
import Example from './public-pages/example';
import CoverLetter from './public-pages/CoverLetter';
import BusinessGenerator from './public-pages/Business/BusinessGenerator';
import JobInterviewSimulator from './public-pages/JobSimulator';
import JobInterviewCoach from './public-pages/JobCoach/JobInterviewCoach';
import Resignation from './public-pages/Resignation';
import JobDescription from './public-pages/JobDescription/JobDescription';
import DefaultLayout from './layouts/DefaultLayout';
import Login from './public-pages/authentication/Login';
import Register from './public-pages/authentication/Register';
import Newsletter from './public-pages/newsletter';
import SimpleLayout from './layouts/SimpleLayout';
import Page404 from './public-pages/page404';
import './App.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function App() {

 
  return (
       <>
       <Router>
          <Routes>
              <Route element={<DefaultLayout/>}  >
                  <Route index  element={<Home/>} />
                  <Route path='/pricing'  element={<Pricing/>} />
                  <Route path='/faq'  element={<Faq/>} />
                  <Route path='/about-jobnux'  element={<AboutUs/>} />
                  <Route path='/example'  element={<Example/>} />
                  <Route path='/ai-cover-letter-generation'  element={<CoverLetter/>} />
                  <Route path='/ai-business-generation'  element={<BusinessGenerator/>} />
                  <Route path='/ai-job-interview-simulation'  element={<JobInterviewSimulator/>} />
                  <Route path='/ai-career-coach'  element={<JobInterviewCoach/>} />
                  <Route path='/ai-resignation-letter-generator'  element={<Resignation/>} />
                  <Route path='/ai-online-cv-creation'  element={<Resume/>} />
                  <Route path='/contact-jobnux'  element={<ContactUs/>} />
                  <Route path='/term-and-privacy'  element={<Terms/>} />
                  {/* <Route path='/job-description'  element={<JobDescription/>} />*/}
                  {/*<Route path='/login'  element={<Login/>} />*/}
                  {/*<Route path='/register'  element={<Register/>} />*/}
                  <Route path='/signin'  element={<Login/>} />
                 <Route path='/signup'  element={<Register/>} />
                  <Route path='/login'  element={<Newsletter/>} />
                  <Route path='/register'  element={<Newsletter/>} />
                  <Route path='/newsletter'  element={<Newsletter/>} />
               </Route>
               <Route element={<SimpleLayout/>}>
                    <Route index  element={<Home/>} />
                    <Route path='*'  element={<Page404/>} />
               </Route>
             
          </Routes>
       </Router>
       <ToastContainer/>
       </>
  );
}

export default App;

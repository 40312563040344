import React, { useState, useEffect } from 'react'
import { Box, Drawer, IconButton, Toolbar, Typography } from '@mui/material'
import Container from '@mui/material/Container'
import { styled as styledMui } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Mainmenu from './Menu';
import { getMenuItems } from './menuItems'; // Importez la fonction getMenuItems
import LanguageSelector from '../../locales/LanguageSelector';
import { useTranslation } from 'react-i18next';
import { colors } from '../../styles';
import { getSubMenuItems } from './subMenuItems';




const SidebarLink = styled(Link)` 
  display: flex;
  color: #000; 
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  list-style: none;
  height:30px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  background:#fff;
  &:hover {
    background:#1371FF;
    cursor: pointer;
    color: #fff;
  }
`;

const SidebarLabel = styled.span`
    margin-left:10px;
`;

const DropdownLink = styled(Link)`
  background:#1371FF; 
  height:50px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color:#fff;
  font-size:12px;

  &:hover{
    background:#616161;
    cursor: pointer;
    color: #fff;
  }

`;

const NavBar = styled.div`
  position:sticky;
  top:0;
  z-index:1999;
  background-color:#fff;
  border-bottom: 1px solid #DADADA;
`;

const MenuBox = styled.div`
  display:flex;
  height:60px;
  position:relative;
  justify-content:center;
  align-items:center;
  zIndex:99;
`;


const ContainerLogo = styled.div`
  max-width: 250px;
  padding: 0 10px;
  overflow: hidden;
`;

const Logo = styled.img`
   width:240px;
   height:35px
`;

const LogoLink = styled(Link)`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 60px;
    text-decoration:none;
`;





const ToolBar = styledMui(Toolbar)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  background: '#FFFFFF',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
}));



const MenuSub = ({ item, handleClick }) => {

  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>

      <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
        <div>
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink to={item.path} key={index} onClick={handleClick} >
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  )
} 

 
function Navbar() {

  
  const { t } = useTranslation('navigationbars');
  //console.log("t=>",t) // Utilisez useTranslation comme précédemment
  const MenuItems = getMenuItems(t); 
  const SubMenuItems = getSubMenuItems(t);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  const [state, setState] = useState({
    toggleMenu: false,
    toggleMenuOpen: false
  });



  const { toggleMenu, toggleMenuOpen } = state;




  const displayToggleMenu = () => {

    const handleToggleMenuOpen = () => setState((prevState) =>
      ({ ...prevState, toggleMenuOpen: true }))


    const handleToggleMenuClose = () => setState((prevState) =>
      ({ ...prevState, toggleMenuOpen: false }))


    return (
      <ToolBar>
        <Box component="div"  sx={{display:'flex',justifyContent:'space-between',width:'100%'}} >
          <Box component="div">
            <LogoLink
              to="/"
            >
              {/*<Logo src={LogoImg} alt="Jobnux.io - Logo " />*/}
              <Typography sx={{
                fontWeight:"bold",
                fontSize:"20px"
              }} color={`${colors.primary}`}>
                Jobnux.io
              </Typography>
            </LogoLink>
          </Box>
          <Box component="div">
            <IconButton
              {...{
                onClick: handleToggleMenuOpen
              }}
              style={{
                color: 'white',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >

              <MenuIcon fontSize='large' sx={{ color: '#707173' }} />
            </IconButton>
          </Box>
         
         
        </Box>
        
        <Drawer
          {...{
            anchor: 'left',
            open: toggleMenuOpen,
            onClose: handleToggleMenuClose
          }}
        >
          <Box
            mt={10}
          >
            {SubMenuItems.map((item, index) => {
              return <MenuSub handleClick={handleClick} item={item} key={index} />;
            })
            }
          </Box>
        </Drawer>
      </ToolBar>
    )
  }


  const displayLargeMenu = () => {

  
    return (
      <ToolBar>
        <ContainerLogo>
          <LogoLink
            to="/"
          >
            {/*<Logo src={LogoImg} alt="Jobnux.io - Logo " />*/}
            <Typography sx={{
              fontWeight:"bold",
              fontSize:"40px"
            }} color={`${colors.primary}`}>
              Jobnux.io
            </Typography>
          </LogoLink>
        </ContainerLogo>
        <MenuBox>

          {MenuItems.map((item, index) => {
            return <Mainmenu item={item} key={index} />;
           })
          }
         <LanguageSelector/>
        </MenuBox>

      </ToolBar>
    )

  }



  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960
        ? setState((prevState) => ({ ...prevState, toggleMenu: true }))
        : setState((prevState) => ({ ...prevState, toggleMenu: false }));

    }
    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());
  }, [])



  return (
    <NavBar>
      <Container maxWidth={false} style={{ zIndex: 99 }} >
        {toggleMenu ? displayToggleMenu() : displayLargeMenu()}
      </Container>
    </NavBar>
  )
}

export default Navbar
import React, { useEffect, useState } from 'react'
import { Content, H1, H2 } from './Elements'
import { Container, Grid, Typography} from '@mui/material'
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { performanceData } from '../../data/performanceData';
import SimpleFeatures from '../../components/cards/SimpleFeatures';

const Move = styled(motion(Grid))`
`
function AiDriven({translator}) {

    const [data, setData] = useState([]);

    useEffect(() => {
      setData(performanceData)
    
      return () => {
        setData([])
      }
    }, [])
    
  return (
    <Content size="100vh" bgcolor="#fff" >
         <Container   >
                <Grid mt={10} mb={10} container spacing={2} >
                     <Move 
                      item 
                      lg={12}
                      xs={12} 
                      pr={2} 
                      viewport={{once:true}}
                      initial={{opacity:0,y:100}}
                      whileInView={{opacity:1,y:0}}
                      transition={{type:"easeIn", duration:1,delay:0 }}

                     >
                            <Typography fontSize={20} variant="h2" component="h2" color="#1371FF" textAlign="center">
                                {translator("sections.featuresKey.subtitle")}
                            </Typography>
                            <H2 style={{fontSize:20,textAlign:'center'}}>
                            {translator("sections.featuresKey.title")}
                            </H2>
                           
                     </Move>
                     <Move 
                        container 
                        spacing={2} 
                        item 
                        lg={12}
                        alignItems="center" 
                        viewport={{once:true}}
                        initial={{opacity:0,y:100}}
                        whileInView={{opacity:1,y:0}}
                        transition={{type:"easeIn", duration:1,delay:0 }}
                     >
                    
                            {data.map((item)=>(

                                <Grid item lg={3} sm={6} md={6} xs={12}  key={item.id} >
                                    <SimpleFeatures item={item} />
                                </Grid>

                            ))}
                       
                     </Move>
                </Grid>
         </Container>
    </Content>
  )
}

export default AiDriven

// profileReducer.js

import {
  ADD_PROFILE,
  SET_ADDRESS,
  SET_ADDRESS_COMPLEMENT,
  SET_CITY,
  SET_CODE_POSTAL,
  SET_EMAIL,
  SET_FIRSTNAME, 
  SET_LASTNAME, 
  SET_PHONE, 
  SET_PHOTO, 
  SET_POSITION
} from "../constants/resumeConstants";

const initialState = {
    photo:null,
    sectionTitle:'',
    firstname:'',
    lastname:'',
    email:'',
    phone:'',
    position: '',
    about:'',
    codePostal:'',
    city:'',
    address:'',
    addressComplement:'',
    linkedin:'',
    github:''
};  

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PHOTO:
      return {
        ...state,
        photo: action.payload,
      };
    case SET_FIRSTNAME:
      return {
        ...state,
        firstname: action.payload,
      };
    case SET_LASTNAME:
      return {
        ...state,
        lastname: action.payload,
      };
    case SET_PHONE:
      return {
        ...state,
        phone: action.payload,
      };
    case SET_EMAIL:
        return {
          ...state,
          email: action.payload,
      };
    case SET_POSITION:
      return {
        ...state,
        position: action.payload,
      };
    case SET_CODE_POSTAL:
        return {
          ...state,
          codePostal: action.payload,
      };
    case SET_CITY:
        return {
          ...state,
          city: action.payload,
       };
    case SET_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    case SET_ADDRESS_COMPLEMENT:
        return {
          ...state,
          addressComplement: action.payload,
        };
    case ADD_PROFILE:
          return {
            ...state,
            ...action.payload
          };
    default: 
      return state;
  }
};

export default profileReducer;

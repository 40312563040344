import React,{useEffect} from 'react'
import { Helmet } from 'react-helmet'
import Mission from './Mission'
import Team from './Team'
import Joinus from './Joinus'
import { useTranslation } from 'react-i18next'
 
function AboutUs() {
  const { t } = useTranslation('aboutUsPage'); 
  useEffect(() => {
   
    window.scrollTo({
        top:0
    })

}, [])
  return (
    <div>
         <Helmet>
          <meta charSet="utf-8" />
          <title> {t("title")} </title>
          <meta name="description" content={t("description")} />

       </Helmet> 
       <Mission translator={t} />  
       <Team  translator={t}/>
       <Joinus translator={t}/>
    </div>
  )
}

export default AboutUs
import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import ResultsNumbers from '../home/ResultsNumbers'
import AiDriven from './AiDriven'
import Steps from './Steps'
import Bande from '../../components/shared/Bande'
import CoverSpace from './CoverSpace'
import SingleTestemonial from '../../components/sections/SingleTestemonial'
import { Typography } from '@mui/material'
import { LetterText } from '../../data/letters'
import LetterCategories from '../../data/letterCategories.json'
import ReadyToLevelUp from '../../components/shared/ReadyToLevelUp'
import TemplateCarousel from '../../components/sections/TemplateCarousel'
import SectionTestemonials from '../../components/sections/SectionTestemonials'
import Partners from '../../components/sections/Partners'
import HeroBanner from '../../components/HeroSections/HeroBanner'
import VideoImg from '../../images/image39.png'
import { useTranslation } from 'react-i18next'


function CoverLetter() {

  const { t } = useTranslation('coverLetterPage'); 
  const [categories, setCategories] = useState([])
  const [letters, setLetters] = useState([])

  useEffect(() => {
    setCategories(LetterCategories)

    return () => {
      setCategories()
    }
  }, [])

  useEffect(() => {
    setLetters(LetterText)

    return () => {
      setLetters()
    }
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);



  return (
    <div>
      <Helmet>
            <meta charSet="utf-8" />
            <title> {t("head.title")}</title>
            <meta name="description" content={t("head.description")} />
      </Helmet>
      <HeroBanner
        title= {t("sections.banner.title")} 
        paragraph={
          <>
            {t("sections.banner.content1")} 
            {t("sections.banner.content2")}
            <span>{t("sections.banner.content3")}</span> 
            {t("sections.banner.content4")}
          </>

        }
        btnTitle={t("sections.banner.btnTitle")}
        image={VideoImg}
        link={t("sections.banner.link")}
      />
      <Partners />
      <SectionTestemonials />
      <ResultsNumbers />
      <AiDriven translator={t} />
      <Steps translator={t} />
      <Bande
        title={t("sections.bande.title")}
        content={t("sections.bande.content")}
     
     />
      <CoverSpace />
      <SingleTestemonial />
      <TemplateCarousel
        data={letters}
        filter={t("sections.TemplateCarousel.filter")}
        title={t("sections.TemplateCarousel.title")}
        linkToCreateTemplate="#"
        linkToViewAll="#"
      />

      <ReadyToLevelUp
        title={t("sections.readyToLevelUp.title")}
        content={t("sections.readyToLevelUp.content")}
        content2={
          <Typography
            color="#fff"
            paragraph
            sx={{
              textAlign: { lg: 'left', xs: 'center' }
            }}
          >
            {t("sections.readyToLevelUp.content2.word1")}
            <b>{t("sections.readyToLevelUp.content2.word2")}</b>  
            {t("sections.readyToLevelUp.content2.word3")}
          </Typography>
        }
        btnTitle={t("sections.readyToLevelUp.btnTitle")}
      />


    </div>
  )
}

export default CoverLetter
import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import {  Content } from '../../styles/GlobalStyles'
import BtnLinkEndIcon from '../buttons/BtnLinkEndIcon'
import { colors } from '../../styles'

function ReadyToLevelUp({title,content,btnTitle,content2}) {
  return (
   
    <Content
      size="50vh" 
      bgcolor="#fff" 
      viewport={{once:true}}
      initial={{opacity:0,y:50}}
      whileInView={{opacity:1,y:0}}
      transition={{type:"easeIn", duration:1,delay:0 }}
    >
        <Container 
              sx={{
                background: `${colors.gradiant}`,
                height:180,
                marginBottom:5,
                borderRadius:5
              }}
            >
                <Grid 
                    container 
                    spacing={1} 
                    alignItems="center"
                    justifyContent="center"
                    sx={{height:"100%"}}
                >
                        <Grid 
                            item 
                            lg={8} 
                            xs={12}
                            display="flex"
                            flexDirection="column" 
                            alignSelf="center"
                            justifyContent="center"
                            
                          
                            >
                            <Typography 
                              variant="h4" 
                              color="#fff" 
                              sx={{
                                fontSize:{lg:25,xs:18},
                                textAlign:{lg:'left',xs:'center'}
                              }}
                            > 
                            {title} 
                            </Typography>
                            <Typography 
                              paragraph 
                              color="#fff"
                              sx={{
                                fontSize:{lg:15,xs:12},
                                textAlign:{lg:'left',xs:'center'}
                              }}
                            > 
                            {content} 
                            </Typography>
                            {content2} 

                        </Grid>
                        <Grid 
                          item 
                          lg={4} 
                          xs={12}
                          sx={{
                            display:'flex',
                            justifyContent:'center'
                          }}
                        >
                            <BtnLinkEndIcon 
                                to="/login" 
                                bgColor="#fff" 
                                textColor="#1371FF"
                                btnTitle={btnTitle}
                                variant="contained"
                                radius="50px"

                              />
                        </Grid>
                </Grid>
            </Container>
    </Content>
    
  
  )
}

export default ReadyToLevelUp